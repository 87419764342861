$nav-collapsed: 769px;
$top-bar-height: 56px;

.ContextControl {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: $top-bar-height;
}

.ShopName {
  margin-left: 8px;
  font-weight: 600;
  font-size: 14px;
  color: var(--p-text, black);

  @media only screen and (min-width: $nav-collapsed) {
    color: var(--p-text, white);
  }
}

/* SavingIndicator.css */
@keyframes flash {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.saving-indicator {
  display: flex;
  align-items: center;
  animation: flash 1s infinite;
  margin-left: 8px; /* Adjust spacing as needed */
}

.saving-indicator .Polaris-Icon {
  margin-right: 4px; /* Space between icon and text */
}
