$nav-collapsed: 769px;
$top-bar-height: 56px;

.ContextControl {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: $top-bar-height;
}

.order__productsTable {
  border-collapse: collapse;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

.order__productsTableItem {
  text-align: left;
  padding: 6px 12px;
  border: 0;
  font-weight: var(--p-font-weight-medium);
  white-space: nowrap;
}

.order__productsTableItem.-left,
.order__productsTableRowItem.-left {
  text-align: left;
}

.order__productsTableItem.-right,
.order__productsTableRowItem.-right {
  text-align: right;
  position: relative;
}

// .order__productsTableRowItem.-right span {
//   position: absolute;
//   bottom: 1.25rem;
//   right: 0;
// }

.order__productsTable .order__productsTableItem {
  padding: 0 var(--p-space-5) var(--p-space-4);
}

.order__productsTable .order__productsTableItem:not(:last-child) {
  padding-right: 0;
}

.order__productsTableRow:not(:first-child) {
  border-top: 0.0625rem solid var(--p-divider);
}

.order__productsTableRow:first-child {
  border-top: 0.0625rem solid var(--p-divider);
}

.order__productsTableRow:last-child {
  border-bottom: none;
}

.order__productsTableRow:last-child {
  border-bottom: 0.0625rem solid var(--p-divider);
}

.order__productsTableRowItem:first-child {
  width: 100%;
}

.order__productsTableRowItem:not(:last-child) {
  padding-right: 0;
}

.order__productsTableRowItem {
  padding: var(--p-space-5);
}

.order__productsTableRowItemRemove {
  display: flex;
  align-items: center;
  min-height: 2.25rem;
}

/**
 * Payment
 */
.paymentRow {
  column-gap: 0.625rem;
  display: grid;
  grid-template-areas: "item detail value";
  grid-template-columns: minmax(auto,30%) .7fr auto;
  margin: var(--p-space-1) 0;
  padding: var(--p-space-1) 0;
}

.paymentRow:first-of-type {
  margin-top: 0;
  padding-top: 0;
}

.paymentRowTitle {
  grid-area: item;
}

.paymentRowTitle, .paymentRowDetail {
  justify-content: start;
}

.paymentRowDetail {
  grid-area: detail;
}

.paymentRowAmount {
  grid-area: value;
  justify-self: end;
}