@import url('https://fonts.cdnfonts.com/css/sofia-pro');

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--p-background, #f4f6f8);
  border: 0
}

html {
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale
}

body {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: initial;
  letter-spacing: initial;
  /* font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif; */
  font-family: 'Sofia Pro', sans-serif;
  color: var(--p-text, #212b36)
}

@media screen and (min-width: 640px) {
  body {
    font-size: 0.875rem
  }
}

strong {
  font-weight: 600
}

a {
  color: var(--p-interactive, #006fbb);
  text-decoration: none;
  cursor: pointer
}

a .ui-link--external__icon {
  display: inline;
  margin-left: 0.125rem;
  white-space: nowrap
}

a .ui-link--external__icon::before {
  content: '\2060'
}

a:hover {
  color: var(--p-interactive-hovered, #084e8a);
  text-decoration: underline
}

a:active {
  color: var(--p-interactive-pressed)
}

a.is-disabled {
  color: var(--p-text-disabled, #95a7b7);
  pointer-events: none
}

a.text-emphasis-subdued {
  color: var(--p-text-subdued, inherit);
  text-decoration: underline
}

a.text-emphasis-subdued:hover {
  color: var(--p-text-subdued, #212b36)
}

button.text-emphasis-subdued {
  color: inherit;
  text-decoration: underline
}

.type-container>*:not(.hide)+* {
  margin-top: 1rem
}

.type-container.type-container--tight>*:not(.hide)+* {
  margin-top: 0.5rem
}

.type-container.type-container--extra-tight>*:not(.hide)+* {
  margin-top: 0.25rem
}

.text-style-title {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem
}

.text-style-heading {
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.5rem
}

@media screen and (min-width: 640px) {
  .text-style-heading {
    font-size: 1rem
  }
}

.text-style-subheading {
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1rem;
  text-transform: uppercase
}

@media screen and (min-width: 640px) {
  .text-style-subheading {
    font-size: 0.75rem
  }
}

.text-style-input {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: initial;
  letter-spacing: initial
}

@media screen and (min-width: 640px) {
  .text-style-input {
    font-size: 0.875rem
  }
}

.text-style-body {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: initial;
  letter-spacing: initial
}

@media screen and (min-width: 640px) {
  .text-style-body {
    font-size: 0.875rem
  }
}

.text-style-display-x-large {
  font-size: 1.6875rem;
  font-weight: 500;
  line-height: 2.25rem
}

@media (min-width: 640px) {
  .text-style-display-x-large {
    font-size: 2.625rem;
    line-height: 2.75rem
  }
}

.text-style-display-large {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem
}

@media (min-width: 640px) {
  .text-style-display-large {
    font-size: 1.75rem;
    line-height: 2rem
  }
}

.text-style-display-medium {
  font-size: 1.3125rem;
  font-weight: 400;
  line-height: 1.75rem
}

@media (min-width: 640px) {
  .text-style-display-medium {
    font-size: 1.625rem;
    line-height: 2rem
  }
}

.text-style-display-small {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem
}

@media (min-width: 640px) {
  .text-style-display-small {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.text-emphasis-strong {
  font-weight: 600
}

.text-emphasis-subdued {
  color: var(--p-text-subdued)
}

.text-emphasis-normal {
  font-weight: 400;
  color: var(--p-text)
}

.text-breakword {
  word-wrap: break-word;
  word-break: break-word
}

@-webkit-keyframes skeletonShimmerAnimation {
  0% {
    opacity: 0.45
  }

  100% {
    opacity: 0.9
  }
}

@keyframes skeletonShimmerAnimation {
  0% {
    opacity: 0.45
  }

  100% {
    opacity: 0.9
  }
}

@-webkit-keyframes fieldErrors {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px)
  }

  33% {
    -webkit-transform: translate(5px, 0px);
    transform: translate(5px, 0px)
  }

  66% {
    -webkit-transform: translate(-5px, 0px);
    transform: translate(-5px, 0px)
  }

  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px)
  }
}

@keyframes fieldErrors {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px)
  }

  33% {
    -webkit-transform: translate(5px, 0px);
    transform: translate(5px, 0px)
  }

  66% {
    -webkit-transform: translate(-5px, 0px);
    transform: translate(-5px, 0px)
  }

  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px)
  }
}

@-webkit-keyframes modalOpen {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}

@keyframes modalOpen {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}

@-webkit-keyframes modalBgOpen {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes modalBgOpen {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  25% {
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg)
  }

  50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  75% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg)
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  25% {
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg)
  }

  50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  75% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg)
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@-webkit-keyframes wobbleOpposite {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  25% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg)
  }

  50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  75% {
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg)
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@keyframes wobbleOpposite {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  25% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg)
  }

  50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  75% {
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg)
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@-webkit-keyframes reveal {
  0% {
    opacity: 0
  }

  1% {
    opacity: 0;
    -webkit-transform: scale(0.98) translateY(-15px);
    transform: scale(0.98) translateY(-15px)
  }

  80% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes reveal {
  0% {
    opacity: 0
  }

  1% {
    opacity: 0;
    -webkit-transform: scale(0.98) translateY(-15px);
    transform: scale(0.98) translateY(-15px)
  }

  80% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@-webkit-keyframes pan {
  0% {
    background-position: 0
  }

  20% {
    background-position: 100px
  }

  25% {
    background-position: 1060px
  }

  45% {
    background-position: 1160px
  }

  50% {
    background-position: 2120px
  }

  70% {
    background-position: 2220px
  }

  75% {
    background-position: 3180px
  }

  95% {
    background-position: 3280px
  }

  100% {
    background-position: 4242px
  }
}

@keyframes pan {
  0% {
    background-position: 0
  }

  20% {
    background-position: 100px
  }

  25% {
    background-position: 1060px
  }

  45% {
    background-position: 1160px
  }

  50% {
    background-position: 2120px
  }

  70% {
    background-position: 2220px
  }

  75% {
    background-position: 3180px
  }

  95% {
    background-position: 3280px
  }

  100% {
    background-position: 4242px
  }
}

@-webkit-keyframes flyup {
  0% {
    -webkit-transform: translateY(100px);
    opacity: 0
  }

  100% {
    -webkit-transform: translateY(0px);
    opacity: 1
  }
}

@keyframes flyup {
  0% {
    -webkit-transform: translateY(100px);
    opacity: 0
  }

  100% {
    -webkit-transform: translateY(0px);
    opacity: 1
  }
}

@-webkit-keyframes flydown {
  0% {
    -webkit-transform: translateY(-100px);
    opacity: 0
  }

  100% {
    -webkit-transform: translateY(0px);
    opacity: 1
  }
}

@keyframes flydown {
  0% {
    -webkit-transform: translateY(-100px);
    opacity: 0
  }

  100% {
    -webkit-transform: translateY(0px);
    opacity: 1
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes fadein {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@-webkit-keyframes flyright {
  0% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1
  }
}

@keyframes flyright {
  0% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1
  }
}

@-webkit-keyframes fillup {
  0% {
    width: 0%
  }
}

@keyframes fillup {
  0% {
    width: 0%
  }
}

@-webkit-keyframes giftSlideUp {
  0% {
    background-position: center 0
  }

  100% {
    background-position: center -995px
  }
}

@keyframes giftSlideUp {
  0% {
    background-position: center 0
  }

  100% {
    background-position: center -995px
  }
}

@-webkit-keyframes giftSlideUp2x {
  0% {
    background-position: center 0
  }

  100% {
    background-position: center -498px
  }
}

@keyframes giftSlideUp2x {
  0% {
    background-position: center 0
  }

  100% {
    background-position: center -498px
  }
}

@-webkit-keyframes giftSlideUpGradient {
  0% {
    background-position: center center, center 0
  }

  100% {
    background-position: center center, center -995px
  }
}

@keyframes giftSlideUpGradient {
  0% {
    background-position: center center, center 0
  }

  100% {
    background-position: center center, center -995px
  }
}

@-webkit-keyframes giftSlideUpGradient2x {
  0% {
    background-position: center center, center center, center 0
  }

  100% {
    background-position: center center, center center, center -498px
  }
}

@keyframes giftSlideUpGradient2x {
  0% {
    background-position: center center, center center, center 0
  }

  100% {
    background-position: center center, center center, center -498px
  }
}

@-webkit-keyframes bouncein {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3)
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }

  70% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9)
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes bouncein {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3)
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }

  70% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9)
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

button::-moz-focus-inner {
  border: 0
}

a:hover section {
  text-decoration: none
}

a.disabled {
  color: #aaa;
  pointer-events: none
}

ul.unstyled,
ul.dropdown,
ol.unstyled,
ol.dropdown {
  margin: 0
}

ul.unstyled>li,
ul.dropdown>li,
ol.unstyled>li,
ol.dropdown>li {
  list-style: none
}

ul.horizontal::after,
ol.horizontal::after {
  content: '';
  display: table;
  clear: both
}

ul.horizontal>li,
ol.horizontal>li {
  display: block;
  float: left
}

hr {
  color: #e6e6e6;
  background-color: #e6e6e6;
  height: 1px;
  padding: 0;
  border: 0
}

[tabindex='-1']:focus {
  outline: none
}

a,
button,
[role='button'],
input,
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation
}

.block {
  display: block !important
}

.is-visuallyhidden,
.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.is-transitioning {
  display: block !important;
  visibility: visible !important
}

.inline {
  display: inline !important;
  width: auto !important
}

.inline-block {
  display: inline-block !important
}

.table {
  display: table !important
}

.table-cell {
  display: table-cell !important
}

.divider {
  margin-bottom: 15px !important;
  padding-bottom: 15px !important;
  border-bottom: 1px solid #e6e6e6 !important
}

.divider-slim {
  margin-bottom: 10px !important;
  padding-bottom: 10px !important;
  border-bottom: 1px solid #e6e6e6 !important
}

.divider-top {
  margin-top: 15px !important;
  padding-top: 15px !important;
  border-top: 1px solid #e6e6e6 !important
}

.s-none {
  margin: 0 !important
}

.s {
  margin: 10px !important
}

.ss {
  margin: 20px !important
}

.st {
  margin-top: 10px !important
}

.sst {
  margin-top: 20px !important
}

.sr {
  margin-right: 10px !important
}

.ssr {
  margin-right: 20px !important
}

.sb {
  margin-bottom: 10px !important
}

.has-inline-errors .sb {
  position: relative
}

.ssb {
  margin-bottom: 15px !important
}

.ssb--thin {
  margin-bottom: 7px !important
}

.sssb {
  margin-bottom: 30px !important
}

.sl {
  margin-left: 10px !important
}

.ssl {
  margin-left: 20px !important
}

.p-none {
  padding: 0 !important
}

.pt-none {
  padding-top: 0 !important
}

.pr-none {
  padding-right: 0 !important
}

.pl-none {
  padding-left: 0 !important
}

.p {
  padding: 10px !important
}

.pp {
  padding: 20px !important
}

.pt {
  padding-top: 10px !important
}

.ppt {
  padding-top: 20px !important
}

.pppt {
  padding-top: 30px !important
}

.pr {
  padding-right: 10px !important
}

.ppr {
  padding-right: 20px !important
}

.pb {
  padding-bottom: 10px !important
}

.ppb {
  padding-bottom: 20px !important
}

.pl {
  padding-left: 10px !important
}

.ppl {
  padding-left: 20px !important
}

.pppl {
  padding-left: 30px !important
}

.p-rel {
  position: relative !important
}

.no-wrap {
  white-space: nowrap !important
}

.w-auto {
  width: auto !important
}

.fr {
  float: right !important
}

.fl {
  float: left !important
}

.fn {
  float: none !important
}

.tr {
  text-align: right !important
}

.tl {
  text-align: left !important
}

.tc {
  text-align: center !important
}

.wait {
  cursor: wait !important
}

.pointer {
  cursor: pointer !important
}

.cursor-default {
  cursor: default
}

.va-t {
  vertical-align: top !important
}

.va-m {
  vertical-align: middle !important
}

.relative {
  position: relative
}

.fixed {
  position: fixed !important
}

.updated {
  background: #f3eab5;
  color: #ce6416
}

.underline {
  text-decoration: underline
}

.mono {
  font-family: Monaco, Consolas, 'Lucida Console', monospace
}

.uppercase {
  text-transform: uppercase
}

.dashed-text {
  border-bottom: 1px dashed #ccc
}

@media screen and (max-width: 1200px) {
  .truncate::before {
    content: '...'
  }

  .truncate .is-truncated {
    display: none
  }
}

.paragraph p {
  margin-bottom: 10px
}

.paragraph p:last-child {
  margin-bottom: 0
}

.paragraph ol,
.paragraph ul {
  margin-left: 20px
}

.paragraph li {
  margin: 5px 0
}

.paragraph li li {
  margin: 8px 0;
  line-height: 1.2
}

.paragraph ul li {
  list-style-type: square
}

.paragraph ul li li {
  list-style-type: circle
}

.ir {
  border: 0;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
  background-color: transparent
}

.top {
  top: 0
}

.animate.animate-rotate {
  -webkit-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite
}

.helper--kill-transitions {
  -webkit-transition: none;
  transition: none
}

.helper--visually-hidden {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important
}

.body:hover .helper--visually-hidden a,
.body:hover .helper--visually-hidden input,
.body:hover .helper--visually-hidden button {
  display: none !important
}

.helper--visually-hidden-focusable {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important
}

.body:hover .helper--visually-hidden-focusable a,
.body:hover .helper--visually-hidden-focusable input,
.body:hover .helper--visually-hidden-focusable button {
  display: none !important
}

.helper--visually-hidden-focusable:active,
.helper--visually-hidden-focusable:focus {
  clip: auto !important;
  height: auto !important;
  margin: 0 !important;
  overflow: visible !important;
  width: auto !important
}

.helper--overflow-hidden {
  overflow: hidden !important;
  max-height: 100vh !important
}

.helper--pre-wrap {
  white-space: pre-wrap
}

.helper--scroll-lock {
  overflow-y: scroll;
  margin: 0
}

.helper--scroll-lock .ui-app-frame,
.helper--scroll-lock #wrapper {
  overflow: hidden;
  height: 100%
}

hr.helper--divider {
  color: #ebeef0;
  background: #ebeef0;
  border: none;
  height: 1px;
  margin: 20px 0
}

.helper--scrollable {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: #f4f6f8
}

img {
  display: block;
  max-width: 100%
}

ul,
ol,
dl {
  margin: 0;
  padding: 0
}

li,
form,
input {
  margin: 0
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0 0 20px 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0
}

h1 {
  font-size: 26px;
  line-height: 1
}

h2 {
  font-size: 18px
}

h3 {
  font-size: 14px
}

h4 {
  font-size: 14px
}

h5 {
  font-size: 14px
}

h6 {
  font-size: 14px
}

p {
  margin: 0
}

ul {
  list-style: disc inside none
}

ol {
  list-style: decimal inside none
}

ol[type='1'] {
  list-style: decimal inside none
}

ol[type='a'] {
  list-style: lower-alpha inside none
}

ol[type='A'] {
  list-style: upper-alpha inside none
}

ol[type='i'] {
  list-style: lower-roman inside none
}

ol[type='I'] {
  list-style: upper-roman inside none
}

li {
  margin: 0
}

dt {
  font-weight: bold
}

dd {
  margin-left: 10px
}

pre,
code {
  font-family: Monaco, Consolas, Lucida Console, monospace
}

pre {
  font-size: 90%
}

code {
  font-size: 85%;
  background: #ebeef0;
  padding: 2px;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word
}

input {
  font-size: 14px;
  line-height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif
}

del {
  text-decoration: line-through
}

address {
  font-style: normal
}

small {
  color: #798c9c;
  font-size: 12px
}

.next-type--note {
  font-style: italic;
  font-size: 12px;
  color: #95a7b7
}

.type--no-margin {
  margin: 0
}

.type--margin-bottom {
  margin-bottom: 5px
}

.type--margin-left {
  margin-left: 10px
}

.type--centered {
  text-align: center
}

.type--right {
  text-align: right
}

.type--left {
  text-align: left
}

.type--breakall {
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word
}

.type--light-weight {
  font-weight: 300
}

.type--semi-bold {
  font-weight: 500
}

.type--bold {
  font-weight: 700
}

.type--base {
  font-size: 14px
}

.type--danger {
  color: #ff5d5d
}

.type--warning {
  color: #ff9517
}

.type--success {
  color: #96bf48
}

.type--attention {
  color: #d4a002
}

.type--info {
  color: #006fbb
}

.type--white {
  color: #ffffff
}

.type--blue-lighter {
  color: #ebf5fa
}

.type--strikethrough {
  text-decoration: line-through
}

.type--line-height-equal-to-font {
  line-height: 1 !important
}

.type--tight-spacing {
  margin-bottom: -4px
}

.type--preserve-whitespace {
  white-space: pre-wrap
}

.type--subdued {
  color: var(--p-text-subdued)
}

.type--no-wrap {
  white-space: nowrap
}

a.type--subdued,
button.type--subdued {
  color: inherit;
  text-decoration: underline
}

a.type--subdued:hover {
  color: #212b36
}

.type--truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block
}

.type--truncated--block {
  display: block
}

.type--margin-top {
  margin-top: 20px
}

.type--half-margin {
  margin-bottom: 10px
}

.type--number {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem
}

@media (min-width: 640px) {
  .type--number {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.type--number--x-large {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem
}

@media (min-width: 640px) {
  .type--number--x-large {
    font-size: 1.75rem;
    line-height: 2rem
  }
}

.type--number--large {
  font-size: 1.3125rem;
  font-weight: 400;
  line-height: 1.75rem
}

@media (min-width: 640px) {
  .type--number--large {
    font-size: 1.625rem;
    line-height: 2rem
  }
}

.type--number--small {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem
}

@media (min-width: 640px) {
  .type--number--small {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.type--number--tiny {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem
}

@media (min-width: 640px) {
  .type--number--tiny {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.ellipsis-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}

.x-large {
  font-size: 18px;
  line-height: 22px
}

.xx-large {
  font-size: 20px;
  line-height: 24px
}

.large {
  font-size: 16px
}

.small {
  font-size: 13px
}

.x-small {
  font-size: 12px
}

.fw-normal {
  font-weight: normal !important
}

.fw-strong {
  font-weight: bold !important
}

.fw-strong-black {
  color: #000000;
  font-weight: bold !important
}

.capitalize {
  text-transform: capitalize
}

.light {
  font-weight: normal
}

.lbl-light {
  color: #8093a5;
  font-weight: normal
}

.description p {
  margin-bottom: 8px
}

.heading {
  display: block;
  border-bottom: 1px solid var(--p-divider);
  color: #000000;
  margin: 0 0 12px 0;
  font-size: 16px;
  padding: 0 0 4px 0;
  font-weight: bold
}

.heading a {
  font-weight: normal
}

.heading-success,
.heading-error,
.heading-warning,
.heading-subdued {
  margin: 0 0 4px 0;
  line-height: 1.3;
  font-weight: 600
}

.heading-success {
  color: #96bf48
}

.heading-error {
  color: #ff5d5d
}

.heading-warning {
  color: #ff9517
}

.heading-subdued {
  color: #798c9c
}

.table-dropdown .heading {
  padding-left: 0px
}

.next-grid__cell--full-height,
.next-grid--equal-height-cells>.next-grid__cell {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column
}

.next-grid__cell--full-height>*,
.next-grid--equal-height-cells>.next-grid__cell>* {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.next-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 20px);
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 10px
}

.next-grid~.next-grid {
  padding-top: 0
}

.next-grid:last-of-type {
  padding-bottom: 10px
}

.next-grid.next-grid--single-column {
  display: block
}

.next-grid.next-grid--single-column>.next-grid__cell {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0
}

.next-grid.next-grid--channel-single-column {
  display: block
}

.next-grid.next-grid--channel-single-column>.next-grid__cell {
  max-width: 100%
}

.next-grid__cell>.next-grid {
  width: 100%;
  padding: 0
}

.next-grid__cell>.next-grid .next-grid__cell:first-child {
  padding-left: 0
}

.next-grid__cell>.next-grid .next-grid__cell:last-child {
  padding-right: 0
}

.next-grid--no-padding {
  width: 100%
}

.next-grid--no-padding:first-of-type,
.next-grid--no-padding:last-of-type {
  padding: 0
}

.next-grid--no-padding>.next-grid__cell {
  padding: 0
}

.ui-banner+.next-grid--no-padding {
  margin-top: 1rem
}

.next-grid--no-outside-padding {
  width: 100%;
  padding: 0
}

.next-grid--no-outside-padding:last-of-type {
  padding: 0
}

.next-grid--no-outside-padding>.next-grid__cell {
  padding-top: 0;
  padding-bottom: 0
}

.next-grid--no-outside-padding>.next-grid__cell:first-of-type {
  padding-left: 0
}

.next-grid--no-outside-padding>.next-grid__cell:last-of-type {
  padding-right: 0
}

.next-grid--no-outside-padding+.next-grid--no-outside-padding {
  margin-top: 20px
}

.next-grid--no-outside-padding+*>.next-grid--no-outside-padding:first-child {
  margin-top: 20px
}

.next-grid--compact>.next-grid__cell {
  padding-left: 5px;
  padding-right: 5px
}

.next-grid--more-compact>.next-grid__cell {
  padding-left: 3px;
  padding-right: 3px
}

.next-grid--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column
}

.next-grid--column>.next-grid__cell {
  padding: 10px 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.next-grid--column>.next-grid__cell:first-child {
  padding-top: 0
}

.next-grid--column>.next-grid__cell:last-child {
  padding-bottom: 0
}

.next-grid--column.next-grid--compact>.next-grid__cell {
  padding: 5px 0
}

.next-grid--column.next-grid--compact>.next-grid__cell:first-child {
  padding-top: 0
}

.next-grid--column.next-grid--compact>.next-grid__cell:last-child {
  padding-bottom: 0
}

.next-grid--column.next-grid--no-padding>.next-grid__cell {
  padding: 0
}

.next-grid--column.next-grid--more-compact>.next-grid__cell {
  padding: 3px 0
}

.next-grid--no-horizontal-padding {
  width: 100%
}

.next-grid--no-horizontal-padding>.next-grid__cell:first-of-type {
  padding-left: 0
}

.next-grid--no-horizontal-padding>.next-grid__cell:last-of-type {
  padding-right: 0
}

.next-grid--condensed-page-spacing {
  width: 100%;
  padding: 0
}

.next-grid--vertically-centered,
.next-grid--center-both {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.next-grid--aligned-to-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end
}

.next-grid--aligned-to-baseline {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline
}

.next-grid--right-aligned {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.next-grid--center-aligned,
.next-grid--center-both {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center
}

.next-grid--space-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.next-grid--multi-row-special {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.next-grid--multi-row-special.next-grid--single-column {
  margin: 20px auto
}

.next-grid--multi-row {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.next-grid--full-height {
  height: 100vh
}

.next-grid--margin-top {
  margin-top: 5px
}

.next-grid--margin-bottom {
  margin-bottom: 5px
}

.next-grid__cell {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0%;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  padding: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 0
}

.next-grid__cell--double {
  -webkit-box-flex: 2;
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2
}

.next-grid__cell--full {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%
}

.next-grid__cell--half {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%
}

.next-grid__cell--third {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.333%;
  -ms-flex: 0 0 33.333%;
  flex: 0 0 33.333%;
  max-width: 33.333%
}

.next-grid__cell--two-fifths {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 40%;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%
}

.next-grid__cell--quarter {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%
}

.next-grid__cell--three-quarter {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%
}

.next-grid__cell--fifth {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%
}

.next-grid__cell--no-flex {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto
}

.next-grid__cell--vertically-centered {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center
}

.next-grid__cell--aligned-to-end {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end
}

.next-grid__cell--divider {
  position: relative
}

.next-grid__cell--divider::after,
.next-grid__cell--divider::before {
  content: '';
  margin-left: -20px;
  position: absolute;
  top: 15px;
  bottom: 15px;
  width: 1px;
  background: #d3dbe2
}

.next-grid__cell--divider::after {
  right: 10px
}

@media (max-width: 590px),
(min-width: 769px) and (max-width: 854px) {

  .page>.next-grid:not(.next-grid--single-column),
  .page>*>.next-grid:not(.next-grid--single-column) {
    width: 100%;
    padding: 0
  }
}

@media (max-width: 769px),
(min-width: 769px) and (max-width: 1054px) {
  .next-grid--outer-grid-3 {
    display: block
  }

  .next-grid--outer-grid-3>.next-grid__cell {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0
  }
}

@media (max-width: 740px),
(min-width: 769px) and (max-width: 1004px) {
  .next-grid--channel-grid {
    display: block
  }

  .next-grid--channel-grid>.next-grid__cell {
    max-width: 100%
  }
}

@media (max-width: 740px),
(min-width: 769px) and (max-width: 1004px) {
  .next-grid--outer-grid {
    display: block
  }

  .next-grid--outer-grid>.next-grid__cell {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0
  }
}

.hide {
  display: none !important
}

.ui-action-list {
  list-style-type: none;
  margin: 0;
  padding: 0.5rem 0
}

.ui-action-list+.ui-action-list {
  border-top: 1px solid var(--p-divider)
}

.ui-action-list__item {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 0.5rem
}

.ui-action-list-action {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  width: inherit;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 0;
  border-radius: var(--p-border-radius-base);
  color: var(--p-text);
  cursor: pointer;
  text-align: inherit;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: initial;
  letter-spacing: initial;
  position: relative
}

@media screen and (min-width: 640px) {
  .ui-action-list-action {
    font-size: 0.875rem
  }
}

.ui-action-list-action::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(0px + 0.0625rem));
  right: calc(-1 * calc(0px + 0.0625rem));
  bottom: calc(-1 * calc(0px + 0.0625rem));
  left: calc(-1 * calc(0px + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.0625rem)
}

.ui-action-list-action:focus {
  color: var(--p-text);
  outline: none
}

.ui-action-list-action:focus::after {
  -webkit-box-shadow: 0 0 0 0.125rem var(--p-focused);
  box-shadow: 0 0 0 0.125rem var(--p-focused)
}

.ui-action-list-action:hover {
  background-color: var(--p-surface-hovered);
  color: var(--p-text);
  text-decoration: none
}

.ui-action-list-action:active {
  background-color: var(--p-surface-pressed)
}

.ui-action-list-action--selected {
  background-color: var(--p-surface-selected);
  color: var(--p-text)
}

.ui-action-list-action--selected::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -0.3125rem;
  height: 100%;
  width: 0.1875rem;
  background-color: var(--p-interactive);
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%)
}

.ui-action-list-action:disabled,
.ui-action-list-action[aria-disabled] {
  color: var(--p-text-disabled);
  cursor: default;
  pointer-events: none
}

.ui-action-list-action:disabled .ui-action-list-action__text,
.ui-action-list-action:disabled .ui-action-list-action__help-text,
.ui-action-list-action[aria-disabled] .ui-action-list-action__text,
.ui-action-list-action[aria-disabled] .ui-action-list-action__help-text {
  font-weight: 400;
  color: inherit
}

.ui-action-list-action__text {
  display: block
}

.ui-action-list-action__help-text {
  display: block;
  color: var(--p-text-subdued)
}

.ui-action-list-action--subdued {
  color: var(--p-text-subdued)
}

.ui-action-list-action--subdued:hover {
  background-color: var(--p-surface-hovered);
  color: var(--p-text-subdued)
}

.ui-action-list-action--subdued:focus {
  color: var(--p-text-subdued)
}

.ui-action-list__section {
  list-style-type: none;
  margin: 0;
  padding: 0
}

.ui-action-list__title {
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1rem;
  text-transform: uppercase;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--p-divider)
}

@media screen and (min-width: 640px) {
  .ui-action-list__title {
    font-size: 0.75rem
  }
}

.ui-annotated-section {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 62.375rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 0;
  padding-top: 1rem
}

@media (max-width: 769px) and (min-width: 490px),
(min-width: 730px) {
  .ui-annotated-section {
    padding-right: 1.25rem;
    padding-left: 1.25rem
  }
}

@media (max-width: 769px) and (min-width: 744px),
(min-width: 984px) {
  .ui-annotated-section {
    padding-right: 2rem;
    padding-left: 2rem
  }
}

@media screen and (max-width: 568px) {
  .ui-annotated-section {
    padding: 0
  }
}

@media screen and (max-width: 768px) {
  .ui-annotated-section {
    display: block;
    padding-top: 0
  }
}

.ui-annotated-section-container {
  margin: 1rem 0
}

.ui-annotated-section-container+.ui-annotated-section-container .ui-annotated-section {
  margin-top: 0;
  padding-top: 1rem;
  border-top: 1px solid var(--p-divider)
}

@media screen and (max-width: 568px) {
  .ui-annotated-section-container+.ui-annotated-section-container .ui-annotated-section {
    margin-top: 0;
    padding-top: 0;
    border: 0
  }
}

.ui-annotated-section__content {
  -webkit-box-flex: 2;
  -webkit-flex: 2 1 30rem;
  -ms-flex: 2 1 30rem;
  flex: 2 1 30rem;
  max-width: 100%;
  min-width: 0;
  padding: 0
}

.ui-annotated-section__annotation {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 15rem;
  -ms-flex: 1 1 15rem;
  flex: 1 1 15rem;
  padding: 0 4rem 0 0
}

@media screen and (max-width: 568px) {
  .ui-annotated-section__annotation {
    padding-left: 1rem
  }
}

.ui-annotated-section__title {
  padding: 1rem 0
}

.ui-annotated-section__description {
  color: var(--p-text-subdued);
  padding: 0 0 1rem
}

.ui-annotated-section__description p {
  margin-bottom: 0.75rem
}

.ui-app-frame {
  width: 100%;
  min-height: 100%
}

.ui-app-frame__header {
  position: fixed;
  z-index: 502;
  top: 0;
  right: 0;
  left: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 3.5rem
}

.ui-app-frame__skip-to-content:not(:focus) {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important
}

.body:hover .ui-app-frame__skip-to-content:not(:focus) a,
.body:hover .ui-app-frame__skip-to-content:not(:focus) input,
.body:hover .ui-app-frame__skip-to-content:not(:focus) button {
  display: none !important
}

.ui-app-frame__skip-to-content:focus {
  clip: auto !important;
  height: auto !important;
  margin: 0 !important;
  overflow: visible !important;
  width: auto !important;
  position: absolute;
  z-index: 501;
  left: 0.5rem
}

.ui-app-frame__aside {
  position: fixed;
  bottom: 0;
  left: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 0px;
  padding-left: calc(0px + constant(safe-area-inset-left));
  padding-left: calc(0px + env(safe-area-inset-left));
  background-color: var(--p-background)
}

@media screen and (max-width: 768px) {
  .ui-app-frame__aside {
    z-index: 504;
    top: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    display: none;
    width: calc(100vw - 3.5rem);
    -webkit-transition: -webkit-transform 260ms ease;
    transition: -webkit-transform 260ms ease;
    transition: transform 260ms ease;
    transition: transform 260ms ease, -webkit-transform 260ms ease;
    min-width: 15rem;
    max-width: 16.5rem
  }
}

@media screen and (max-width: 768px) and (prefers-reduced-motion: reduce) {
  .ui-app-frame__aside {
    -webkit-transition-duration: 1ms;
    transition-duration: 1ms
  }
}

@media screen and (min-width: 769px) {
  .ui-app-frame__aside {
    z-index: 1;
    top: 3.5rem;
    width: 15rem;
    border-right: 1px solid var(--p-border-subdued)
  }
}

@media screen and (max-width: 768px) {
  .ui-app-frame__aside--is-open {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    display: block
  }
}

@media screen and (max-width: 768px) {
  .ui-app-frame__aside--is-transitioning {
    display: block;
    visibility: visible
  }
}

@media screen and (min-width: 769px) {
  .ui-app-frame__aside-opener {
    display: none
  }
}

.ui-app-frame__aside-closer {
  position: absolute;
  top: 0.5rem;
  right: -0.5rem;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: opacity 260ms ease;
  transition: opacity 260ms ease;
  opacity: 0
}

@media screen and (min-width: 769px) {
  .ui-app-frame__aside-closer {
    display: none
  }
}

.ui-app-frame__aside--is-open .ui-app-frame__aside-closer {
  opacity: 1
}

.ui-app-frame__aside-closer .ui-button--transparent:active {
  background: transparent
}

.ui-app-frame__aside-closer .next-icon,
.ui-app-frame__aside-closer .next-icon__text {
  fill: var(--p-on-surface-background)
}

@media screen and (prefers-reduced-motion: reduce) {
  .ui-app-frame__aside-closer {
    -webkit-transition-duration: 0ms;
    transition-duration: 0ms
  }
}

.ui-app-frame__main {
  display: block;
  padding-top: 3.5rem;
  padding-right: 0px;
  padding-right: calc(0px + constant(safe-area-inset-right));
  padding-right: calc(0px + env(safe-area-inset-right));
  padding-bottom: 0px;
  padding-bottom: calc(0px + constant(safe-area-inset-bottom));
  padding-bottom: calc(0px + env(safe-area-inset-bottom))
}

@media screen and (max-width: 768px) {
  .ui-app-frame__main {
    padding-left: 0px;
    padding-left: calc(0px + constant(safe-area-inset-left));
    padding-left: calc(0px + env(safe-area-inset-left))
  }
}

@media screen and (min-width: 769px) {
  .ui-app-frame__main {
    padding-left: 15rem
  }
}

.ui-app-frame__backdrop {
  display: none
}

@media screen and (max-width: 768px) {
  .ui-app-frame__backdrop {
    position: fixed;
    z-index: 503;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--p-backdrop)
  }
}

@media screen and (max-width: 768px) {
  .ui-app-frame__backdrop--is-visible {
    display: block;
    -webkit-animation: app-frame-fadein 260ms ease forwards;
    animation: app-frame-fadein 260ms ease forwards
  }
}

.ui-app-frame--without-chrome .ui-app-frame__main {
  padding-top: 0;
  padding-right: 0px;
  padding-right: calc(0px + constant(safe-area-inset-right));
  padding-right: calc(0px + env(safe-area-inset-right));
  padding-bottom: 0px;
  padding-bottom: calc(0px + constant(safe-area-inset-bottom));
  padding-bottom: calc(0px + env(safe-area-inset-bottom));
  padding-left: 0px;
  padding-left: calc(0px + constant(safe-area-inset-left));
  padding-left: calc(0px + env(safe-area-inset-left))
}

.ui-app-frame--with-turbo-bar {
  min-height: calc(100% - 2rem)
}

.ui-app-frame--with-turbo-bar .ui-app-frame__header {
  top: 2rem
}

@media screen and (max-width: 768px) {
  .ui-app-frame--with-turbo-bar .ui-app-frame__aside {
    top: 2rem
  }
}

@media screen and (min-width: 769px) {
  .ui-app-frame--with-turbo-bar .ui-app-frame__aside {
    top: 5.5rem
  }
}

.ui-app-frame--viewing-embedded-app .ui-app-frame__main {
  padding: 0
}

@-webkit-keyframes app-frame-fadein {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes app-frame-fadein {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.badge {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: initial;
  letter-spacing: initial;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  padding: 0 0.625rem;
  background-color: var(--p-surface-neutral);
  border: 2px solid var(--p-surface);
  border-radius: 1.25rem;
  font-size: 0.8125rem;
  font-weight: var(--p-badge-font-weight);
  line-height: 1.25rem;
  color: var(--p-text);
  white-space: nowrap;
  vertical-align: middle
}

@media screen and (min-width: 640px) {
  .badge {
    font-size: 0.875rem
  }
}

.badge .next-icon,
.badge .next-icon__text {
  top: auto;
  height: 12px;
  width: 12px;
  margin: 0 0.25rem 0 -0.25rem;
  fill: #8a6116;
  color: transparent
}

.badge__pip {
  height: 0.375rem;
  width: 0.375rem;
  margin: 0 0.25rem 0 -0.1875rem;
  border: 0.125rem solid currentColor;
  border-radius: 50%
}

.badge--inline {
  margin-left: 0.5rem
}

.badge--status-subdued,
.badge--status-complete {
  color: var(--p-text-subdued);
  background-color: var(--p-action-secondary-disabled)
}

.badge--status-subdued .badge__pip,
.badge--status-complete .badge__pip {
  color: var(--p-icon)
}

.badge--status-success {
  background-color: var(--p-surface-success)
}

.badge--status-success .badge__pip {
  color: var(--p-icon-success)
}

.badge--status-info {
  background-color: var(--p-surface-highlight)
}

.badge--status-info .badge__pip {
  color: var(--p-icon-highlight)
}

.badge--status-attention {
  background-color: #ffea8a
}

.badge--status-attention .badge__pip {
  color: #8a6116
}

.badge--status-warning {
  background-color: var(--p-surface-warning)
}

.badge--status-warning .badge__pip {
  color: var(--p-icon-warning)
}

.badge--status-critical {
  background-color: var(--p-surface-critical)
}

.badge--status-new {
  background-color: var(--p-surface-neutral);
  color: var(--p-text)
}

.badge--progress-incomplete .badge__pip {
  background: transparent
}

.badge--progress-partially-complete .badge__pip {
  background: -webkit-gradient(linear, left bottom, left top, from(currentColor), color-stop(50%, currentColor), color-stop(50%, transparent), to(transparent));
  background: linear-gradient(to top, currentColor, currentColor 50%, transparent 50%, transparent)
}

.badge--progress-complete .badge__pip {
  background: currentColor
}

.ui-button,
.btn {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: auto;
  margin: 0;
  background: inherit;
  border: 0;
  border-radius: inherit;
  font-family: inherit;
  font-weight: 400;
  cursor: pointer;
  text-transform: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.5625rem 0.9375rem;
  border-radius: var(--p-border-radius-base);
  font-size: 0.9375rem;
  font-weight: var(--p-button-font-weight);
  line-height: 1rem;
  color: var(--p-text);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: var(--p-action-secondary);
  border: 1px solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  min-height: 2.25rem
}

.ui-button::-moz-focus-inner,
.btn::-moz-focus-inner {
  padding: 0;
  border: 0
}

.ui-button:hover,
.ui-button:focus,
.ui-button:active,
.btn:hover,
.btn:focus,
.btn:active {
  text-decoration: none
}

.ui-button.btn-disabled,
.ui-button.disabled,
.ui-button:disabled,
.ui-button[disabled],
.btn.btn-disabled,
.btn.disabled,
.btn:disabled,
.btn[disabled] {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  pointer-events: none
}

.ui-button:focus,
.btn:focus {
  outline: none
}

@media screen and (min-width: 640px) {

  .ui-button,
  .btn {
    font-size: 0.875rem
  }
}

@media (-ms-high-contrast: active) {

  .ui-button,
  .btn {
    outline: 1px solid windowText
  }
}

.ui-button .next-icon,
.ui-button .next-icon__text,
.btn .next-icon,
.btn .next-icon__text {
  position: relative;
  top: -0.04em;
  margin-top: -0.6875rem;
  margin-bottom: -0.6875rem
}

.ui-button .next-icon--color-blue,
.btn .next-icon--color-blue {
  color: transparent;
  fill: var(--p-interactive, #212b36)
}

.ui-button .next-icon--color-white,
.btn .next-icon--color-white {
  color: transparent;
  fill: white
}

.ui-button .ico,
.btn .ico {
  margin-top: -button-vertical-spacing(large)-4px;
  margin-bottom: -button-vertical-spacing(large);
  vertical-align: middle
}

.ui-button:hover,
.btn:hover {
  background: var(--p-action-secondary-hovered);
  color: var(--p-text)
}

.ui-button:active,
.ui-button[aria-pressed='true'],
.btn:active,
.btn[aria-pressed='true'] {
  background: var(--p-action-secondary-pressed)
}

.ui-button[aria-pressed='true'],
.ui-button[aria-pressed='true']:hover,
.ui-button[aria-pressed='true']:focus,
.ui-button[aria-pressed='true']:active,
.btn[aria-pressed='true'],
.btn[aria-pressed='true']:hover,
.btn[aria-pressed='true']:focus,
.btn[aria-pressed='true']:active {
  background: var(--p-action-secondary-depressed);
  color: var(--p-text-on-interactive);
  border: 1px solid var(--p-border-depressed);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 1px 0 rgba(0, 0, 0, 0.15)
}

.ui-button[aria-pressed='true']:focus:not(:active),
.btn[aria-pressed='true']:focus:not(:active) {
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 1px 0 rgba(0, 0, 0, 0.15)
}

.ui-button[aria-pressed='true']:active,
.btn[aria-pressed='true']:active {
  background: var(--p-action-secondary-pressed);
  color: var(--p-text)
}

@-webkit-keyframes button-loading-spinner {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes button-loading-spinner {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.ui-button.has-loading,
.ui-button.js-btn-loadable,
.btn.has-loading,
.btn.js-btn-loadable {
  position: relative;
  -webkit-transition: border-color 0.2s ease-out;
  transition: border-color 0.2s ease-out
}

.ui-button.has-loading::before,
.ui-button.js-btn-loadable::before,
.btn.has-loading::before,
.btn.js-btn-loadable::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  height: 16px;
  width: 16px;
  margin-top: -8px;
  margin-left: -8px;
  /* background-image: url(/assets/legacy/loading-circle-ink-lightest-dba2e94d3624437cece8592b3a965c9d1123809bc3fbbc4000f7faa68e14e360.svg); */
  background-position: 50% 50%;
  background-repeat: no-repeat;
  -webkit-animation: button-loading-spinner 0.7s linear infinite;
  animation: button-loading-spinner 0.7s linear infinite
}

.ui-button.is-loading,
.ui-button.is-loading:hover,
.btn.is-loading,
.btn.is-loading:hover {
  border-color: var(--p-override-transparent, 1px solid var(--p-border));
  cursor: default;
  text-shadow: none;
  color: transparent !important
}

.ui-button.is-loading .next-icon,
.ui-button.is-loading .next-icon__text,
.ui-button.is-loading:hover .next-icon,
.btn.is-loading .next-icon,
.btn.is-loading .next-icon__text,
.btn.is-loading:hover .next-icon {
  visibility: hidden
}

.ui-button.is-loading,
.ui-button.is-loading.btn-disabled,
.ui-button.is-loading.disabled,
.ui-button.is-loading:disabled,
.ui-button.is-loading[disabled],
.ui-button.is-loading:hover,
.ui-button.is-loading.btn-disabled:hover,
.ui-button.is-loading.disabled:hover,
.ui-button.is-loading:hover:disabled,
.ui-button.is-loading[disabled]:hover,
.btn.is-loading,
.btn.is-loading.btn-disabled,
.btn.is-loading.disabled,
.btn.is-loading:disabled,
.btn.is-loading[disabled],
.btn.is-loading:hover,
.btn.is-loading.btn-disabled:hover,
.btn.is-loading.disabled:hover,
.btn.is-loading:hover:disabled,
.btn.is-loading[disabled]:hover {
  color: transparent !important
}

.ui-button.is-loading::before,
.ui-button.is-loading:hover::before,
.btn.is-loading::before,
.btn.is-loading:hover::before {
  display: block
}

.ui-button.is-loading:hover,
.ui-button.is-loading:focus,
.ui-button.is-loading:active,
.ui-button.is-loading:hover:hover,
.ui-button.is-loading:hover:focus,
.ui-button.is-loading:hover:active,
.btn.is-loading:hover,
.btn.is-loading:focus,
.btn.is-loading:active,
.btn.is-loading:hover:hover,
.btn.is-loading:hover:focus,
.btn.is-loading:hover:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: transparent !important
}

.ui-button.btn-disabled,
.ui-button.disabled,
.ui-button:disabled,
.ui-button[disabled],
.btn.btn-disabled,
.btn.disabled,
.btn:disabled,
.btn[disabled] {
  background: var(--p-action-secondary-disabled);
  color: var(--p-text-disabled);
  border-color: var(--p-border-disabled)
}

.ui-button.btn-disabled .next-icon,
.ui-button.btn-disabled .next-icon__text,
.ui-button.disabled .next-icon,
.ui-button.disabled .next-icon__text,
.ui-button:disabled .next-icon,
.ui-button:disabled .next-icon__text,
.ui-button[disabled] .next-icon,
.ui-button[disabled] .next-icon__text,
.btn.btn-disabled .next-icon,
.btn.btn-disabled .next-icon__text,
.btn.disabled .next-icon,
.btn.disabled .next-icon__text,
.btn:disabled .next-icon,
.btn:disabled .next-icon__text,
.btn[disabled] .next-icon,
.btn[disabled] .next-icon__text {
  fill: var(--p-icon-disabled)
}

.ui-button::after,
.btn::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(0.0625rem + 0.0625rem));
  right: calc(-1 * calc(0.0625rem + 0.0625rem));
  bottom: calc(-1 * calc(0.0625rem + 0.0625rem));
  left: calc(-1 * calc(0.0625rem + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(0.0625rem + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(0.0625rem + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.0625rem)
}

.ui-button:focus:not(:active)::after,
.btn:focus:not(:active)::after {
  -webkit-box-shadow: 0 0 0 0.125rem var(--p-focused);
  box-shadow: 0 0 0 0.125rem var(--p-focused)
}

.ui-button--primary,
.btn-primary {
  background: -webkit-gradient(linear, left top, left bottom, from(#6371c7), to(#5563c1));
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  border-color: var(--p-override-transparent, #3f4eae);
  -webkit-box-shadow: var(--p-override-none, inset 0 1px 0 0 #6f7bcb);
  box-shadow: var(--p-override-none, inset 0 1px 0 0 #6f7bcb);
  color: white;
  color: var(--p-text-on-primary, white);
  position: relative;
  background: var(--p-action-primary);
  border-width: 0;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2)
}

.ui-button--primary:hover,
.btn-primary:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(#5c6ac4), to(#4959bd));
  background: linear-gradient(to bottom, #5c6ac4, #4959bd);
  border-color: var(--p-override-transparent, #3f4eae);
  color: var(--p-override-none, white)
}

.ui-button--primary:focus,
.btn-primary:focus {
  color: var(--p-override-none, white);
  border-color: var(--p-override-transparent, #202e78);
  -webkit-box-shadow: var(--p-override-none, inset 0 1px 0 0 #6f7bcb, 0 0 0 1px #202e78);
  box-shadow: var(--p-override-none, inset 0 1px 0 0 #6f7bcb, 0 0 0 1px #202e78)
}

.ui-button--primary:active,
.btn-primary:active {
  background: -webkit-gradient(linear, left top, left bottom, from(#3f4eae), to(#3f4eae));
  background: linear-gradient(to bottom, #3f4eae, #3f4eae);
  border-color: var(--p-override-transparent, #38469b);
  -webkit-box-shadow: var(--p-override-none, inset 0 1px 0 0 #38469b, 0 0 0 1px #38469b);
  box-shadow: var(--p-override-none, inset 0 1px 0 0 #38469b, 0 0 0 1px #38469b)
}

.ui-button--primary.btn-disabled,
.ui-button--primary.disabled,
.ui-button--primary:disabled,
.ui-button--primary[disabled],
.btn-primary.btn-disabled,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary[disabled] {
  background: var(--p-override-none, #bac0e6);
  border-color: var(--p-override-transparent, #a7aedf);
  color: var(--p-override-none, white)
}

.ui-button--primary:hover,
.ui-button--primary:focus,
.btn-primary:hover,
.btn-primary:focus {
  color: var(--p-text-on-primary, white)
}

.ui-button--primary::after,
.btn-primary::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(0px + 0.0625rem));
  right: calc(-1 * calc(0px + 0.0625rem));
  bottom: calc(-1 * calc(0px + 0.0625rem));
  left: calc(-1 * calc(0px + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.0625rem)
}

.ui-button--primary:hover,
.btn-primary:hover {
  border-width: 0;
  background: var(--p-action-primary-hovered);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2)
}

.ui-button--primary:active,
.btn-primary:active {
  border-width: 0;
  background: var(--p-action-primary-pressed);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2)
}

.ui-button--primary:focus:not(:active),
.btn-primary:focus:not(:active) {
  border-width: 0;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2)
}

.ui-button--primary.btn-disabled,
.ui-button--primary.disabled,
.ui-button--primary:disabled,
.ui-button--primary[disabled],
.btn-primary.btn-disabled,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary[disabled] {
  color: var(--p-text-disabled);
  background: var(--p-action-primary-disabled);
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none
}

.ui-button--primary[aria-pressed='true'],
.ui-button--primary[aria-pressed='true']:hover,
.ui-button--primary[aria-pressed='true']:focus:not(:active),
.btn-primary[aria-pressed='true'],
.btn-primary[aria-pressed='true']:hover,
.btn-primary[aria-pressed='true']:focus:not(:active) {
  background: var(--p-action-primary-depressed);
  color: var(--p-text-on-interactive);
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none
}

.ui-button--primary.has-loading::before,
.ui-button--primary.js-btn-loadable::before,
.btn-primary.has-loading::before,
.btn-primary.js-btn-loadable::before {
  /* background-image: url(/assets/legacy/loading-circle-white-1210de12a2e91f45195ede5755926cca6ff2ca7e888b2d9cf0a143151bd79ab3.svg) */
}

.ui-button--destructive,
.btn-destroy-no-hover,
.btn-destroy {
  background: -webkit-gradient(linear, left top, left bottom, from(#6371c7), to(#5563c1));
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  border-color: var(--p-override-transparent, #3f4eae);
  -webkit-box-shadow: var(--p-override-none, inset 0 1px 0 0 #6f7bcb);
  box-shadow: var(--p-override-none, inset 0 1px 0 0 #6f7bcb);
  color: white;
  color: var(--p-text-on-primary, white);
  position: relative;
  border-width: 0;
  background: var(--p-action-critical);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2)
}

.ui-button--destructive:hover,
.btn-destroy-no-hover:hover,
.btn-destroy:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(#5c6ac4), to(#4959bd));
  background: linear-gradient(to bottom, #5c6ac4, #4959bd);
  border-color: var(--p-override-transparent, #3f4eae);
  color: var(--p-override-none, white)
}

.ui-button--destructive:focus,
.btn-destroy-no-hover:focus,
.btn-destroy:focus {
  color: var(--p-override-none, white);
  border-color: var(--p-override-transparent, #202e78);
  -webkit-box-shadow: var(--p-override-none, inset 0 1px 0 0 #6f7bcb, 0 0 0 1px #202e78);
  box-shadow: var(--p-override-none, inset 0 1px 0 0 #6f7bcb, 0 0 0 1px #202e78)
}

.ui-button--destructive:active,
.btn-destroy-no-hover:active,
.btn-destroy:active {
  background: -webkit-gradient(linear, left top, left bottom, from(#3f4eae), to(#3f4eae));
  background: linear-gradient(to bottom, #3f4eae, #3f4eae);
  border-color: var(--p-override-transparent, #38469b);
  -webkit-box-shadow: var(--p-override-none, inset 0 1px 0 0 #38469b, 0 0 0 1px #38469b);
  box-shadow: var(--p-override-none, inset 0 1px 0 0 #38469b, 0 0 0 1px #38469b)
}

.ui-button--destructive.btn-disabled,
.ui-button--destructive.disabled,
.ui-button--destructive:disabled,
.ui-button--destructive[disabled],
.btn-destroy-no-hover.btn-disabled,
.btn-destroy-no-hover.disabled,
.btn-destroy-no-hover:disabled,
.btn-destroy-no-hover[disabled],
.btn-destroy.btn-disabled,
.btn-destroy.disabled,
.btn-destroy:disabled,
.btn-destroy[disabled] {
  background: var(--p-override-none, #bac0e6);
  border-color: var(--p-override-transparent, #a7aedf);
  color: var(--p-override-none, white)
}

.ui-button--destructive:hover,
.ui-button--destructive:focus,
.btn-destroy-no-hover:hover,
.btn-destroy-no-hover:focus,
.btn-destroy:hover,
.btn-destroy:focus {
  color: var(--p-text-on-primary, white)
}

.ui-button--destructive::after,
.btn-destroy-no-hover::after,
.btn-destroy::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(0px + 0.0625rem));
  right: calc(-1 * calc(0px + 0.0625rem));
  bottom: calc(-1 * calc(0px + 0.0625rem));
  left: calc(-1 * calc(0px + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.0625rem)
}

.ui-button--destructive:hover,
.btn-destroy-no-hover:hover,
.btn-destroy:hover {
  border-width: 0;
  background: var(--p-action-critical-hovered);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2)
}

.ui-button--destructive:active,
.btn-destroy-no-hover:active,
.btn-destroy:active {
  border-width: 0;
  background: var(--p-action-critical-pressed);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2)
}

.ui-button--destructive:focus:not(:active),
.btn-destroy-no-hover:focus:not(:active),
.btn-destroy:focus:not(:active) {
  border-width: 0;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.2)
}

.ui-button--destructive.btn-disabled,
.ui-button--destructive.disabled,
.ui-button--destructive:disabled,
.ui-button--destructive[disabled],
.btn-destroy-no-hover.btn-disabled,
.btn-destroy-no-hover.disabled,
.btn-destroy-no-hover:disabled,
.btn-destroy-no-hover[disabled],
.btn-destroy.btn-disabled,
.btn-destroy.disabled,
.btn-destroy:disabled,
.btn-destroy[disabled] {
  border-width: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--p-text-disabled);
  background: var(--p-action-critical-disabled)
}

.ui-button--destructive[aria-pressed='true'],
.ui-button--destructive[aria-pressed='true']:hover,
.ui-button--destructive[aria-pressed='true']:focus:not(:active),
.btn-destroy-no-hover[aria-pressed='true'],
.btn-destroy-no-hover[aria-pressed='true']:hover,
.btn-destroy-no-hover[aria-pressed='true']:focus:not(:active),
.btn-destroy[aria-pressed='true'],
.btn-destroy[aria-pressed='true']:hover,
.btn-destroy[aria-pressed='true']:focus:not(:active) {
  background: var(--p-action-critical-depressed);
  color: var(--p-text-on-interactive);
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none
}

.ui-button--destructive.has-loading::before,
.ui-button--destructive.js-btn-loadable::before,
.btn-destroy-no-hover.has-loading::before,
.btn-destroy-no-hover.js-btn-loadable::before,
.btn-destroy.has-loading::before,
.btn-destroy.js-btn-loadable::before {
  /* background-image: url(/assets/legacy/loading-circle-white-1210de12a2e91f45195ede5755926cca6ff2ca7e888b2d9cf0a143151bd79ab3.svg) */
}

.ui-button--outline,
.btn--outline {
  border-color: var(--p-border-subdued);
  color: var(--p-text);
  background: transparent
}

.ui-button--outline:hover,
.btn--outline:hover {
  background: var(--p-surface-hovered);
  border-color: var(--p-border)
}

.ui-button--outline:active,
.btn--outline:active {
  background: var(--p-surface-pressed)
}

.ui-button--outline[aria-pressed='true'],
.btn--outline[aria-pressed='true'] {
  border-color: var(--p-border);
  background: var(--p-surface-pressed)
}

.ui-button--outline[aria-pressed='true']:hover,
.btn--outline[aria-pressed='true']:hover {
  background: var(--p-surface-hovered)
}

.ui-button--outline[aria-pressed='true']:focus:not(:active)::after,
.btn--outline[aria-pressed='true']:focus:not(:active)::after {
  -webkit-box-shadow: 0 0 0 0.125rem var(--p-focused);
  box-shadow: 0 0 0 0.125rem var(--p-focused)
}

.ui-button--outline[aria-pressed='true'],
.ui-button--outline[aria-pressed='true']:hover,
.ui-button--outline[aria-pressed='true']:focus,
.ui-button--outline[aria-pressed='true']:active,
.btn--outline[aria-pressed='true'],
.btn--outline[aria-pressed='true']:hover,
.btn--outline[aria-pressed='true']:focus,
.btn--outline[aria-pressed='true']:active {
  background: var(--p-surface-pressed);
  color: var(--p-text-on-interactive)
}

.ui-button--outline.btn-disabled,
.ui-button--outline.disabled,
.ui-button--outline:disabled,
.ui-button--outline[disabled],
.btn--outline.btn-disabled,
.btn--outline.disabled,
.btn--outline:disabled,
.btn--outline[disabled] {
  background: transparent;
  border: 1px solid transparent;
  color: var(--text)
}

.ui-button--size-large,
.btn-large {
  margin: 0.9375rem 0;
  padding: 0.6875rem 1.5rem;
  font-size: 1.0625rem;
  line-height: 1.25rem;
  min-height: 2.75rem
}

@media screen and (min-width: 640px) {

  .ui-button--size-large,
  .btn-large {
    font-size: 1rem
  }
}

.ui-button--size-small,
.btn-slim {
  padding: 0.3125rem 0.625rem;
  font-size: 0.9375rem;
  line-height: 1rem;
  min-height: 1.75rem
}

@media screen and (min-width: 640px) {

  .ui-button--size-small,
  .btn-slim {
    font-size: 0.875rem
  }
}

.ui-button--full-width,
.btn--full-width {
  width: 100%;
  text-align: center;
  text-overflow: ellipsis
}

.ui-button--transparent,
.btn--transparent,
.btn--plain {
  background: transparent;
  border-color: transparent;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--p-interactive);
  text-decoration: none;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text
}

.ui-button--transparent:hover,
.btn--transparent:hover,
.btn--plain:hover {
  color: var(--p-interactive-hovered);
  text-decoration: underline
}

.ui-button--transparent:active,
.ui-button--transparent:focus,
.btn--transparent:active,
.btn--transparent:focus,
.btn--plain:active,
.btn--plain:focus {
  color: var(--p-interactive);
  border-radius: var(--p-border-radius-base)
}

.ui-button--transparent:active,
.btn--transparent:active,
.btn--plain:active {
  text-decoration: underline;
  color: var(--p-interactive-pressed);
  background: var(--p-surface-pressed)
}

.ui-button--transparent.ui-button--icon-only,
.ui-button--transparent.btn--icon,
.btn--transparent.ui-button--icon-only,
.btn--transparent.btn--icon,
.btn--plain.ui-button--icon-only,
.btn--plain.btn--icon {
  color: var(--p-text)
}

.ui-button--link,
.btn--link {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: auto;
  margin: 0;
  background: inherit;
  border: 0;
  border-radius: inherit;
  font-family: inherit;
  font-weight: 400;
  cursor: pointer;
  text-transform: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border-color: transparent;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--p-interactive);
  text-decoration: none;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  padding: 0;
  text-align: left;
  white-space: normal;
  vertical-align: initial;
  min-height: 0;
  border: none;
  box-shadow: none
}

.ui-button--link::-moz-focus-inner,
.btn--link::-moz-focus-inner {
  padding: 0;
  border: 0
}

.ui-button--link:hover,
.ui-button--link:focus,
.ui-button--link:active,
.btn--link:hover,
.btn--link:focus,
.btn--link:active {
  text-decoration: none
}

.ui-button--link.btn-disabled,
.ui-button--link.disabled,
.ui-button--link:disabled,
.ui-button--link[disabled],
.btn--link.btn-disabled,
.btn--link.disabled,
.btn--link:disabled,
.btn--link[disabled] {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  pointer-events: none
}

.ui-button--link:focus,
.btn--link:focus {
  outline: none
}

.ui-button--link:hover,
.btn--link:hover {
  color: var(--p-interactive-hovered);
  text-decoration: underline
}

.ui-button--link:active,
.ui-button--link:focus,
.btn--link:active,
.btn--link:focus {
  color: var(--p-interactive);
  border-radius: var(--p-border-radius-base)
}

.ui-button--link:active,
.btn--link:active {
  text-decoration: underline;
  color: var(--p-interactive-pressed);
  background: var(--p-surface-pressed)
}

.ui-button--link.ui-button--icon-only,
.ui-button--link.btn--icon,
.btn--link.ui-button--icon-only,
.btn--link.btn--icon {
  color: var(--p-text)
}

@media screen and (min-width: 640px) {

  .ui-button--link,
  .btn--link {
    font-size: 0.875rem
  }
}

.ui-button--link::after,
.btn--link::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(0.0625rem + 0.0625rem));
  right: calc(-1 * calc(0.0625rem + 0.0625rem));
  bottom: calc(-1 * calc(0.0625rem + 0.0625rem));
  left: calc(-1 * calc(0.0625rem + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(0.0625rem + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(0.0625rem + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.0625rem)
}

.ui-button--link,
.ui-button--link:active,
.ui-button--link:focus,
.btn--link,
.btn--link:active,
.btn--link:focus {
  border-left-width: 0;
  border-right-width: 0
}

.ui-button--link:focus:not(:active)::after,
.btn--link:focus:not(:active)::after {
  -webkit-box-shadow: 0 0 0 0.125rem var(--p-focused);
  box-shadow: 0 0 0 0.125rem var(--p-focused)
}

.ui-button--icon-only,
.btn--icon {
  padding-right: 9px;
  padding-left: 9px
}

.ui-button--icon-only.ui-button--size-large,
.ui-button--icon-only.btn-large,
.btn--icon.ui-button--size-large,
.btn--icon.btn-large {
  padding-right: 11px;
  padding-left: 11px
}

.ui-button--icon-only.ui-button--size-small,
.ui-button--icon-only.btn-slim,
.btn--icon.ui-button--size-small,
.btn--icon.btn-slim {
  padding-right: 5px;
  padding-left: 5px
}

.ui-button--skip,
.btn-skip {
  z-index: 226;
  top: 0;
  left: 0;
  padding: 1rem 0 !important;
  border-radius: 0;
  text-align: center;
  min-width: 15rem
}

.ui-button-group,
.button-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  list-style: none
}

.ui-button-group .ui-button-group,
.ui-button-group .button-group,
.button-group .ui-button-group,
.button-group .button-group {
  margin-right: 0
}

.ui-button-group__item,
.ui-button-group>*,
.button-group>* {
  margin-top: 0.5rem;
  margin-right: 0.5rem
}

.ui-button-group--right-aligned,
.button-group--right-aligned {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.ui-button-group--extra-spacing {
  margin-top: -1rem;
  margin-right: -1rem
}

.ui-button-group--extra-spacing .ui-button-group__item {
  margin-top: 1rem;
  margin-right: 1rem
}

.ui-button-group--segmented,
.segmented {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 0
}

.ui-button-group--segmented .ui-button:focus,
.ui-button-group--segmented .btn:focus,
.segmented .ui-button:focus,
.segmented .btn:focus {
  position: relative;
  z-index: 1
}

.ui-button-group--segmented .ui-button:focus::after,
.ui-button-group--segmented .btn:focus::after,
.segmented .ui-button:focus::after,
.segmented .btn:focus::after {
  border-radius: 0
}

.ui-button-group--segmented>.ui-button-group__item,
.ui-button-group--segmented>*,
.segmented>.ui-button-group__item,
.segmented>* {
  display: block;
  list-style-type: none;
  margin-right: 0
}

.ui-button-group--segmented>.ui-button-group__item:not(:first-child) .ui-button,
.ui-button-group--segmented>.ui-button-group__item:not(:first-child) .btn,
.ui-button-group--segmented>.ui-button-group__item.ui-button:not(:first-child),
.ui-button-group--segmented>.ui-button-group__item.btn:not(:first-child),
.ui-button-group--segmented>*:not(:first-child) .ui-button,
.ui-button-group--segmented>*:not(:first-child) .btn,
.ui-button-group--segmented>*.ui-button:not(:first-child),
.ui-button-group--segmented>*.btn:not(:first-child),
.segmented>.ui-button-group__item:not(:first-child) .ui-button,
.segmented>.ui-button-group__item:not(:first-child) .btn,
.segmented>.ui-button-group__item.ui-button:not(:first-child),
.segmented>.ui-button-group__item.btn:not(:first-child),
.segmented>*:not(:first-child) .ui-button,
.segmented>*:not(:first-child) .btn,
.segmented>*.ui-button:not(:first-child),
.segmented>*.btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px
}

.ui-button-group--segmented>.ui-button-group__item:not(:last-child) .ui-button,
.ui-button-group--segmented>.ui-button-group__item:not(:last-child) .btn,
.ui-button-group--segmented>.ui-button-group__item.ui-button:not(:last-child),
.ui-button-group--segmented>.ui-button-group__item.btn:not(:last-child),
.ui-button-group--segmented>*:not(:last-child) .ui-button,
.ui-button-group--segmented>*:not(:last-child) .btn,
.ui-button-group--segmented>*.ui-button:not(:last-child),
.ui-button-group--segmented>*.btn:not(:last-child),
.segmented>.ui-button-group__item:not(:last-child) .ui-button,
.segmented>.ui-button-group__item:not(:last-child) .btn,
.segmented>.ui-button-group__item.ui-button:not(:last-child),
.segmented>.ui-button-group__item.btn:not(:last-child),
.segmented>*:not(:last-child) .ui-button,
.segmented>*:not(:last-child) .btn,
.segmented>*.ui-button:not(:last-child),
.segmented>*.btn:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.ui-button-group--segmented>.ui-button-group__item .btn--outline:not(:focus),
.ui-button-group--segmented>.ui-button-group__item .btn--outline:active,
.ui-button-group--segmented>.ui-button-group__item .ui-button--outline:not(:focus),
.ui-button-group--segmented>.ui-button-group__item .ui-button--outline:active,
.ui-button-group--segmented>* .btn--outline:not(:focus),
.ui-button-group--segmented>* .btn--outline:active,
.ui-button-group--segmented>* .ui-button--outline:not(:focus),
.ui-button-group--segmented>* .ui-button--outline:active,
.segmented>.ui-button-group__item .btn--outline:not(:focus),
.segmented>.ui-button-group__item .btn--outline:active,
.segmented>.ui-button-group__item .ui-button--outline:not(:focus),
.segmented>.ui-button-group__item .ui-button--outline:active,
.segmented>* .btn--outline:not(:focus),
.segmented>* .btn--outline:active,
.segmented>* .ui-button--outline:not(:focus),
.segmented>* .ui-button--outline:active {
  border-left-color: var(--p-border-subdued)
}

.ui-button-group--segmented>.ui-button-group__item:first-of-type .ui-button:focus::after,
.ui-button-group--segmented>.ui-button-group__item:first-of-type .btn:focus::after,
.segmented>.ui-button-group__item:first-of-type .ui-button:focus::after,
.segmented>.ui-button-group__item:first-of-type .btn:focus::after {
  border-top-left-radius: var(--p-border-radius-base);
  border-bottom-left-radius: var(--p-border-radius-base)
}

.ui-button-group--segmented>.ui-button-group__item:last-of-type .ui-button:focus::after,
.ui-button-group--segmented>.ui-button-group__item:last-of-type .btn:focus::after,
.segmented>.ui-button-group__item:last-of-type .ui-button:focus::after,
.segmented>.ui-button-group__item:last-of-type .btn:focus::after {
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base)
}

.btn-separate {
  margin-left: 0.25rem
}

.btn-separate-left {
  margin-right: 0.25rem
}

.btn-separate-top {
  margin-top: 0.5rem
}

.ui-card {
  background-color: var(--p-surface);
  -webkit-box-shadow: var(--p-card-shadow);
  box-shadow: var(--p-card-shadow)
}

@media screen and (min-width: 568px) {
  .ui-card {
    border-radius: var(--p-border-radius-wide)
  }

  .ui-card>*:first-child {
    border-top-left-radius: var(--p-border-radius-wide);
    border-top-right-radius: var(--p-border-radius-wide)
  }

  .ui-card>*:last-child {
    border-bottom-right-radius: var(--p-border-radius-wide);
    border-bottom-left-radius: var(--p-border-radius-wide)
  }
}

.ui-card+.ui-card {
  margin-top: 1.25rem
}

.ui-card--type-aside {
  background-color: var(--p-surface-subdued)
}

.ui-card__header {
  display: block;
  padding: 1.25rem 1rem 0
}

@media screen and (min-width: 568px) {
  .ui-card__header {
    padding: 1.25rem 1.25rem 0
  }
}

.ui-card__header+.ui-card__section--type-subdued {
  margin-top: 1.25rem;
  border-top: 1px solid var(--p-divider)
}

.ui-card__section {
  padding: 1.25rem 1rem
}

@media screen and (min-width: 568px) {
  .ui-card__section {
    padding: 1.25rem
  }

  .ui-card__section:first-child {
    border-radius: var(--p-border-radius-wide) var(--p-border-radius-wide) 0 0
  }

  .ui-card__section:last-child {
    border-radius: 0 0 var(--p-border-radius-wide) var(--p-border-radius-wide)
  }
}

.ui-card__section+.ui-card__section {
  border-top: 1px solid var(--p-divider)
}

.ui-card__footer {
  display: block;
  padding: 0 1rem 1.25rem
}

@media screen and (min-width: 568px) {
  .ui-card__footer {
    padding: 0 1.25rem 1.25rem;
    border-radius: 0 0 var(--p-border-radius-wide) var(--p-border-radius-wide)
  }
}

.ui-card__section--type-subdued {
  background-color: var(--p-surface-subdued)
}

.ui-card__section--type-subdued+.ui-card__footer {
  padding-top: 1.25rem;
  border-top: 1px solid var(--p-divider)
}

.ui-card__section--full-width {
  padding: 0
}

.ui-card__section--full-width .ui-card__section-header {
  padding: 1.25rem 1rem 0
}

@media screen and (min-width: 568px) {
  .ui-card__section--full-width .ui-card__section-header {
    padding: 1.25rem 1.25rem 0
  }
}

.ui-card__section--full-width+.ui-card__footer {
  padding-top: 1.25rem
}

.ui-callout-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.ui-callout-card__image {
  display: none
}

@media (min-width: 450px) {
  .ui-callout-card__image {
    display: block;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-left: 1rem
  }
}

.ui-callout-card__content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.ui-callout-card__content>*:not(.hide)+* {
  margin-top: 1rem
}

.ui-code {
  padding: 0.25rem;
  background-color: var(--p-background);
  border-radius: var(--p-border-radius-base);
  font-family: Monaco, Consolas, Lucida Console, monospace;
  overflow-x: scroll
}

.ui-code--block {
  padding: 1rem;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: initial;
  letter-spacing: initial
}

.ui-code--block code {
  background-color: var(--p-background)
}

[data-collapsible-state='collapsed'] {
  opacity: 0;
  height: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  visibility: hidden
}

.ui-collapsible--has-transition {
  -webkit-transition-property: opacity, height, padding-top, padding-bottom;
  transition-property: opacity, height, padding-top, padding-bottom;
  -webkit-transition-duration: 260ms;
  transition-duration: 260ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  visibility: visible !important
}

.ui-collapsible__activator--hide {
  display: none !important
}

.ui-content-list {
  margin-bottom: 1rem;
  list-style: none
}

.ui-content-list:last-child {
  margin-bottom: 0
}

.ui-content-list__item {
  position: relative;
  margin-bottom: 0.5rem;
  padding-left: 1rem
}

.ui-content-list__item:last-child {
  margin-bottom: 0
}

.ui-content-list__item::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block
}

.ui-content-list__item .ui-content-list {
  margin-top: 0.5rem;
  margin-bottom: 0
}

.ui-content-list--type-number,
.ui-content-list--numbered {
  counter-reset: ordered-counter
}

.ui-content-list--type-number>.ui-content-list__item::before,
.ui-content-list--numbered>.ui-content-list__item::before {
  font-weight: 600;
  content: counter(ordered-counter, decimal) ".";
  font-size: 0.75rem;
  vertical-align: top;
  counter-increment: ordered-counter
}

.ui-content-list--type-bullet>.ui-content-list__item::before,
.ui-content-list--bulleted>.ui-content-list__item::before {
  content: '\2022';
  font-size: 1.125rem
}

.ui-content-list--type-icon>.ui-content-list__item,
.ui-content-list--icons>.ui-content-list__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0
}

.ui-content-list--type-icon .ui-content-list__icon,
.ui-content-list--icons .ui-content-list__icon {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 0.5rem
}

.ui-content-list--type-icon .ui-content-list__text,
.ui-content-list--icons .ui-content-list__text {
  overflow-wrap: break-word;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0%;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  min-width: 0;
  word-wrap: break-word;
  word-break: break-word
}

.ui-content-list--type-icon .ui-content-list,
.ui-content-list--icons .ui-content-list {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%
}

.ui-context-bar {
  position: absolute;
  z-index: 504;
  top: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 3.5rem;
  background: var(--p-surface);
  -webkit-box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  pointer-events: none
}

.ui-context-bar--is-dirty {
  opacity: 1;
  pointer-events: auto
}

.ui-context-bar--single-column .ui-context-bar__contents {
  max-width: 41.375rem
}

.ui-context-bar--full-width .ui-context-bar__contents {
  max-width: 100%
}

.ui-context-bar__branding {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 15rem;
  min-width: 15rem;
  padding-right: 0.5rem;
  padding-left: 1rem;
  padding-left: calc(1rem + constant(safe-area-inset-left));
  padding-left: calc(1rem + env(safe-area-inset-left));
  opacity: 0.3;
  pointer-events: none
}

.ui-context-bar__branding svg,
.ui-context-bar__branding img {
  display: block;
  height: auto;
  width: 6.5rem;
  max-height: 2rem
}

@media screen and (max-width: 768px) {
  .ui-context-bar__branding {
    display: none
  }
}

.ui-context-bar__branding svg {
  fill: var(--p-text)
}

.ui-context-bar__contents {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 62.375rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 1px
}

@media (max-width: 769px) and (min-width: 490px),
(min-width: 730px) {
  .ui-context-bar__contents {
    padding-right: 1.25rem;
    padding-left: 1.25rem
  }
}

@media (max-width: 769px) and (min-width: 744px),
(min-width: 984px) {
  .ui-context-bar__contents {
    padding-right: 2rem;
    padding-left: 2rem
  }
}

.ui-context-bar__message {
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: var(--p-text-subdued);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 1rem 0 0
}

@media screen and (min-width: 640px) {
  .ui-context-bar__message {
    font-size: 1rem
  }
}

.ui-context-bar__actions-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.ui-context-bar__actions-group>*:not(:first-child) {
  margin-left: 0.5rem
}

.ui-datepicker__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: -2.875rem
}

.ui-datepicker__header .ui-datepicker__previous-month {
  margin-top: -round(9px);
  margin-left: -round(9px)
}

.ui-datepicker__header .ui-datepicker__next-month {
  margin-top: -round(9px);
  margin-right: -round(9px)
}

.ui-datepicker__day {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  --desired-day-box-height: 48px;
  padding: calc((var(--desired-day-box-height) - 20px) / 2) 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0%;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  width: calc(100% / 7);
  margin: 0 0 0 -1px;
  background: transparent;
  border: none;
  border-radius: var(--p-border-radius-base);
  font-size: 0.75rem;
  color: var(--p-text);
  cursor: pointer
}

@media screen and (min-width: 640px) {
  .ui-datepicker__day {
    font-size: 0.875rem
  }
}

.ui-datepicker__day::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(0px + 0.0625rem));
  right: calc(-1 * calc(0px + 0.0625rem));
  bottom: calc(-1 * calc(0px + 0.0625rem));
  left: calc(-1 * calc(0px + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.0625rem)
}

@media (min-width: 640px) {
  .ui-datepicker__day {
    --desired-day-box-height: 36px
  }
}

.ui-datepicker__day:hover {
  background: var(--p-interactive-hovered);
  color: var(--p-text-on-interactive)
}

.ui-datepicker__day[disabled] {
  background-color: transparent;
  color: var(--p-text-disabled)
}

.ui-datepicker__day[disabled]:hover {
  cursor: default;
  background-color: transparent;
  color: var(--p-text-disabled)
}

.ui-datepicker__day[disabled]:focus::after {
  content: none
}

.ui-datepicker__day:focus,
.ui-datepicker__day:active {
  outline: none
}

.ui-datepicker__day:focus:not(:active)::after {
  -webkit-box-shadow: 0 0 0 0.125rem var(--p-focused);
  box-shadow: 0 0 0 0.125rem var(--p-focused)
}

.ui-datepicker__day.ui-datepicker__day--empty {
  background: transparent
}

.ui-datepicker__day--current {
  font-weight: 600
}

.ui-datepicker__day--selected {
  background: var(--p-interactive);
  color: var(--p-text-on-interactive)
}

.ui-datepicker__day--in-range {
  z-index: auto;
  border-radius: 0;
  background: var(--p-surface-selected)
}

.ui-datepicker__weekdays {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.ui-datepicker__weekday {
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1rem;
  color: var(--p-text-subdued);
  display: inline-block;
  width: calc(100% / 7);
  padding: 9px;
  font-size: 0.75rem;
  text-align: center;
  color: var(--p-text-subdued)
}

@media (min-width: 640px) {
  .ui-datepicker__weekday {
    font-size: 0.75rem
  }
}

.ui-datepicker__weekday--current {
  font-weight: 600;
  color: black
}

.ui-datepicker__week {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -1px;
  margin-bottom: 0.125rem
}

.ui-datepicker__week>.ui-datepicker__day--in-range:first-child:not(.ui-datepicker__day--first-in-range):not(.ui-datepicker__day--last-in-range) {
  border-radius: var(--p-border-radius-base) 0 0 var(--p-border-radius-base)
}

.ui-datepicker__week>.ui-datepicker__day--in-range:last-child:not(.ui-datepicker__day--first-in-range):not(.ui-datepicker__day--last-in-range) {
  border-radius: 0 var(--p-border-radius-base) var(--p-border-radius-base) 0
}

.ui-datepicker__month {
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.5rem;
  font-weight: 400;
  display: block;
  text-align: center
}

@media screen and (min-width: 640px) {
  .ui-datepicker__month {
    font-size: 1rem
  }
}

.ui-datepicker__month--current {
  font-weight: 600;
  color: black
}

.ui-datepicker__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: -1rem;
  margin-left: -1rem
}

.ui-datepicker__container .ui-datepicker__calendar {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 14.375rem;
  margin-top: 1rem;
  margin-left: 1rem
}

.ui-datepicker__day--first-in-range:not(.ui-datepicker__day--last-in-range),
.ui-datepicker__day--first-in-range:not(.ui-datepicker__day--last-in-range)::after {
  border-radius: 1.875rem 0 0 1.875rem
}

.ui-datepicker__day--last-in-range:not(.ui-datepicker__day--first-in-range),
.ui-datepicker__day--last-in-range:not(.ui-datepicker__day--first-in-range)::after {
  border-radius: 0 1.875rem 1.875rem 0
}

.ui-datepicker__month--current,
.ui-datepicker__weekday--current {
  color: var(--p-text)
}

.ui-datepicker__day--current,
.ui-datepicker__month--current,
.ui-datepicker__weekday--current {
  font-weight: 600
}

.ui-dismissible--transitionable {
  -webkit-transition: max-height 200ms ease, opacity 200ms ease;
  transition: max-height 200ms ease, opacity 200ms ease
}

@media screen and (prefers-reduced-motion: reduce) {
  .ui-dismissible--transitionable {
    -webkit-transition: opacity 200ms ease;
    transition: opacity 200ms ease
  }
}

.ui-dismissible__action {
  padding: 0.5rem;
  background: none;
  border: 0;
  outline: none;
  line-height: 1;
  cursor: pointer
}

.ui-dismissible__action>.next-icon,
.ui-dismissible__action>.next-icon__text {
  fill: rgba(0, 0, 0, 0.2)
}

.ui-dismissible__action:hover,
.ui-dismissible__action:focus,
.ui-dismissible__action:active {
  background: none;
  border: 0
}

.ui-dismissible__action:hover>.next-icon,
.ui-dismissible__action:hover>.next-icon__text,
.ui-dismissible__action:focus>.next-icon,
.ui-dismissible__action:focus>.next-icon__text,
.ui-dismissible__action:active>.next-icon,
.ui-dismissible__action:active>.next-icon__text {
  fill: rgba(0, 0, 0, 0.5)
}

.ui-dismissible--is-closed {
  opacity: 0;
  max-height: 0 !important
}

.ui-dismissible--is-hidden {
  display: none !important
}

.ui-description-list {
  margin: 0;
  padding: 0
}

.ui-description-list-item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 1rem 0;
  border-bottom: 1px solid var(--p-divider)
}

.ui-description-list-item:last-of-type {
  border-bottom: 0
}

@media screen and (min-width: 568px) {
  .ui-description-list-item {
    padding: 0.5rem 0
  }
}

.ui-description-list-item__term {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  padding: 0 0 0.5rem;
  font-weight: 600
}

@media screen and (min-width: 568px) {
  .ui-description-list-item__term {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    padding: 0 1rem 0 0
  }
}

.ui-description-list-item__description {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  margin-left: 0
}

@media screen and (min-width: 568px) {
  .ui-description-list-item__description {
    -webkit-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%
  }
}

.ui-documentation-container {
  padding: 1rem
}

.ui-documentation-container>*+* {
  margin-bottom: 1rem
}

.ui-documentation-container>h1 {
  font-size: 1.6875rem;
  font-weight: 500;
  line-height: 2.25rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--p-border)
}

@media (min-width: 640px) {
  .ui-documentation-container>h1 {
    font-size: 2.625rem;
    line-height: 2.75rem
  }
}

.ui-documentation-container>h2 {
  font-size: 1.3125rem;
  font-weight: 400;
  line-height: 1.75rem;
  margin-top: 1.25rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--p-border);
  max-width: 40rem
}

@media (min-width: 640px) {
  .ui-documentation-container>h2 {
    font-size: 1.625rem;
    line-height: 2rem
  }
}

.ui-documentation-container>h3 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  max-width: 40rem
}

@media (min-width: 640px) {
  .ui-documentation-container>h3 {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.ui-documentation-container>h4 {
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.5rem;
  max-width: 40rem
}

@media screen and (min-width: 640px) {
  .ui-documentation-container>h4 {
    font-size: 1rem
  }
}

.ui-documentation-container>p {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: initial;
  letter-spacing: initial;
  max-width: 40rem
}

@media screen and (min-width: 640px) {
  .ui-documentation-container>p {
    font-size: 0.875rem
  }
}

.ui-documentation-container>ul {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: initial;
  letter-spacing: initial;
  list-style-position: outside;
  margin-left: 1rem;
  max-width: 50rem
}

@media screen and (min-width: 640px) {
  .ui-documentation-container>ul {
    font-size: 0.875rem
  }
}

.ui-documentation-container>ul li {
  margin-bottom: 0.5rem
}

.ui-documentation-container>ul ul {
  list-style-position: outside;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem
}

.ui-documentation-container>ul>li {
  margin-bottom: 0.5rem
}

.ui-documentation-container>ul li>code,
.ui-documentation-container>p>code,
.ui-documentation-container>h2>code,
.ui-documentation-container>h3>code,
.ui-documentation-container>h4>code {
  display: inline-block;
  margin-bottom: 0.25rem;
  padding: 0.25rem;
  background-color: #f4f6f8;
  border-radius: var(--p-border-radius-base);
  color: #212b36
}

.ui-documentation-container pre+h3,
.ui-documentation-container pre+h4 {
  margin-top: 2rem
}

.ui-empty-state {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow-x: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 1rem auto 0 auto;
  padding: 1rem 0;
  max-width: 62.375rem;
  text-align: left
}

@media screen and (min-width: 720px) {

  .ui-empty-state .ui-empty-state__title,
  .ui-empty-state .ui-empty-state__subtitle,
  .ui-empty-state .ui-empty-state__details {
    max-width: 25rem
  }
}

@media (max-width: 769px) and (min-width: 744px),
(min-width: 984px) {
  .ui-empty-state {
    padding-right: 2rem;
    padding-left: 2rem
  }

  .ui-empty-state .ui-empty-state__section {
    left: 0
  }
}

.ui-empty-state .ui-button--size-large {
  margin: 0
}

.ui-empty-state img {
  margin-right: auto;
  margin-left: auto
}

.ui-empty-state__section {
  position: relative;
  left: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-flow: column-reverse wrap;
  -ms-flex-flow: column-reverse wrap;
  flex-flow: column-reverse wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 60px 0;
  padding-top: 0
}

@media screen and (max-width: 720px) {
  .ui-empty-state__section {
    left: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 1rem
  }
}

.ui-empty-state__section--featured {
  background-color: var(--p-surface)
}

.ui-empty-state__section:not(.ui-empty-state__section--featured)+.ui-empty-state__section:not(.ui-empty-state__section--featured) {
  padding-top: 0
}

.ui-empty-state__subsection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1000px;
  margin: 0;
  padding: 0
}

.ui-empty-state__subsection>*:not(.hide)+* {
  margin-top: 1rem
}

.ui-empty-state__subsection:not(:last-child) {
  padding-bottom: 0.5rem
}

.ui-empty-state__subsection+.ui-empty-state__subsection {
  margin-top: 1rem
}

@media screen and (min-width: 0\0),
(-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .ui-empty-state__subsection>* {
    max-width: 100%
  }
}

.ui-empty-state__subsection__background {
  position: initial;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  width: 226px;
  max-width: 100%
}

.ui-empty-state__subsection__background img {
  position: static;
  z-index: 0;
  max-width: 100%;
  width: auto;
  margin-right: 0;
  margin-left: 0;
  margin-top: 0
}

@media screen and (min-width: 1025px) {
  .ui-empty-state__subsection__background img {
    left: -80%
  }
}

@media screen and (max-width: 1024px) {
  .ui-empty-state__subsection__background img {
    width: 100%
  }
}

@media screen and (max-width: 568px) {
  .ui-empty-state__subsection__background img {
    width: 150%;
    max-width: 150%;
    margin-left: -50%
  }
}

@media screen and (max-width: 1024px) {
  .ui-empty-state__subsection__background {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-top: 1rem
  }
}

.ui-empty-state__subsection__foreground {
  position: initial;
  z-index: 1;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%
}

@media screen and (max-width: 1024px) {
  .ui-empty-state__subsection__foreground {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%
  }
}

@media screen and (max-width: 1024px) {
  .ui-empty-state__subsection__foreground--vertical-shift+.ui-empty-state__subsection__background img {
    margin-top: -3.125rem
  }
}

.ui-empty-state--no-illustration .ui-empty-state__section {
  left: 0;
  padding-bottom: 1.25rem
}

.ui-empty-state--no-illustration .ui-empty-state__subsection__foreground {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 0 1rem
}

.ui-empty-state--no-illustration .ui-empty-state__title,
.ui-empty-state--no-illustration .ui-empty-state__subtitle {
  width: 100%;
  max-width: inherit
}

@media screen and (min-width: 720px) {

  .ui-empty-state--no-illustration .ui-empty-state__title,
  .ui-empty-state--no-illustration .ui-empty-state__subtitle {
    text-align: center
  }
}

@media screen and (min-width: 720px) {
  .ui-empty-state--no-illustration .ui-empty-state__subsection {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
  }
}

.ui-empty-state__items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin: 0
}

@media screen and (min-width: 0\0),
(-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .ui-empty-state__items {
    max-width: calc(100% + 30px);
    width: calc(100% + 30px)
  }
}

.ui-empty-state__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 100%;
  -webkit-flex: 100%;
  -ms-flex: 100%;
  flex: 100%;
  margin: 0
}

.ui-empty-state__item>* {
  -webkit-box-flex: 100%;
  -webkit-flex: 100%;
  -ms-flex: 100%;
  flex: 100%
}

@media screen and (min-width: 0\0),
(-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .ui-empty-state__item {
    max-width: 100%;
    width: 100%
  }
}

.ui-empty-state__subitems {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0
}

@media screen and (min-width: 0\0),
(-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .ui-empty-state__subitems {
    max-width: calc(100% + 20px);
    width: calc(100% + 20px)
  }
}

.ui-empty-state__subitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 100%;
  -webkit-flex: 100%;
  -ms-flex: 100%;
  flex: 100%;
  margin: 0
}

.ui-empty-state__title {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  width: 100%
}

@media (min-width: 640px) {
  .ui-empty-state__title {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.ui-empty-state__title:last-child {
  margin-bottom: 0
}

.ui-empty-state__subtitle {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: initial;
  letter-spacing: initial;
  margin-top: 0.5rem;
  color: var(--p-text-subdued)
}

@media screen and (min-width: 640px) {
  .ui-empty-state__subtitle {
    font-size: 0.875rem
  }
}

.ui-empty-state__subtitle:last-child {
  margin-bottom: 0
}

.ui-empty-state__details {
  color: var(--p-text-subdued)
}

.ui-empty-state--centered-layout {
  margin-top: 0
}

.ui-empty-state--centered-layout .ui-empty-state__title {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem
}

@media (min-width: 640px) {
  .ui-empty-state--centered-layout .ui-empty-state__title {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.ui-empty-state--centered-layout .ui-empty-state__subtitle {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: initial;
  letter-spacing: initial
}

@media screen and (min-width: 640px) {
  .ui-empty-state--centered-layout .ui-empty-state__subtitle {
    font-size: 0.875rem
  }
}

.ui-empty-state--centered-layout .ui-empty-state__section {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.ui-empty-state--centered-layout .ui-empty-state__subsection {
  text-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.ui-empty-state--centered-layout .ui-empty-state__subsection>*:not(.hide)+* {
  margin-top: 1rem
}

.ui-empty-state--centered-layout .ui-empty-state__subsection:not(:last-child) {
  padding-bottom: 0.5rem
}

.ui-empty-state--centered-layout .ui-empty-state__items {
  width: 100%
}

.ui-empty-state--centered-layout .ui-empty-state__subitems {
  margin: 0
}

.ui-empty-state--centered-layout .ui-empty-state__subitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center
}

.ui-empty-state--centered-layout .ui-empty-state__subsection__background {
  width: 226px;
  max-width: 100%
}

.ui-empty-state--centered-layout .ui-empty-state__subsection__background img {
  position: static;
  max-width: 100%;
  width: auto;
  margin-left: 0;
  margin-top: 0
}

.ui-exception-list {
  list-style: none
}

.ui-exception-list__item {
  position: relative;
  padding-left: 1.5rem
}

.ui-exception-list__item+.ui-exception-list__item {
  margin-top: 0.25rem
}

.ui-exception-list__icon {
  position: absolute;
  top: 0.0625rem;
  left: 0;
  font-size: 0.875rem
}

.ui-exception-list__icon .next-icon,
.ui-exception-list__icon .next-icon__text {
  color: var(--p-icon-subdued)
}

.ui-exception-list__title {
  color: var(--p-text-subdued)
}

.ui-exception-list__description {
  color: var(--p-text-subdued)
}

.ui-exception-list__title+.ui-exception-list__description::before {
  content: '\0020 \2013 \0020';
  display: inline
}

.ui-exception-list--type-bullet .ui-exception-list__item::before {
  content: '';
  position: absolute;
  top: 0.7em;
  left: 0.4375rem;
  display: block;
  height: 0.375rem;
  width: 0.375rem;
  margin-top: -0.1875rem;
  background-color: var(--p-text-subdued);
  border-radius: 50%
}

.ui-exception-list__item--status-critical .ui-exception-list__title,
.ui-exception-list__item--status-critical .ui-exception-list__description:only-child {
  color: var(--p-text-critical)
}

.ui-exception-list .ui-exception-list__item--status-critical::before {
  background-color: var(--p-text-critical)
}

.ui-exception-list__item--status-critical .ui-exception-list__icon .next-icon,
.ui-exception-list__item--status-critical .ui-exception-list__icon .next-icon__text {
  color: transparent;
  fill: var(--p-icon-critical)
}

.ui-exception-list__item--status-warning .ui-exception-list__title,
.ui-exception-list__item--status-warning .ui-exception-list__description:only-child {
  color: var(--p-text-warning)
}

.ui-exception-list .ui-exception-list__item--status-warning::before {
  background-color: var(--p-text-warning)
}

.ui-exception-list__item--status-warning .ui-exception-list__icon .next-icon,
.ui-exception-list__item--status-warning .ui-exception-list__icon .next-icon__text {
  color: transparent;
  fill: var(--p-icon-warning)
}

.ui-exception-list__item--truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.ui-flag {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 48px
}

.ui-flag>* {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.ui-flag--size-small {
  width: 24px
}

.ui-flash-wrapper {
  position: fixed;
  z-index: 552;
  bottom: 1rem;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  width: 100%;
  pointer-events: none
}

.ui-flash {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 1rem;
  background: var(--p-surface);
  -webkit-box-shadow: var(--p-override-none);
  box-shadow: var(--p-override-none);
  border-radius: var(--p-border-radius-wide);
  color: var(--p-text, white);
  cursor: default;
  max-width: 400px;
  pointer-events: auto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem
}

@media (min-width: 640px) {
  .ui-flash {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.ui-flash__close-button {
  display: none
}

.ui-flash__close-button .ui-button--transparent:hover,
.ui-flash__close-button .ui-button--transparent:active {
  background: transparent
}

.ui-flash__message {
  padding: 0.5rem 1rem
}

.ui-flash--error {
  background: var(--p-action-critical);
  color: var(--p-text-on-critical)
}

.ui-flash--error .ui-flash__message {
  padding: 0.5rem 0.5rem 0.5rem 1rem
}

.ui-flash--error .ui-flash__close-button {
  display: block;
  padding: 0.25rem 0.5rem;
  color: var(--p-text-on-critical)
}

.ui-flash--error .ui-flash__close-button .ui-button {
  color: inherit;
  vertical-align: middle
}

.ui-flash--theme-editor-footer-offset {
  bottom: 3.5rem
}

@media screen and (min-width: 768px) {
  .ui-flash--nav-offset {
    margin-left: 15rem
  }
}

.ui-flash-wrapper--is-visible {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.ui-flash--is-animating-in {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-name: flashAnimateIn;
  animation-name: flashAnimateIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out
}

.ui-flash--is-animating-out {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-name: flashAnimateOut;
  animation-name: flashAnimateOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in
}

@-webkit-keyframes flashAnimateIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px)
  }

  33% {
    opacity: 1
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes flashAnimateIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px)
  }

  33% {
    opacity: 1
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  @-webkit-keyframes flashAnimateIn {
    0% {
      opacity: 0
    }

    100% {
      opacity: 1
    }
  }

  @keyframes flashAnimateIn {
    0% {
      opacity: 0
    }

    100% {
      opacity: 1
    }
  }
}

@-webkit-keyframes flashAnimateOut {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@keyframes flashAnimateOut {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.ui-frame,
.frame {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid currentColor;
  color: #95a7b7
}

.ui-frame.ui-frame--fill,
.ui-frame.frame--fill,
.frame.ui-frame--fill,
.frame.frame--fill {
  background: #95a7b7
}

.ui-frame--size-12,
.frame--12 {
  height: 12px;
  width: 12px
}

.ui-frame--size-16,
.frame--16 {
  height: 16px;
  width: 16px
}

.ui-frame--size-24,
.frame--24 {
  height: 24px;
  width: 24px
}

.ui-frame--size-32,
.frame--32 {
  height: 32px;
  width: 32px
}

.ui-frame--size-36,
.frame--36 {
  height: 36px;
  width: 36px
}

.ui-frame--size-48,
.frame--48 {
  height: 48px;
  width: 48px
}

.ui-frame--size-80,
.frame--80 {
  height: 80px;
  width: 80px
}

.ui-frame--circle,
.frame--circle {
  border-radius: 50%
}

.ui-frame--type-subdued,
.frame--subdued {
  color: #c4cdd5
}

.ui-frame--type-subdued.ui-frame--fill,
.ui-frame--type-subdued.frame--fill,
.frame--subdued.ui-frame--fill,
.frame--subdued.frame--fill {
  background: #c4cdd5
}

.ui-frame--type-info,
.frame--info {
  color: #006fbb
}

.ui-frame--type-info.ui-frame--fill,
.ui-frame--type-info.frame--fill,
.frame--info.ui-frame--fill,
.frame--info.frame--fill {
  background: #006fbb
}

.ui-frame--type-success,
.frame--success {
  color: #50b83c
}

.ui-frame--type-success.ui-frame--fill,
.ui-frame--type-success.frame--fill,
.frame--success.ui-frame--fill,
.frame--success.frame--fill {
  background: #50b83c
}

.ui-frame--type-error,
.frame--error {
  color: #bf0711
}

.ui-frame--type-error.ui-frame--fill,
.ui-frame--type-error.frame--fill,
.frame--error.ui-frame--fill,
.frame--error.frame--fill {
  background: #bf0711
}

.ui-footer-help {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: auto;
  margin: 1.25rem 0.5rem;
  text-align: center
}

@media screen and (max-width: 568px) {
  .ui-footer-help {
    width: 100%;
    margin: 1.25rem 0
  }
}

.ui-footer-help__content {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  padding: 1.25rem 1.25rem 1.25rem 1rem;
  border: var(--p-override-none, 1px solid var(--p-border-subdued));
  border-radius: var(--p-override-none, 999px);
  color: var(--p-text, #212b36);
  text-align: left
}

@media screen and (max-width: 568px) {
  .ui-footer-help__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border-right: 0;
    border-left: 0;
    border-radius: 0
  }
}

.ui-footer-help__content ul {
  list-style: disc outside none;
  margin-left: 1rem
}

.ui-footer-help__icon {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0.5rem;
  padding: var(--p-override-none, 0.5rem);
  border-radius: 50%;
  color: var(--p-text, #95a7b7)
}

.ui-footer-help__icon svg {
  fill: var(--p-background, #47c1bf);
  color: var(--p-icon-highlight, white)
}

.ui-footer-help__icon::before {
  content: var(--p-override-none, "");
  position: absolute;
  right: 0.25rem;
  height: 2rem;
  width: 2rem;
  background: white;
  border-radius: 50%
}

.ui-footer-help__title {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: initial;
  letter-spacing: initial
}

@media screen and (min-width: 640px) {
  .ui-footer-help__title {
    font-size: 0.875rem
  }
}

.ui-heading {
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.5rem
}

@media screen and (min-width: 640px) {
  .ui-heading {
    font-size: 1rem
  }
}

.ui-keyboard-key {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: auto;
  min-width: 1.375rem;
  margin: 0 0.25rem 0.5rem 0;
  padding: 0.25rem;
  background-color: var(--p-surface);
  border: 0;
  -webkit-box-shadow: 0 0 0 1px var(--p-border-subdued), 0 0.18em 0 0 var(--p-surface), 0 0.18em 0 1px var(--p-border-subdued);
  box-shadow: 0 0 0 1px var(--p-border-subdued), 0 0.18em 0 0 var(--p-surface), 0 0.18em 0 1px var(--p-border-subdued);
  border-radius: var(--p-border-radius-base);
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.875rem;
  color: var(--p-text-subdued);
  cursor: default;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.ui-keyboard-key~.ui-keyboard-key {
  margin-left: 0.25rem
}

.ui-layout {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 62.375rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem
}

@media (max-width: 769px) and (min-width: 490px),
(min-width: 730px) {
  .ui-layout {
    padding-right: 1.25rem;
    padding-left: 1.25rem
  }
}

@media (max-width: 769px) and (min-width: 744px),
(min-width: 984px) {
  .ui-layout {
    padding-right: 2rem;
    padding-left: 2rem
  }
}

@media screen and (max-width: 568px) {
  .ui-layout {
    padding-right: 0;
    padding-left: 0
  }
}

.ui-layout+.ui-layout {
  margin-top: 0
}

.ui-layout--full-width {
  max-width: none
}

.ui-layout--single-column {
  max-width: 41.375rem
}

.ui-layout__sections {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: -1rem;
  margin-left: -1rem;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0
}

.ui-layout__section {
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  margin-top: -1rem;
  margin-left: -1rem;
  padding-top: 1rem;
  padding-left: 1rem
}

.ui-layout__section--primary {
  -webkit-box-flex: 2;
  -webkit-flex: 2 1 30rem;
  -ms-flex: 2 1 30rem;
  flex: 2 1 30rem
}

.ui-layout__section--secondary {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 15rem;
  -ms-flex: 1 0 15rem;
  flex: 1 0 15rem
}

.ui-layout__section--one-half {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 28.125rem;
  -ms-flex: 1 1 28.125rem;
  flex: 1 1 28.125rem
}

.ui-layout__item {
  min-width: 0;
  max-width: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  padding-top: 1rem;
  padding-left: 1rem
}

.ui-nav {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  padding-top: 1rem
}

.ui-nav .ui-nav__item+.ui-nav__item {
  border-top: 0.0625rem solid transparent
}

.ui-nav .ui-nav__link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 2.25rem;
  border-radius: var(--p-border-radius-base);
  padding: 0 0.5rem;
  margin: 0 0.5rem;
  position: relative
}

.ui-nav .ui-nav__link::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(0px + 0.0625rem));
  right: calc(-1 * calc(0px + 0.0625rem));
  bottom: calc(-1 * calc(0px + 0.0625rem));
  left: calc(-1 * calc(0px + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.0625rem)
}

.ui-nav .ui-nav__link:hover {
  background-color: var(--p-background-hovered)
}

.ui-nav .ui-nav__link:active {
  background-color: var(--p-background-pressed)
}

.ui-nav .ui-nav__link:focus {
  color: var(--p-text);
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none
}

.ui-nav .ui-nav__link:focus::after {
  -webkit-box-shadow: 0 0 0 0.125rem var(--p-focused);
  box-shadow: 0 0 0 0.125rem var(--p-focused)
}

.ui-nav .ui-nav__link--child {
  padding-left: 2.75rem;
  min-height: 2rem
}

.ui-nav .ui-nav__label--child {
  color: var(--p-text-subdued)
}

.ui-nav .ui-nav__item--selected {
  position: relative
}

.ui-nav .ui-nav__item--selected .ui-nav__link {
  background-color: var(--p-background-selected)
}

.ui-nav .ui-nav__item--selected .ui-nav__link:hover {
  background-color: var(--p-background-hovered)
}

.ui-nav .ui-nav__item--selected .ui-nav__link:active {
  background-color: var(--p-background-pressed)
}

.ui-nav .ui-nav__item--selected .ui-nav__label {
  color: var(--p-action-primary)
}

.ui-nav .ui-nav__item--selected::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background: var(--p-action-primary);
  width: 0.1875rem;
  height: 100%;
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base)
}

.ui-nav__item--disabled.ui-nav__item--disabled>.ui-nav {
  opacity: 1
}

.ui-nav .ui-nav__view-all {
  padding: 0.25rem 0.5rem
}

.ui-nav .next-icon,
.ui-nav .next-icon__text {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center
}

.ui-nav>:last-child {
  padding-bottom: 1rem;
  padding-bottom: calc(1rem + constant(safe-area-inset-bottom));
  padding-bottom: calc(1rem + env(safe-area-inset-bottom))
}

.ui-nav__heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 1.25rem;
  padding-left: 1rem;
  min-height: 2rem
}

.ui-nav__heading:first-child {
  margin-top: 0
}

.ui-nav__heading-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.ui-nav__heading--hidden {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important
}

.body:hover .ui-nav__heading--hidden a,
.body:hover .ui-nav__heading--hidden input,
.body:hover .ui-nav__heading--hidden button {
  display: none !important
}

.ui-nav__group {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style: none;
  min-width: 0
}

.ui-nav__group--parent {
  margin-top: 1.25rem
}

.ui-nav__group--parent:first-child,
.ui-nav__heading:not(.ui-nav__heading--hidden)+.ui-nav__group--parent,
.ui-nav__heading:first-child+.ui-nav__group--parent {
  margin-top: 0
}

.ui-nav__group--child {
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%
}

.ui-nav__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start
}

.ui-nav__item--child:last-child {
  margin-bottom: 0.5rem
}

.ui-nav__item--disabled>.ui-nav__group--child {
  display: none
}

.ui-nav__link.ui-nav__link,
.ui-nav__link.ui-nav__link.ui-button--link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0.5rem;
  min-width: 0
}

.ui-nav__link.ui-nav__link>.next-icon,
.ui-nav__link.ui-nav__link>.next-icon__text,
.ui-nav__link.ui-nav__link.ui-button--link>.next-icon {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  padding: 0 0.5rem;
  color: var(--p-override-transparent);
  fill: var(--p-icon)
}

@media screen and (min-width: 769px) {

  .ui-nav__link.ui-nav__link>.next-icon,
  .ui-nav__link.ui-nav__link>.next-icon__text,
  .ui-nav__link.ui-nav__link.ui-button--link>.next-icon {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem
  }
}

.ui-nav__link.ui-nav__link:hover,
.ui-nav__link.ui-nav__link.ui-button--link:hover {
  text-decoration: none
}

@media screen and (min-width: 769px) {

  .ui-nav__link.ui-nav__link:hover,
  .ui-nav__link.ui-nav__link.ui-button--link:hover {
    color: var(--p-text)
  }

  .ui-nav__link.ui-nav__link:hover>.next-icon,
  .ui-nav__link.ui-nav__link:hover>.next-icon__text,
  .ui-nav__link.ui-nav__link.ui-button--link:hover>.next-icon {
    fill: var(--p-icon)
  }
}

.ui-nav__item--child-selected>.ui-nav__link.ui-nav__link>.next-icon,
.ui-nav__item--child-selected>.ui-nav__link.ui-nav__link>.next-icon__text,
.ui-nav__item--child-selected>.ui-nav__link.ui-nav__link.ui-button--link>.next-icon {
  fill: var(--p-action-primary)
}

.ui-nav__item--disabled.ui-nav__item--disabled>.ui-nav__link.ui-nav__link,
.ui-nav__item--disabled.ui-nav__item--disabled>.ui-nav__link.ui-nav__link.ui-button--link {
  color: var(--p-text-disabled);
  opacity: 0.6
}

.ui-nav__item--disabled.ui-nav__item--disabled>.ui-nav__link.ui-nav__link>.next-icon,
.ui-nav__item--disabled.ui-nav__item--disabled>.ui-nav__link.ui-nav__link>.next-icon__text,
.ui-nav__item--disabled.ui-nav__item--disabled>.ui-nav__link.ui-nav__link.ui-button--link>.next-icon {
  fill: var(--p-icon-disabled)
}

.ui-nav__item--disabled.ui-nav__item--disabled>.ui-nav__link.ui-nav__link:hover,
.ui-nav__item--disabled.ui-nav__item--disabled>.ui-nav__link.ui-nav__link.ui-button--link:hover {
  color: var(--p-text-disabled)
}

.ui-nav__item--disabled.ui-nav__item--disabled>.ui-nav__link.ui-nav__link:active,
.ui-nav__item--disabled.ui-nav__item--disabled>.ui-nav__link.ui-nav__link.ui-button--link:active {
  background: var(--p-surface)
}

.ui-nav__link--parent.ui-nav__link--parent,
.ui-nav__link--parent.ui-nav__link--parent.ui-button--link {
  color: var(--p-text)
}

.ui-nav__link--parent.ui-nav__link--parent:hover,
.ui-nav__link--parent.ui-nav__link--parent.ui-button--link:hover {
  color: var(--p-text)
}

@media screen and (min-width: 769px) {

  .ui-nav__link--parent.ui-nav__link--parent:hover,
  .ui-nav__link--parent.ui-nav__link--parent.ui-button--link:hover {
    color: var(--p-text)
  }
}

.ui-nav__item--selected>.ui-nav__link--parent.ui-nav__link--parent,
.ui-nav__item--selected>.ui-nav__link--parent.ui-nav__link--parent.ui-button--link {
  color: var(--p-action-primary);
  text-decoration: none
}

.ui-nav__item--selected>.ui-nav__link--parent.ui-nav__link--parent>.next-icon,
.ui-nav__item--selected>.ui-nav__link--parent.ui-nav__link--parent>.next-icon__text,
.ui-nav__item--selected>.ui-nav__link--parent.ui-nav__link--parent.ui-button--link>.next-icon {
  fill: var(--p-action-primary)
}

.ui-nav__link--parent.ui-nav__link--parent:active,
.ui-nav__link--parent.ui-nav__link--parent.ui-button--link:active {
  color: var(--p-text)
}

.ui-nav__link--parent.ui-nav__link--parent:active>.next-icon,
.ui-nav__link--parent.ui-nav__link--parent:active>.next-icon__text,
.ui-nav__link--parent.ui-nav__link--parent.ui-button--link:active>.next-icon {
  fill: var(--p-icon)
}

.ui-nav__link--child.ui-nav__link--child {
  padding-left: 2.75rem;
  color: var(--p-text)
}

.ui-nav__link--child.ui-nav__link--child:hover {
  color: var(--p-text)
}

@media screen and (min-width: 769px) {
  .ui-nav__link--child.ui-nav__link--child:hover {
    color: var(--p-text)
  }
}

.ui-nav__link--child.ui-nav__link--child:focus,
.ui-nav__item--selected>.ui-nav__link--child.ui-nav__link--child {
  color: var(--p-action-primary);
  text-decoration: none
}

.ui-nav__link--child.ui-nav__link--child:focus>.next-icon,
.ui-nav__link--child.ui-nav__link--child:focus>.next-icon__text,
.ui-nav__item--selected>.ui-nav__link--child.ui-nav__link--child>.next-icon,
.ui-nav__item--selected>.ui-nav__link--child.ui-nav__link--child>.next-icon__text {
  fill: var(--p-action-primary)
}

.ui-nav__link--child.ui-nav__link--child:active {
  color: var(--p-action-primary)
}

.ui-nav__link--child.ui-nav__link--child:active>.next-icon,
.ui-nav__link--child.ui-nav__link--child:active>.next-icon__text {
  fill: var(--p-action-primary)
}

.ui-nav__label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  padding-right: 0.5rem;
  padding-left: 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  font-weight: 500
}

@media screen and (min-width: 769px) {
  .ui-nav__label {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
    font-size: 0.875rem;
    padding-left: 0.5rem
  }
}

@media screen and (min-width: 769px) {
  .ui-nav__label {
    font-size: 0.875rem
  }
}

.ui-nav__label--parent {
  font-weight: 600
}

.ui-nav__label--child {
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.25rem
}

@media screen and (min-width: 769px) {
  .ui-nav__label--child {
    font-size: 0.875rem;
    line-height: 1.25rem
  }
}

@media screen and (min-width: 769px) {
  .ui-nav__label--child {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
  }
}

.ui-nav__badge {
  font-weight: 600;
  margin: 0 0.5rem;
  padding: 0 0.375rem;
  background-color: var(--p-surface-neutral, #47c1bf);
  border-radius: 0.5rem;
  font-size: 0.6875rem;
  line-height: 1rem;
  color: var(--p-text)
}

.ui-nav__action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2.5rem
}

@media screen and (min-width: 769px) {
  .ui-nav__action {
    height: 2rem
  }
}

.ui-nav__action>.ui-button.ui-button {
  padding-right: 1rem;
  padding-left: 1rem
}

@media screen and (min-width: 769px) {
  .ui-nav__action>.ui-button.ui-button {
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem
  }
}

.ui-nav__action>.ui-button.ui-button>.next-icon,
.ui-nav__action>.ui-button.ui-button>.next-icon__text {
  color: var(--p-override-transparent);
  fill: var(--p-icon)
}

@media screen and (min-width: 769px) {

  .ui-nav__action>.ui-button.ui-button:hover>.next-icon,
  .ui-nav__action>.ui-button.ui-button:hover>.next-icon__text {
    fill: var(--p-icon)
  }
}

.ui-nav__action>.ui-button.ui-button:focus>.next-icon,
.ui-nav__action>.ui-button.ui-button:focus>.next-icon__text {
  fill: var(--p-icon)
}

.ui-nav__action>.ui-button.ui-button:active>.next-icon,
.ui-nav__action>.ui-button.ui-button:active>.next-icon__text {
  fill: var(--p-icon)
}

.ui-nav__view-all {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-top: 0.25rem
}

.ui-nav__view-all>.ui-button--link.ui-button--link {
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.25rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  color: var(--p-text)
}

@media screen and (min-width: 769px) {
  .ui-nav__view-all>.ui-button--link.ui-button--link {
    font-size: 0.875rem;
    line-height: 1.25rem
  }
}

@media screen and (min-width: 769px) {
  .ui-nav__view-all>.ui-button--link.ui-button--link {
    height: 2rem
  }
}

.ui-nav__view-all>.ui-button--link.ui-button--link:hover {
  color: var(--p-text);
  text-decoration: none
}

@media screen and (min-width: 769px) {
  .ui-nav__view-all>.ui-button--link.ui-button--link:hover {
    color: var(--p-text)
  }
}

.ui-nav__view-all>.ui-button--link.ui-button--link:focus {
  color: var(--p-text);
  text-decoration: none
}

.ui-nav__view-all>.ui-button--link.ui-button--link:active {
  color: var(--p-text)
}

.ui-nav__view-all-icon {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 3.5rem
}

@media screen and (min-width: 769px) {
  .ui-nav__view-all-icon {
    width: 3.25rem
  }
}

.ui-nav__align-bottom-shim {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.ui-nav__item--parent {
  -webkit-box-sizing: content-box;
  box-sizing: content-box
}

.ui-page-actions {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 62.375rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  margin: 1rem auto 0;
  padding-top: 1rem;
  border-top: 1px solid var(--p-divider)
}

@media (max-width: 769px) and (min-width: 490px),
(min-width: 730px) {
  .ui-page-actions {
    padding-right: 1.25rem;
    padding-left: 1.25rem
  }
}

@media (max-width: 769px) and (min-width: 744px),
(min-width: 984px) {
  .ui-page-actions {
    padding-right: 2rem;
    padding-left: 2rem
  }
}

.ui-page-actions--single-column {
  max-width: 41.375rem
}

.ui-page-actions__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
  padding: 0
}

.ui-page-actions--has-secondary .ui-page-actions__container {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.ui-page-actions__actions {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  max-width: calc(100% - 0.5rem);
  min-width: 0
}

.ui-page-actions__button-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -0.5rem;
  margin-left: -0.5rem
}

.ui-page-actions__button-group .ui-button,
.ui-page-actions__button-group .btn {
  margin-top: 0.5rem;
  margin-left: 0.5rem
}

.ui-page-actions__button-group .ui-button--destructive,
.ui-page-actions__button-group .btn-destroy {
  position: relative;
  background: transparent;
  border: 1px solid var(--p-border-critical);
  color: var(--p-interactive-critical);
  -webkit-box-shadow: none;
  box-shadow: none
}

.ui-page-actions__button-group .ui-button--destructive::after,
.ui-page-actions__button-group .btn-destroy::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(0.0625rem + 0.0625rem));
  right: calc(-1 * calc(0.0625rem + 0.0625rem));
  bottom: calc(-1 * calc(0.0625rem + 0.0625rem));
  left: calc(-1 * calc(0.0625rem + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(0.0625rem + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(0.0625rem + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.0625rem)
}

.ui-page-actions__button-group .ui-button--destructive:hover,
.ui-page-actions__button-group .ui-button--destructive:active,
.ui-page-actions__button-group .btn-destroy:hover,
.ui-page-actions__button-group .btn-destroy:active {
  background: var(--p-surface-critical-subdued)
}

.ui-page-actions__button-group .ui-button--destructive:focus,
.ui-page-actions__button-group .btn-destroy:focus {
  border-width: 1px;
  -webkit-box-shadow: none;
  box-shadow: none
}

.ui-popover__container {
  position: relative;
  display: inline-block
}

.ui-popover__container--contains-active-popover {
  z-index: 23
}

.ui-popover__container--is-deactivating {
  z-index: 22
}

.ui-popover__container--full-width-container {
  display: block;
  width: 100%
}

.ui-popover {
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  display: none;
  margin: 0.5rem 1rem;
  background-color: var(--p-surface);
  -webkit-box-shadow: var(--p-popover-shadow);
  box-shadow: var(--p-popover-shadow);
  border-radius: var(--p-border-radius-wide);
  max-width: 100%;
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1)
}

@media screen and (prefers-reduced-motion: reduce) {
  .ui-popover {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

.ui-popover--tight-spacing {
  margin-top: 0.25rem
}

.ui-popover--is-transitioning {
  display: block
}

.ui-popover--is-active {
  -webkit-transform: scale(1);
  transform: scale(1);
  display: block;
  opacity: 1
}

.ui-popover--is-positioned-above {
  top: auto;
  bottom: 100%
}

.ui-popover--reduced-spacing .ui-popover__section,
.ui-popover--reduced-spacing .ui-popover__pane--fixed {
  padding: 0.5rem
}

.ui-popover--full-width {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  width: 100%;
  max-width: none
}

.ui-popover--full-width.ui-popover--is-active {
  -webkit-transform: scale(1);
  transform: scale(1)
}

.ui-popover--full-width .ui-popover__content-wrapper,
.ui-popover--full-width .ui-popover__content {
  width: 100%;
  max-width: none
}

.ui-popover--full-height .ui-popover__content-wrapper {
  max-height: none
}

.ui-popover__content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: var(--p-border-radius-base);
  max-width: 550px;
  max-height: 300px
}

.ui-popover__content {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: inherit;
  text-align: left;
  max-height: 100%;
  min-width: 150px
}

.ui-popover__content>*:first-child {
  margin-top: 0
}

.ui-popover__content>*:last-child {
  margin-bottom: 0
}

.ui-popover__content--is-calculating {
  white-space: nowrap !important
}

.ui-popover__content--is-calculating * {
  white-space: nowrap !important
}

.ui-popover__pane {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  border-radius: inherit;
  max-width: 100%;
  -webkit-transition: -webkit-box-shadow 200ms ease;
  transition: -webkit-box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease, -webkit-box-shadow 200ms ease
}

.ui-popover__pane+.ui-popover__pane {
  border-top: 1px solid var(--p-divider)
}

.ui-popover__pane--expand {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.ui-popover__pane--fixed {
  overflow: visible;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 1rem
}

.ui-popover__section {
  padding: 1rem
}

.ui-popover__section+.ui-popover__section {
  border-top: 1px solid var(--p-divider)
}

.ui-popover__section--compact {
  padding: 0.5rem
}

.ui-popover__section--compact+.ui-popover__section {
  border-top: 1px solid var(--p-divider)
}

.ui-popover__section--no-padding {
  padding: 0
}

.ui-popover__container[data-searchable-select] .next-input--invisible {
  width: 100%
}

.ui-progress-bar {
  overflow: hidden;
  height: 1rem;
  width: 100%;
  background-color: var(--p-action-secondary);
  border-radius: var(--p-border-radius-base)
}

.ui-progress-bar--size-small {
  height: 0.5rem
}

.ui-progress-bar--size-large {
  height: 2rem
}

.ui-progress-bar__indicator {
  height: inherit;
  width: 0%;
  background-color: var(--p-border-highlight)
}

.ui-progress-bar__element {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important
}

.body:hover .ui-progress-bar__element a,
.body:hover .ui-progress-bar__element input,
.body:hover .ui-progress-bar__element button {
  display: none !important
}

.ui-progress-bar__label {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important
}

.body:hover .ui-progress-bar__label a,
.body:hover .ui-progress-bar__label input,
.body:hover .ui-progress-bar__label button {
  display: none !important
}

.ui-resource-list-section {
  overflow-x: hidden
}

.ui-resource-list-section+.ui-resource-list-section .ui-resource-list-tools {
  border-top: 1px solid var(--p-divider)
}

@media print,
screen and (min-width: 569px) {
  .ui-resource-list-section {
    overflow-x: visible
  }
}

.ui-resource-list-section--has-active-popover {
  overflow-x: visible
}

.ui-resource-list {
  list-style: none;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms
}

.ui-resource-list.ui-resource-list--selectable {
  -webkit-transform: translateX(-2.25rem);
  transform: translateX(-2.25rem);
  width: calc(100% + 2.25rem)
}

@media print,
screen and (min-width: 569px) {
  .ui-resource-list.ui-resource-list--selectable {
    -webkit-transform: none;
    transform: none;
    width: 100%
  }
}

.ui-resource-list.ui-resource-list--editable {
  -webkit-transform: translateX(0);
  transform: translateX(0)
}

.ui-resource-list-section--is-loading .ui-resource-list {
  position: relative
}

.ui-resource-list-section--is-loading .ui-resource-list::before {
  content: '';
  position: absolute;
  z-index: 3;
  top: -1px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--p-surface);
  opacity: 0.5
}

.ui-resource-list-section--is-loading .ui-resource-list:not(.ui-resource-list--empty)::after {
  content: '';
  position: absolute;
  z-index: 4;
  top: 0.625rem;
  left: calc(50% + 1.125rem);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 2.75rem;
  width: 2.75rem;
  margin-left: -1.375rem;
  border: 0.1875rem solid var(--p-border-highlight);
  border-left-color: transparent;
  -webkit-animation: loading-spinner 0.5s linear infinite;
  animation: loading-spinner 0.5s linear infinite;
  border-radius: 50%;
  -webkit-transition: left 200ms cubic-bezier(0.64, 0, 0.35, 1);
  transition: left 200ms cubic-bezier(0.64, 0, 0.35, 1)
}

.ui-resource-list-section--is-loading .ui-resource-list.ui-resource-list--editable:not(.ui-resource-list--empty)::after {
  left: calc(50% - 1.125rem)
}

@media screen and (min-width: 569px) {
  .ui-resource-list-section--is-loading .ui-resource-list:not(.ui-resource-list--empty)::after {
    left: 50%
  }
}

@-webkit-keyframes loading-spinner {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes loading-spinner {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.ui-resource-list-tools+.ui-resource-list>*:first-child {
  border-top: 1px solid var(--p-divider)
}

.ui-resource-list-tools+.ui-resource-list {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.ui-resource-list__pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1.5rem 1rem;
  border-top: 1px solid var(--p-divider)
}

.ui-resource-list-section--is-loading .ui-resource-list__pagination {
  position: relative
}

.ui-resource-list-section--is-loading .ui-resource-list__pagination::before {
  content: '';
  position: absolute;
  z-index: 3;
  top: -1px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--p-surface);
  opacity: 0.5
}

.ui-resource-list-tools {
  background-color: var(--p-surface);
  border-top-left-radius: var(--p-border-radius-wide);
  border-top-right-radius: var(--p-border-radius-wide)
}

.ui-resource-list-tools--no-bulk {
  padding-bottom: 0.625rem
}

.ui-resource-list-tools__sticky-container {
  overflow-x: hidden;
  padding-bottom: 0.625rem
}

@media print {
  .ui-resource-list-tools__sticky-container {
    display: none
  }
}

.ui-resource-list-tools--sticky .ui-resource-list-tools__sticky-container {
  position: fixed;
  z-index: 10;
  top: 3.5rem;
  bottom: auto;
  width: 100%;
  background-color: var(--p-surface);
  -webkit-box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)
}

.ui-resource-list-tools__sticky-container--has-active-popover {
  overflow-x: visible
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__sticky-container--sort-has-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
  }
}

.ui-resource-list-tools__filter-container {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__filter-container {
    padding-right: 1rem;
    padding-left: 1rem
  }
}

@media screen and (min-width: 568px) {
  .ui-resource-list-tools__filter-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
  }
}

@media print {
  .ui-resource-list-tools__filter-container {
    display: none
  }
}

.ui-resource-list-tools__filter-container .ui-resource-list-tools__sort {
  display: none;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  padding-top: 1rem
}

.ui-resource-list-tools__filter-container .ui-resource-list-tools__sort:last-child {
  margin-left: 0.5rem
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__filter-container .ui-resource-list-tools__sort {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
  }
}

.ui-resource-list-tools__filter-container .ui-resource-list-tools__sort .next-label {
  margin-right: 1rem;
  white-space: nowrap
}

@media screen and (max-width: 1024px) {
  .ui-resource-list-tools__filter-container .ui-resource-list-tools__sort .next-label {
    display: none
  }
}

.ui-resource-list-tools__sort-container {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__sort-container {
    padding-right: 1rem;
    padding-left: 1rem
  }
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__sort-container {
    display: none
  }
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__sticky-container--sort-has-block .ui-resource-list-tools__sort-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
  }
}

.ui-resource-list-tools--editable .ui-resource-list-tools__sort-container,
.ui-resource-list-tools--sticky .ui-resource-list-tools__sort-container {
  display: none
}

.ui-resource-list-tools__sort-container .ui-resource-list-tools__sort {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding-top: 0.625rem
}

.ui-resource-list-tools__sort-container .ui-resource-list-tools__sort .next-label {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important
}

.body:hover .ui-resource-list-tools__sort-container .ui-resource-list-tools__sort .next-label a,
.body:hover .ui-resource-list-tools__sort-container .ui-resource-list-tools__sort .next-label input,
.body:hover .ui-resource-list-tools__sort-container .ui-resource-list-tools__sort .next-label button {
  display: none !important
}

.ui-resource-list-tools__sort-container .ui-resource-list-tools__sort+.ui-resource-list-tools__toggle {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: 1rem
}

.ui-resource-list-tools__bulk-container {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: none
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__bulk-container {
    padding-right: 1rem;
    padding-left: 1rem
  }
}

.ui-resource-list-tools--editable .ui-resource-list-tools__bulk-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.ui-resource-list-tools--editable .ui-resource-list-tools__bulk-container .ui-resource-list-tools__toggle {
  display: none
}

.ui-resource-list-tools--sticky .ui-resource-list-tools__bulk-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__bulk-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
  }
}

.ui-resource-list-section--is-loading .ui-resource-list-tools__bulk-container {
  position: relative
}

.ui-resource-list-section--is-loading .ui-resource-list-tools__bulk-container::before {
  content: '';
  position: absolute;
  z-index: 3;
  top: -1px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--p-surface);
  opacity: 0.5
}

.ui-resource-list-tools__bulk-container--without-sort {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.ui-resource-list-tools__tags-container {
  padding-right: 0.75rem;
  padding-left: 0.75rem
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__tags-container {
    padding-right: 1rem;
    padding-left: 1rem
  }
}

.ui-resource-list-tools__tags {
  padding-top: 0.75rem
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__tags {
    padding-top: 1rem
  }
}

.ui-resource-list-tools__filter {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding-top: 0.75rem
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__filter {
    padding-top: 1rem
  }
}

.ui-resource-list-tools__filter .next-label {
  display: none
}

.ui-resource-list-tools__filter .ui-popover__container:first-child>.ui-button {
  left: 0;
  margin-right: 0
}

.ui-resource-list-tools__toggle {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding-top: 0.625rem
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__toggle {
    display: none
  }
}

.ui-resource-list-section--is-loading .ui-resource-list-tools__toggle {
  position: relative
}

.ui-resource-list-section--is-loading .ui-resource-list-tools__toggle::before {
  content: '';
  position: absolute;
  z-index: 3;
  top: -1px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--p-surface);
  opacity: 0.5
}

.ui-resource-list-tools__bulk {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  padding-top: 0.625rem
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__bulk {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 1.125rem
  }
}

@media screen and (min-width: 768px) {
  .ui-resource-list-tools__bulk {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
  }
}

@media screen and (min-width: 1024px) {
  .ui-resource-list-tools__bulk {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
  }
}

.ui-resource-list-tools__bulk-inner-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  -webkit-transition-property: -webkit-box-shadow;
  transition-property: -webkit-box-shadow;
  transition-property: box-shadow;
  transition-property: box-shadow, -webkit-box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1)
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools--editable .ui-resource-list-tools__bulk-inner-container {
    width: auto;
    margin-right: 1rem
  }
}

.ui-resource-list-tools__bulk-inner-container--has-pseudo-border {
  -webkit-box-shadow: 0 0 0 1px var(--p-border-subdued) inset;
  box-shadow: 0 0 0 1px var(--p-border-subdued) inset
}

.ui-resource-list-tools__bulk-check-box-container {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 2.25rem;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 1px solid transparent;
  border-top-left-radius: var(--p-border-radius-base);
  border-bottom-left-radius: var(--p-border-radius-base)
}

.ui-resource-list-tools--editable .ui-resource-list-tools__bulk-check-box-container {
  border-color: var(--p-border-neutral-subdued);
  -webkit-animation-name: bulk-check-box-slide-in;
  animation-name: bulk-check-box-slide-in;
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  animation-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  background: var(--p-action-secondary);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued)
}

.ui-resource-list-tools--editable .ui-resource-list-tools__bulk-check-box-container:hover {
  background: var(--p-action-secondary-hovered)
}

.ui-resource-list-tools--editable .ui-resource-list-tools__bulk-check-box-container:active {
  background: var(--p-action-secondary-pressed)
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools--editable .ui-resource-list-tools__bulk-check-box-container {
    -webkit-animation: none;
    animation: none
  }
}

.ui-resource-list-tools__bulk-actions {
  display: none
}

.ui-resource-list-tools__bulk-actions.ui-resource-list-tools__bulk-actions--is-visible {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}

.ui-resource-list-tools__bulk-actions .ui-button:focus {
  position: relative;
  z-index: 1
}

.ui-resource-list-tools__bulk-actions .ui-button[data-primary-action],
.ui-resource-list-tools__bulk-actions .ui-button[data-secondary-action] {
  display: none
}

@media screen and (min-width: 1024px) {

  .ui-resource-list-tools__bulk-actions .ui-button[data-primary-action],
  .ui-resource-list-tools__bulk-actions .ui-button[data-secondary-action] {
    display: initial
  }
}

@media screen and (min-width: 1024px) {

  .ui-resource-list-tools__bulk-actions .ui-action-list-action[data-primary-action],
  .ui-resource-list-tools__bulk-actions .ui-action-list-action[data-secondary-action] {
    display: none
  }
}

@media screen and (min-width: 1024px) {
  .ui-resource-list-tools__bulk-actions--no-bulk-provided .ui-popover__container {
    display: none
  }
}

.ui-resource-list-tools__bulk-actions--no-bulk-provided .ui-button:last-of-type {
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base)
}

.ui-resource-list-tools__bulk-cancel {
  display: none
}

.ui-resource-list-tools--editable .ui-resource-list-tools__bulk-cancel {
  display: initial
}

.ui-resource-list-tools__bulk-actions .ui-button {
  margin-left: -1px;
  border-radius: 0
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__bulk-actions>.ui-popover__container>.ui-button {
    margin-left: -1px;
    border-top-right-radius: var(--p-border-radius-base);
    border-bottom-right-radius: var(--p-border-radius-base)
  }
}

.ui-resource-list-tools__bulk-cancel>.ui-button {
  margin-left: -1px;
  border-top-right-radius: var(--p-border-radius-base);
  border-bottom-right-radius: var(--p-border-radius-base)
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__bulk-cancel>.ui-button {
    display: none
  }
}

.ui-resource-list-tools__bulk-check-box {
  display: none
}

.ui-resource-list-tools--editable .ui-resource-list-tools__bulk-check-box {
  position: relative;
  display: block
}

@media screen and (min-width: 568px) {
  .ui-resource-list-tools__bulk-check-box {
    display: block
  }
}

.ui-resource-list-tools__bulk-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-right: 0.5rem;
  padding-left: 0.5rem;
  cursor: pointer
}

.ui-resource-list-tools--editable .ui-resource-list-tools__bulk-content {
  font-weight: 600;
  color: var(--p-text-subdued)
}

.ui-resource-list-tools--selected .ui-resource-list-tools__bulk-content {
  color: var(--p-text)
}

@media screen and (min-width: 568px) {
  .ui-resource-list-tools__bulk-content {
    padding-right: 1rem;
    padding-left: 0.75rem
  }
}

.ui-resource-list-tools--editable .ui-resource-list-tools__bulk-showing {
  display: none
}

.ui-resource-list-tools--editable .showing {
  display: none
}

.ui-resource-list-tools--selected .ui-resource-list-tools__bulk-name,
.ui-resource-list-tools--editable .ui-resource-list-tools__bulk-name {
  display: none
}

.ui-resource-list-tools--selected .name,
.ui-resource-list-tools--editable .name {
  display: none
}

.ui-resource-list-tools__bulk-selected {
  display: none
}

.ui-resource-list-tools--editable .ui-resource-list-tools__bulk-selected {
  display: initial
}

.ui-resource-list-tools .selected {
  display: none
}

.ui-resource-list-tools--editable .selected {
  display: initial
}

.ui-resource-list-tools__resource-location--emphasized {
  font-weight: 600
}

.ui-resource-list-tools__paginated-select-all {
  display: none;
  width: 100%;
  padding-top: 0.5rem
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__paginated-select-all {
    padding-top: 0.75rem
  }
}

@media screen and (min-width: 1080px) {
  .ui-resource-list-tools__paginated-select-all {
    width: auto;
    padding-top: 0
  }
}

.ui-resource-list-tools__paginated-select-all--is-visible {
  display: block;
  -webkit-animation-name: slide-down;
  animation-name: slide-down;
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms
}

@media screen and (min-width: 569px) {
  .ui-resource-list-tools__paginated-select-all--is-visible {
    -webkit-animation: none;
    animation: none
  }
}

@-webkit-keyframes slide-in {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@keyframes slide-in {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@-webkit-keyframes slide-out {
  from {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  to {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
  }
}

@keyframes slide-out {
  from {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  to {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
  }
}

@-webkit-keyframes slide-down {
  from {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }
}

@keyframes slide-down {
  from {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }
}

@-webkit-keyframes bulk-check-box-slide-in {
  from {
    margin-left: -2.25rem
  }

  to {
    margin-left: 0
  }
}

@keyframes bulk-check-box-slide-in {
  from {
    margin-left: -2.25rem
  }

  to {
    margin-left: 0
  }
}

.ui-resource-list-item {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75rem 1rem;
  min-height: 2.75rem
}

.ui-resource-list-item+.ui-resource-list-item {
  border-top: 1px solid var(--p-divider)
}

@media screen and (min-width: 569px) {
  .ui-resource-list-item {
    padding-right: 1.25rem;
    padding-left: 1.25rem
  }
}

.ui-resource-list:first-child .ui-resource-list-item:first-child {
  border-top-left-radius: var(--p-border-radius-wide);
  border-top-right-radius: var(--p-border-radius-wide)
}

.ui-resource-list:last-child .ui-resource-list-item:last-child {
  border-bottom-left-radius: var(--p-border-radius-wide);
  border-bottom-right-radius: var(--p-border-radius-wide)
}

.ui-resource-list-item--actionable {
  position: relative;
  cursor: pointer
}

.ui-resource-list-item--actionable::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(0px + 0.0625rem));
  right: calc(-1 * calc(0px + 0.0625rem));
  bottom: calc(-1 * calc(0px + 0.0625rem));
  left: calc(-1 * calc(0px + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.0625rem)
}

.ui-resource-list-item--actionable:hover {
  background-color: var(--p-surface-selected-hovered)
}

.ui-resource-list-item--actionable:active {
  background-color: var(--p-surface-selected-pressed)
}

.ui-resource-list-item--actionable:focus {
  outline: none
}

@media screen and (max-width: 768px) {
  .ui-resource-list-item--actionable:focus {
    -webkit-box-shadow: inset 0.125rem 0 0 var(--p-focused);
    box-shadow: inset 0.125rem 0 0 var(--p-focused)
  }
}

@media screen and (min-width: 768px) {
  .ui-resource-list-item--actionable:focus:not(:active)::after {
    -webkit-box-shadow: 0 0 0 0.125rem var(--p-focused);
    box-shadow: 0 0 0 0.125rem var(--p-focused)
  }
}

.ui-resource-list-item--selected {
  outline: none;
  background-color: var(--p-surface-selected)
}

.ui-resource-list-item--selected:focus {
  background-color: var(--p-surface-selected)
}

.ui-resource-list-item--selected:hover {
  background-color: var(--p-surface-selected-hovered)
}

.ui-resource-list-item--selected:active {
  background-color: var(--p-surface-selected-pressed)
}

.ui-resource-list-item__owned {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start
}

@media print {
  .ui-resource-list-item__owned {
    display: none
  }
}

.ui-resource-list-item__media {
  overflow: hidden;
  margin-right: 1rem
}

.ui-resource-list-item__media.ui-resource-list-item__media--size-small {
  height: 2.5rem;
  width: 2.5rem
}

.ui-resource-list-item__media.ui-resource-list-item__media--size-small>img {
  max-height: 2.5rem;
  max-width: 2.5rem
}

@media screen and (min-width: 768px) {
  .ui-resource-list-item__media.ui-resource-list-item__media--size-small {
    height: 2rem;
    width: 2rem
  }

  .ui-resource-list-item__media.ui-resource-list-item__media--size-small>img {
    max-height: 2rem;
    max-width: 2rem
  }
}

.ui-resource-list-item__media.ui-resource-list-item__media--size-medium {
  height: 2.5rem;
  width: 2.5rem
}

.ui-resource-list-item__media.ui-resource-list-item__media--size-medium>img {
  max-height: 2.5rem;
  max-width: 2.5rem
}

.ui-resource-list-item__media.ui-resource-list-item__media--size-large {
  height: 3.125rem;
  width: 3.125rem
}

.ui-resource-list-item__media.ui-resource-list-item__media--size-large>img {
  max-height: 3.125rem;
  max-width: 3.125rem
}

.ui-resource-list-item__selector {
  width: 3rem;
  min-height: 2.75rem;
  margin: -0.75rem 0 -0.75rem -0.75rem;
  pointer-events: none
}

.ui-resource-list-item__selector .next-input-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%
}

.ui-resource-list-item__selector .next-input-wrapper .next-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}

.ui-resource-list-item__selector .next-input-wrapper .next-checkbox--styled {
  position: relative;
  top: auto;
  left: auto
}

@media screen and (min-width: 569px) {
  .ui-resource-list-item__selector {
    margin-right: 0.25rem
  }
}

.ui-resource-list-item__selector .ui-resource-list--editable {
  pointer-events: auto
}

@media screen and (min-width: 569px) {
  .ui-resource-list-item__selector {
    pointer-events: auto
  }
}

.ui-resource-list-item__content {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0
}

.ui-resource-list-item__persistent-buttons {
  display: none;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin: -0.25rem -0.75rem -0.25rem 0
}

@media screen and (min-width: 1024px) {
  .ui-resource-list-item__persistent-buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
  }
}

.ui-resource-list-item__floating-buttons {
  position: absolute;
  visibility: hidden;
  top: 0;
  right: 0.75rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  max-height: 3.5rem
}

@media screen and (min-width: 1024px) {
  .ui-resource-list-item__floating-buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
  }

  .ui-resource-list-item:hover .ui-resource-list-item__floating-buttons,
  .ui-resource-list-item--focused .ui-resource-list-item__floating-buttons {
    visibility: visible
  }
}

.ui-resource-list-item__actions-popover {
  display: block;
  margin: -0.25rem -0.25rem -0.25rem 0
}

@media screen and (min-width: 1024px) {
  .ui-resource-list-item__actions-popover {
    display: none
  }
}

.ui-resource-list-item__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  text-indent: -9999%
}

.ui-resource-list-item__promote-layer {
  position: relative;
  z-index: 1
}

.ui-rollup>* {
  display: none;
  opacity: 0
}

.ui-rollup>*:nth-child(1) {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms
}

.ui-rollup>*:nth-child(2) {
  -webkit-animation-delay: 50ms;
  animation-delay: 50ms
}

.ui-rollup>*:nth-child(3) {
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms
}

.ui-rollup>*:nth-child(4) {
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms
}

.ui-rollup>*:nth-child(5) {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms
}

.ui-rollup>*:nth-child(6) {
  -webkit-animation-delay: 250ms;
  animation-delay: 250ms
}

.ui-rollup>*:nth-child(7) {
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms
}

.ui-rollup>*:nth-child(8) {
  -webkit-animation-delay: 350ms;
  animation-delay: 350ms
}

.ui-rollup>*:nth-child(9) {
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms
}

.ui-rollup>*:nth-child(10) {
  -webkit-animation-delay: 450ms;
  animation-delay: 450ms
}

.ui-rollup>*:nth-child(n + 11) {
  -webkit-animation-delay: 500ms;
  animation-delay: 500ms
}

.ui-rollup--keep-visible-1>*:nth-child(1) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: none;
  animation: none;
  opacity: 1
}

.ui-rollup--keep-visible-1>*:nth-child(2) {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms
}

.ui-rollup--keep-visible-1>*:nth-child(3) {
  -webkit-animation-delay: 50ms;
  animation-delay: 50ms
}

.ui-rollup--keep-visible-1>*:nth-child(4) {
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms
}

.ui-rollup--keep-visible-1>*:nth-child(5) {
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms
}

.ui-rollup--keep-visible-1>*:nth-child(6) {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms
}

.ui-rollup--keep-visible-1>*:nth-child(7) {
  -webkit-animation-delay: 250ms;
  animation-delay: 250ms
}

.ui-rollup--keep-visible-1>*:nth-child(8) {
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms
}

.ui-rollup--keep-visible-1>*:nth-child(9) {
  -webkit-animation-delay: 350ms;
  animation-delay: 350ms
}

.ui-rollup--keep-visible-1>*:nth-child(10) {
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms
}

.ui-rollup--keep-visible-2>*:nth-child(1) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: none;
  animation: none;
  opacity: 1
}

.ui-rollup--keep-visible-2>*:nth-child(2) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: none;
  animation: none;
  opacity: 1
}

.ui-rollup--keep-visible-2>*:nth-child(3) {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms
}

.ui-rollup--keep-visible-2>*:nth-child(4) {
  -webkit-animation-delay: 50ms;
  animation-delay: 50ms
}

.ui-rollup--keep-visible-2>*:nth-child(5) {
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms
}

.ui-rollup--keep-visible-2>*:nth-child(6) {
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms
}

.ui-rollup--keep-visible-2>*:nth-child(7) {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms
}

.ui-rollup--keep-visible-2>*:nth-child(8) {
  -webkit-animation-delay: 250ms;
  animation-delay: 250ms
}

.ui-rollup--keep-visible-2>*:nth-child(9) {
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms
}

.ui-rollup--keep-visible-2>*:nth-child(10) {
  -webkit-animation-delay: 350ms;
  animation-delay: 350ms
}

.ui-rollup--keep-visible-3>*:nth-child(1) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: none;
  animation: none;
  opacity: 1
}

.ui-rollup--keep-visible-3>*:nth-child(2) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: none;
  animation: none;
  opacity: 1
}

.ui-rollup--keep-visible-3>*:nth-child(3) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: none;
  animation: none;
  opacity: 1
}

.ui-rollup--keep-visible-3>*:nth-child(4) {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms
}

.ui-rollup--keep-visible-3>*:nth-child(5) {
  -webkit-animation-delay: 50ms;
  animation-delay: 50ms
}

.ui-rollup--keep-visible-3>*:nth-child(6) {
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms
}

.ui-rollup--keep-visible-3>*:nth-child(7) {
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms
}

.ui-rollup--keep-visible-3>*:nth-child(8) {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms
}

.ui-rollup--keep-visible-3>*:nth-child(9) {
  -webkit-animation-delay: 250ms;
  animation-delay: 250ms
}

.ui-rollup--keep-visible-3>*:nth-child(10) {
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms
}

.ui-rollup--keep-visible-4>*:nth-child(1) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: none;
  animation: none;
  opacity: 1
}

.ui-rollup--keep-visible-4>*:nth-child(2) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: none;
  animation: none;
  opacity: 1
}

.ui-rollup--keep-visible-4>*:nth-child(3) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: none;
  animation: none;
  opacity: 1
}

.ui-rollup--keep-visible-4>*:nth-child(4) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: none;
  animation: none;
  opacity: 1
}

.ui-rollup--keep-visible-4>*:nth-child(5) {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms
}

.ui-rollup--keep-visible-4>*:nth-child(6) {
  -webkit-animation-delay: 50ms;
  animation-delay: 50ms
}

.ui-rollup--keep-visible-4>*:nth-child(7) {
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms
}

.ui-rollup--keep-visible-4>*:nth-child(8) {
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms
}

.ui-rollup--keep-visible-4>*:nth-child(9) {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms
}

.ui-rollup--keep-visible-4>*:nth-child(10) {
  -webkit-animation-delay: 250ms;
  animation-delay: 250ms
}

.ui-rollup--show>* {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: rollup-fadein 200ms linear forwards;
  animation: rollup-fadein 200ms linear forwards
}

[data-rollup-force-show-child-instance='true']>.ui-rollup>*,
.ui-rollup__item--force-show {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: none;
  animation: none;
  opacity: 1
}

@-webkit-keyframes rollup-fadein {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes rollup-fadein {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.ui-scrollable {
  position: relative;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  -webkit-transition: -webkit-box-shadow 260ms ease;
  transition: -webkit-box-shadow 260ms ease;
  transition: box-shadow 260ms ease;
  transition: box-shadow 260ms ease, -webkit-box-shadow 260ms ease
}

.ui-scrollable__container {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 100%;
  max-width: 100%
}

.ui-scrollable__scroll-content {
  position: relative;
  z-index: 0;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  overflow: scroll;
  width: 100%;
  margin: 0 -15px -15px 0;
  padding: 0 15px 15px 0;
  max-width: 100%
}

.ui-scrollable__content {
  position: absolute;
  right: 0;
  left: 0;
  height: 100%;
  margin-bottom: -15px
}

.ui-scrollable__track {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  width: 11px
}

.ui-scrollable__scrollbar {
  position: absolute;
  right: 2px;
  width: 7px;
  background: #6c6e71;
  background-clip: padding-box;
  border-radius: 7px;
  -webkit-transition: opacity 200ms linear;
  transition: opacity 200ms linear;
  min-height: 10px;
  opacity: 0
}

.ui-scrollable__track:hover .ui-scrollable__scrollbar {
  -webkit-transition: opacity 0 linear;
  transition: opacity 0 linear;
  opacity: 0.7
}

.ui-scrollable__scrollbar--visible {
  opacity: 0.7
}

.ui-skeleton-body-text__container {
  -webkit-animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  will-change: opacity
}

@media (prefers-reduced-motion) {
  .ui-skeleton-body-text__container {
    -webkit-animation: none;
    animation: none
  }
}

.ui-skeleton-body-text {
  height: 0.5rem;
  position: relative
}

.ui-skeleton-body-text::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: #dfe3e8;
  border-radius: var(--p-border-radius-base)
}

.ui-skeleton-body-text:last-child:not(:first-child) {
  width: 80%
}

.ui-skeleton-body-text+.ui-skeleton-body-text {
  margin-top: 0.75rem
}

.ui-spinner {
  -webkit-transform-origin: 1.375rem 1.375rem 1.375rem;
  transform-origin: 1.375rem 1.375rem 1.375rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: 2.75rem;
  width: 2.75rem;
  -webkit-animation: ui-spinner-rotate 0.5s linear infinite;
  animation: ui-spinner-rotate 0.5s linear infinite;
  background-color: transparent;
  border: 0.1875rem solid var(--p-border-highlight);
  border-left-color: transparent;
  border-radius: 50%
}

.ui-spinner--size-small {
  -webkit-transform-origin: 0.625rem 0.625rem 0.625rem;
  transform-origin: 0.625rem 0.625rem 0.625rem;
  height: 1.25rem;
  width: 1.25rem
}

.ui-spinner--size-large {
  -webkit-transform-origin: 2.5rem 2.5rem 2.5rem;
  transform-origin: 2.5rem 2.5rem 2.5rem;
  height: 5rem;
  width: 5rem
}

.ui-spinner--color-white {
  border-color: var(--p-border-highlight);
  border-left-color: transparent
}

.ui-spinner--color-ink-lightest {
  border-color: var(--p-border-highlight);
  border-left-color: transparent
}

.ui-spinner--color-highlight {
  border-color: var(--p-border-highlight);
  border-left-color: transparent
}

@-webkit-keyframes ui-spinner-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes ui-spinner-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.ui-stack {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -1rem;
  margin-left: -1rem
}

.ui-stack>* {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-top: 1rem;
  margin-left: 1rem
}

.ui-stack>.ui-stack {
  margin-top: 0;
  margin-left: 0
}

.ui-stack>.ui-stack--spacing-tight {
  margin-top: 0.5rem;
  margin-left: 0.5rem
}

.ui-stack>.ui-stack--spacing-loose {
  margin-top: -0.25rem;
  margin-left: -0.25rem
}

.ui-stack--spacing-none {
  margin-top: 0;
  margin-left: 0
}

.ui-stack--spacing-none>* {
  margin-top: 0;
  margin-left: 0
}

.ui-stack--spacing-none>.ui-stack {
  margin-top: -1rem;
  margin-left: -1rem
}

.ui-stack--spacing-none .ui-stack--spacing-tight {
  margin-top: -0.5rem;
  margin-left: -0.5rem
}

.ui-stack--spacing-none .ui-stack--spacing-loose {
  margin-top: -1.25rem;
  margin-left: -1.25rem
}

.ui-stack--spacing-tight {
  margin-top: -0.5rem;
  margin-left: -0.5rem
}

.ui-stack--spacing-tight>* {
  margin-top: 0.5rem;
  margin-left: 0.5rem
}

.ui-stack--spacing-tight>.ui-stack {
  margin-top: -0.5rem;
  margin-left: -0.5rem
}

.ui-stack--spacing-tight>.ui-stack--spacing-tight {
  margin-top: 0;
  margin-left: 0
}

.ui-stack--spacing-tight>.ui-stack--spacing-loose {
  margin-top: -0.75rem;
  margin-left: -0.75rem
}

.ui-stack--spacing-loose {
  margin-top: -1.25rem;
  margin-left: -1.25rem
}

.ui-stack--spacing-loose>* {
  margin-top: 1.25rem;
  margin-left: 1.25rem
}

.ui-stack--spacing-loose>.ui-stack {
  margin-top: 0.25rem;
  margin-left: 0.25rem
}

.ui-stack--spacing-loose>.ui-stack--spacing-tight {
  margin-top: 0.75rem;
  margin-left: 0.75rem
}

.ui-stack--spacing-loose>.ui-stack--spacing-loose {
  margin-top: 0;
  margin-left: 0
}

.ui-stack--wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.ui-stack--vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column
}

.ui-stack--distribution-leading {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.ui-stack--distribution-trailing {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.ui-stack--distribution-equal-spacing {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.ui-stack--distribution-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center
}

.ui-stack--distribution-fill>* {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

@supports (min-width: -webkit-max-content) or (min-width: -moz-max-content) or (min-width: max-content) {
  .ui-stack--distribution-fill-equally:not(.ui-stack--vertical)>* {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 0%;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content
  }
}

@supports (min-height: -webkit-max-content) or (min-height: -moz-max-content) or (min-height: max-content) {
  .ui-stack--distribution-fill-equally.ui-stack--vertical>* {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 0%;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content
  }
}

.ui-stack--distribution-fill-equally>* {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.ui-stack--distribution-fill-equally>* ::-webkit-:not(:root:root),
.ui-stack--distribution-fill-equally>* {
  -webkit-box-flex: 1 !important;
  -webkit-flex: 1 1 auto !important;
  flex: 1 1 auto !important
}

.ui-stack--alignment-leading {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start
}

.ui-stack--alignment-trailing {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end
}

.ui-stack--alignment-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.ui-stack--alignment-fill {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

.ui-stack--alignment-baseline {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline
}

.ui-stack-item {
  min-width: 0;
  max-width: 100%
}

.ui-stack-item--fill {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.ui-subheading {
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1rem;
  text-transform: uppercase
}

@media screen and (min-width: 640px) {
  .ui-subheading {
    font-size: 0.75rem
  }
}

.ui-subheading--subdued {
  color: var(--p-text-subdued)
}

.ui-tags {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none
}

.ui-tag {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  overflow-wrap: break-word;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  background-color: var(--p-surface-neutral, #dfe3e8);
  border-radius: var(--p-border-radius-base, var(--p-border-radius-base));
  color: var(--p-text, #454f5b);
  max-width: 100%;
  min-height: 1.75rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.ui-tag__label {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: initial;
  letter-spacing: initial;
  display: block;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  min-width: 0;
  width: 100%;
  padding: 0.25rem 0;
  padding-left: 0.5rem
}

@media screen and (min-width: 640px) {
  .ui-tag__label {
    font-size: 0.875rem
  }
}

.ui-tag__remove-button {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: auto;
  margin: 0;
  background: inherit;
  border: 0;
  border-radius: inherit;
  font-family: inherit;
  font-weight: 400;
  cursor: pointer;
  text-transform: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0;
  outline: none;
  vertical-align: middle;
  margin: 0 0.25rem 0 0.5rem;
  position: relative
}

.ui-tag__remove-button::-moz-focus-inner {
  padding: 0;
  border: 0
}

.ui-tag__remove-button:hover,
.ui-tag__remove-button:focus,
.ui-tag__remove-button:active {
  text-decoration: none
}

.ui-tag__remove-button.btn-disabled,
.ui-tag__remove-button.disabled,
.ui-tag__remove-button:disabled,
.ui-tag__remove-button[disabled] {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  pointer-events: none
}

.ui-tag__remove-button:focus {
  outline: none
}

.ui-tag__remove-button svg {
  fill: var(--p-icon)
}

.ui-tag__remove-button:hover {
  background: var(--p-surface-neutral-hovered)
}

.ui-tag__remove-button::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(0px + 0.0625rem));
  right: calc(-1 * calc(0px + 0.0625rem));
  bottom: calc(-1 * calc(0px + 0.0625rem));
  left: calc(-1 * calc(0px + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.0625rem)
}

.ui-tag__remove-button:focus:not(:active)::after {
  -webkit-box-shadow: 0 0 0 0.125rem var(--p-focused);
  box-shadow: 0 0 0 0.125rem var(--p-focused)
}

.ui-tag__remove-button:active {
  background: var(--p-surface-neutral-pressed)
}

.ui-tag__remove-button:disabled {
  cursor: default;
  pointer-events: none
}

.ui-tag__remove-button:disabled svg {
  fill: var(--p-icon-disabled)
}

.ui-tag__remove-button svg {
  top: 0
}

.ui-text-style--variation-subdued {
  color: var(--p-text-subdued)
}

.ui-text-style--variation-strong {
  font-weight: 600
}

.ui-text-style--variation-positive {
  color: #108043
}

.ui-text-style--variation-negative {
  color: #bf0711
}

.ui-title {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem
}

.ui-type-container>*:not(.hide)+* {
  margin-top: 1rem
}

.ui-type-container--spacing-extra-tight>*:not(.hide)+* {
  margin-top: 0.25rem
}

.ui-type-container--spacing-tight>*:not(.hide)+* {
  margin-top: 0.5rem
}

.ui-type-container--spacing-loose>*:not(.hide)+* {
  margin-top: 1.25rem
}

.ui-top-bar {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 3.5rem;
  width: 100vw;
  -webkit-box-shadow: var(--p-top-bar-shadow);
  box-shadow: var(--p-top-bar-shadow);
  -webkit-transition: -webkit-box-shadow 0.1s var(--p-ease);
  transition: -webkit-box-shadow 0.1s var(--p-ease);
  transition: box-shadow 0.1s var(--p-ease);
  transition: box-shadow 0.1s var(--p-ease), -webkit-box-shadow 0.1s var(--p-ease);
  padding-right: 0px;
  padding-right: calc(0px + constant(safe-area-inset-right));
  padding-right: calc(0px + env(safe-area-inset-right));
  background: var(--p-surface)
}

.ui-top-bar--shadow-visible {
  -webkit-box-shadow: var(--p-top-bar-shadow);
  box-shadow: var(--p-top-bar-shadow)
}

.ui-top-bar__branding {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 15rem;
  min-width: 15rem;
  padding-right: 0.5rem;
  padding-left: 1rem;
  padding-left: calc(1rem + constant(safe-area-inset-left));
  padding-left: calc(1rem + env(safe-area-inset-left));
  background-color: var(--p-surface)
}

.ui-top-bar__branding svg,
.ui-top-bar__branding img {
  display: block;
  height: auto;
  width: 6.5rem;
  max-height: 2rem
}

@media screen and (max-width: 768px) {
  .ui-top-bar__branding {
    display: none
  }
}

.ui-top-bar__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.ui-top-bar__item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 1rem;
  color: var(--p-text);
  text-decoration: none
}

@media screen and (max-width: 768px) {
  .ui-top-bar__item {
    padding-left: 0.5rem
  }
}

.ui-top-bar--dark-text .ui-top-bar__item {
  color: var(--p-text)
}

@media screen and (min-width: 769px) {
  .ui-top-bar__item--show-for-mobile {
    display: none
  }
}

@media screen and (max-width: 768px) {
  .ui-top-bar__item--show-for-desktop {
    display: none
  }
}

.ui-app-frame__aside-opener .next-icon,
.ui-app-frame__aside-opener .next-icon__text {
  fill: var(--p-icon)
}

.ui-top-bar__item--fill {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.ui-top-bar__link {
  color: var(--p-interactive)
}

.ui-top-bar--dark-text .ui-top-bar__link {
  color: var(--p-interactive)
}

.ui-top-bar__link:hover {
  color: var(--p-interactive-hovered);
  text-decoration: none
}

.ui-top-bar--dark-text .ui-top-bar__link:hover {
  color: var(--p-interactive-hovered)
}

.ui-password {
  width: 100%;
  padding: 0;
  background: var(--p-surface, white);
  border: 1px solid var(--p-border);
  -webkit-box-shadow: var(--p-override-none, inset 0 1px 0 0 rgba(63, 63, 68, 0.05));
  box-shadow: var(--p-override-none, inset 0 1px 0 0 rgba(63, 63, 68, 0.05));
  border-radius: var(--p-border-radius-base)
}

.ui-password .ui-password__input {
  padding: 0.25rem 0.5rem;
  background: none;
  border: 1px solid transparent;
  -webkit-box-shadow: none;
  box-shadow: none
}

.ui-password .ui-password__input:read-only,
.ui-password .ui-password__input:-webkit-autofill {
  left: 0
}

.ui-password .ui-password__button {
  padding: 0.25rem 0.5rem;
  border: 1px solid transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: var(--p-action-interactive, #006fbb)
}

.ui-password .ui-password__button:hover {
  border: 1px solid transparent
}

.ui-password .ui-password__button:disabled {
  color: var(--p-text-disabled, var(--p-action-interactive, #006fbb));
  background: var(--p-action-secondary-disabled, #f4f6f8)
}

.ui-password .ui-password__input,
.ui-password .ui-password__button {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%
}

.ui-password.ui-password--readonly {
  background: var(--p-surface-subdued, #f9fafb)
}

.ui-password.ui-password--disabled {
  background: var(--p-action-secondary-disabled, #f4f6f8);
  border-color: var(--p-border-subdued, #c4cdd5);
  color: var(--p-text-disabled, #919eab)
}

.ui-password.ui-password--disabled .ui-password__input[disabled] {
  background: none;
  border: 0
}

.next-input-wrapper--is-error .ui-password {
  border: 0
}

.next-input-wrapper--is-error .ui-password__button:focus,
.next-input-wrapper--is-error .ui-password__button:hover:focus {
  border: 1px solid var(--p-border, #5c6ac4);
  -webkit-box-shadow: var(--p-override-none, 0 0 0 1px #5c6ac4);
  box-shadow: var(--p-override-none, 0 0 0 1px #5c6ac4)
}

.ui-setting-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -1rem;
  margin-left: -1rem
}

.ui-setting-toggle__setting,
.ui-setting-toggle__toggle {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-top: 1rem;
  margin-left: 1rem;
  max-width: calc(100% - 1rem);
  min-width: 0
}

.ui-setting-toggle__setting {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 21.875rem;
  -ms-flex: 1 0 21.875rem;
  flex: 1 0 21.875rem
}

.ui-banner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: initial;
  letter-spacing: initial;
  border-radius: var(--p-border-radius-wide);
  border-width: 1px;
  border-style: solid;
  position: relative
}

@media screen and (min-width: 640px) {
  .ui-banner {
    font-size: 0.875rem
  }
}

.ui-banner::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(0.0625rem + 0.0625rem));
  right: calc(-1 * calc(0.0625rem + 0.0625rem));
  bottom: calc(-1 * calc(0.0625rem + 0.0625rem));
  left: calc(-1 * calc(0.0625rem + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(0.0625rem + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(0.0625rem + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-wide) + 0.0625rem)
}

.ui-banner:focus::after {
  -webkit-box-shadow: 0 0 0 0.125rem var(--p-focused);
  box-shadow: 0 0 0 0.125rem var(--p-focused)
}

.ui-banner a:not(.ui-button) {
  color: var(--p-text);
  text-decoration: underline
}

.ui-banner a:not(.ui-button):hover,
.ui-banner a:not(.ui-button):focus {
  color: var(--p-text)
}

.ui-banner .ui-button--link {
  -webkit-text-decoration-color: var(--p-border-subdued);
  text-decoration-color: var(--p-border-subdued);
  color: var(--p-text)
}

.ui-banner .ui-button--link:hover,
.ui-banner .ui-button--link:active,
.ui-banner .ui-button--link:focus {
  text-decoration: none
}

.ui-banner--within-content-container {
  padding: 1rem 1rem 0.875rem;
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
  border-color: var(--p-border-neutral-subdued, transparent);
  background-color: var(--p-surface-neutral-subdued, #f4f6f8)
}

.ui-banner--within-content-container .ui-banner__content {
  margin-top: -0.125rem
}

.ui-banner--within-content-container:focus {
  outline: none
}

.ui-banner--within-content-container .ui-banner__ribbon::before {
  background-color: var(--p-override-transparent, #dfe3e8)
}

.ui-banner--within-content-container .ui-banner__ribbon>.next-icon,
.ui-banner--within-content-container .ui-banner__ribbon>.next-icon__text {
  fill: var(--p-icon, #637381);
  color: white
}

.ui-banner--within-content-container .ui-button--outline {
  border-color: var(--p-border-neutral-subdued);
  background: transparent
}

.ui-banner--within-content-container .ui-button--outline:hover {
  background: var(--p-surface-hovered)
}

.ui-banner--within-content-container .ui-button--outline:active {
  background: var(--p-surface-pressed)
}

.ui-banner--within-content-container .ui-button--outline:focus:not(:active) {
  border-color: var(--p-border-neutral-subdued);
  background: transparent
}

.ui-banner--within-content-container.ui-banner--status-success {
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
  border-color: var(--p-border-success-subdued, transparent);
  background-color: var(--p-surface-success-subdued, #eff7ed)
}

.ui-banner--within-content-container.ui-banner--status-success:focus {
  outline: none
}

.ui-banner--within-content-container.ui-banner--status-success .ui-banner__ribbon::before {
  background-color: var(--p-override-transparent, #bbe5b3)
}

.ui-banner--within-content-container.ui-banner--status-success .ui-banner__ribbon>.next-icon,
.ui-banner--within-content-container.ui-banner--status-success .ui-banner__ribbon>.next-icon__text {
  fill: var(--p-icon-success, #108043);
  color: white
}

.ui-banner--within-content-container.ui-banner--status-success .ui-button--outline {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued)
}

.ui-banner--within-content-container.ui-banner--status-success .ui-button--outline:hover {
  background: var(--p-surface-success-subdued-hovered)
}

.ui-banner--within-content-container.ui-banner--status-success .ui-button--outline:active {
  background: var(--p-surface-success-subdued-pressed)
}

.ui-banner--within-content-container.ui-banner--status-success .ui-button--outline:focus:not(:active) {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued)
}

.ui-banner--within-content-container.ui-banner--status-info {
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
  border-color: var(--p-border-highlight-subdued, transparent);
  background-color: var(--p-surface-highlight-subdued, #eef9f9)
}

.ui-banner--within-content-container.ui-banner--status-info:focus {
  outline: none
}

.ui-banner--within-content-container.ui-banner--status-info .ui-banner__ribbon::before {
  background-color: var(--p-override-transparent, #b7ecec)
}

.ui-banner--within-content-container.ui-banner--status-info .ui-banner__ribbon>.next-icon,
.ui-banner--within-content-container.ui-banner--status-info .ui-banner__ribbon>.next-icon__text {
  fill: var(--p-icon-highlight, #00848e);
  color: white
}

.ui-banner--within-content-container.ui-banner--status-info .ui-button--outline {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued)
}

.ui-banner--within-content-container.ui-banner--status-info .ui-button--outline:hover {
  background: var(--p-surface-highlight-subdued-hovered)
}

.ui-banner--within-content-container.ui-banner--status-info .ui-button--outline:active {
  background: var(--p-surface-highlight-subdued-pressed)
}

.ui-banner--within-content-container.ui-banner--status-info .ui-button--outline:focus:not(:active) {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued)
}

.ui-banner--within-content-container.ui-banner--status-warning {
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
  border-color: var(--p-border-warning-subdued, transparent);
  background-color: var(--p-surface-warning-subdued, #fdf7e3)
}

.ui-banner--within-content-container.ui-banner--status-warning:focus {
  outline: none
}

.ui-banner--within-content-container.ui-banner--status-warning .ui-banner__ribbon::before {
  background-color: var(--p-override-transparent, #ffea8a)
}

.ui-banner--within-content-container.ui-banner--status-warning .ui-banner__ribbon>.next-icon,
.ui-banner--within-content-container.ui-banner--status-warning .ui-banner__ribbon>.next-icon__text {
  fill: var(--p-icon-warning, #8a6116);
  color: white
}

.ui-banner--within-content-container.ui-banner--status-warning .ui-button--outline {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued)
}

.ui-banner--within-content-container.ui-banner--status-warning .ui-button--outline:hover {
  background: var(--p-surface-warning-subdued-hovered)
}

.ui-banner--within-content-container.ui-banner--status-warning .ui-button--outline:active {
  background: var(--p-surface-warning-subdued-pressed)
}

.ui-banner--within-content-container.ui-banner--status-warning .ui-button--outline:focus:not(:active) {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued)
}

.ui-banner--within-content-container.ui-banner--status-critical {
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
  border-color: var(--p-border-critical-subdued, transparent);
  background-color: var(--p-surface-critical-subdued, #fdf3f0)
}

.ui-banner--within-content-container.ui-banner--status-critical:focus {
  outline: none
}

.ui-banner--within-content-container.ui-banner--status-critical .ui-banner__ribbon::before {
  background-color: var(--p-override-transparent, #fead9a)
}

.ui-banner--within-content-container.ui-banner--status-critical .ui-banner__ribbon>.next-icon,
.ui-banner--within-content-container.ui-banner--status-critical .ui-banner__ribbon>.next-icon__text {
  fill: var(--p-icon-critical, #bf0711);
  color: white
}

.ui-banner--within-content-container.ui-banner--status-critical .ui-button--outline {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued)
}

.ui-banner--within-content-container.ui-banner--status-critical .ui-button--outline:hover {
  background: var(--p-surface-critical-subdued-hovered)
}

.ui-banner--within-content-container.ui-banner--status-critical .ui-button--outline:active {
  background: var(--p-surface-critical-subdued-pressed)
}

.ui-banner--within-content-container.ui-banner--status-critical .ui-button--outline:focus:not(:active) {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued)
}

.ui-banner--within-content-container:not(.hide)+.ui-banner {
  margin-top: 0.5rem
}

.ui-banner--within-content-container .ui-banner__ribbon {
  padding-right: 0.75rem
}

.ui-banner--within-content-container .ui-banner__content-button {
  padding: 0.75rem 0 0.25rem 0
}

.ui-banner--within-content-container .ui-banner__dismissible-button {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem
}

.ui-banner--within-page {
  padding: 1.25rem 1.25rem 1.125rem;
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
  border-color: var(--p-border-neutral-subdued, transparent);
  background-color: var(--p-surface-neutral-subdued, #f4f6f8)
}

.ui-banner--within-page .ui-banner__content {
  margin-top: -0.125rem
}

.ui-banner--within-page:focus {
  outline: none
}

.ui-banner--within-page .ui-banner__ribbon::before {
  background-color: var(--p-override-transparent, #dfe3e8)
}

.ui-banner--within-page .ui-banner__ribbon>.next-icon,
.ui-banner--within-page .ui-banner__ribbon>.next-icon__text {
  fill: var(--p-icon, #637381);
  color: white
}

.ui-banner--within-page .ui-button--outline {
  border-color: var(--p-border-neutral-subdued);
  background: transparent
}

.ui-banner--within-page .ui-button--outline:hover {
  background: var(--p-surface-hovered)
}

.ui-banner--within-page .ui-button--outline:active {
  background: var(--p-surface-pressed)
}

.ui-banner--within-page .ui-button--outline:focus:not(:active) {
  border-color: var(--p-border-neutral-subdued);
  background: transparent
}

.ui-banner--within-page.ui-banner--status-success {
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
  border-color: var(--p-border-success-subdued, transparent);
  background-color: var(--p-surface-success-subdued, #eff7ed)
}

.ui-banner--within-page.ui-banner--status-success:focus {
  outline: none
}

.ui-banner--within-page.ui-banner--status-success .ui-banner__ribbon::before {
  background-color: var(--p-override-transparent, #bbe5b3)
}

.ui-banner--within-page.ui-banner--status-success .ui-banner__ribbon>.next-icon,
.ui-banner--within-page.ui-banner--status-success .ui-banner__ribbon>.next-icon__text {
  fill: var(--p-icon-success, #108043);
  color: white
}

.ui-banner--within-page.ui-banner--status-success .ui-button--outline {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued)
}

.ui-banner--within-page.ui-banner--status-success .ui-button--outline:hover {
  background: var(--p-surface-success-subdued-hovered)
}

.ui-banner--within-page.ui-banner--status-success .ui-button--outline:active {
  background: var(--p-surface-success-subdued-pressed)
}

.ui-banner--within-page.ui-banner--status-success .ui-button--outline:focus:not(:active) {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued)
}

.ui-banner--within-page.ui-banner--status-info {
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
  border-color: var(--p-border-highlight-subdued, transparent);
  background-color: var(--p-surface-highlight-subdued, #eef9f9)
}

.ui-banner--within-page.ui-banner--status-info:focus {
  outline: none
}

.ui-banner--within-page.ui-banner--status-info .ui-banner__ribbon::before {
  background-color: var(--p-override-transparent, #b7ecec)
}

.ui-banner--within-page.ui-banner--status-info .ui-banner__ribbon>.next-icon,
.ui-banner--within-page.ui-banner--status-info .ui-banner__ribbon>.next-icon__text {
  fill: var(--p-icon-highlight, #00848e);
  color: white
}

.ui-banner--within-page.ui-banner--status-info .ui-button--outline {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued)
}

.ui-banner--within-page.ui-banner--status-info .ui-button--outline:hover {
  background: var(--p-surface-highlight-subdued-hovered)
}

.ui-banner--within-page.ui-banner--status-info .ui-button--outline:active {
  background: var(--p-surface-highlight-subdued-pressed)
}

.ui-banner--within-page.ui-banner--status-info .ui-button--outline:focus:not(:active) {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued)
}

.ui-banner--within-page.ui-banner--status-warning {
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
  border-color: var(--p-border-warning-subdued, transparent);
  background-color: var(--p-surface-warning-subdued, #fdf7e3)
}

.ui-banner--within-page.ui-banner--status-warning:focus {
  outline: none
}

.ui-banner--within-page.ui-banner--status-warning .ui-banner__ribbon::before {
  background-color: var(--p-override-transparent, #ffea8a)
}

.ui-banner--within-page.ui-banner--status-warning .ui-banner__ribbon>.next-icon,
.ui-banner--within-page.ui-banner--status-warning .ui-banner__ribbon>.next-icon__text {
  fill: var(--p-icon-warning, #8a6116);
  color: white
}

.ui-banner--within-page.ui-banner--status-warning .ui-button--outline {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued)
}

.ui-banner--within-page.ui-banner--status-warning .ui-button--outline:hover {
  background: var(--p-surface-warning-subdued-hovered)
}

.ui-banner--within-page.ui-banner--status-warning .ui-button--outline:active {
  background: var(--p-surface-warning-subdued-pressed)
}

.ui-banner--within-page.ui-banner--status-warning .ui-button--outline:focus:not(:active) {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued)
}

.ui-banner--within-page.ui-banner--status-critical {
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
  border-color: var(--p-border-critical-subdued, transparent);
  background-color: var(--p-surface-critical-subdued, #fdf3f0)
}

.ui-banner--within-page.ui-banner--status-critical:focus {
  outline: none
}

.ui-banner--within-page.ui-banner--status-critical .ui-banner__ribbon::before {
  background-color: var(--p-override-transparent, #fead9a)
}

.ui-banner--within-page.ui-banner--status-critical .ui-banner__ribbon>.next-icon,
.ui-banner--within-page.ui-banner--status-critical .ui-banner__ribbon>.next-icon__text {
  fill: var(--p-icon-critical, #bf0711);
  color: white
}

.ui-banner--within-page.ui-banner--status-critical .ui-button--outline {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued)
}

.ui-banner--within-page.ui-banner--status-critical .ui-button--outline:hover {
  background: var(--p-surface-critical-subdued-hovered)
}

.ui-banner--within-page.ui-banner--status-critical .ui-button--outline:active {
  background: var(--p-surface-critical-subdued-pressed)
}

.ui-banner--within-page.ui-banner--status-critical .ui-button--outline:focus:not(:active) {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued)
}

.ui-banner--within-page:not(.hide)+.ui-banner {
  margin-top: 1.25rem
}

.ui-banner--within-page .ui-banner__ribbon {
  padding-right: 1rem
}

.ui-banner--within-page .ui-banner__content-button {
  padding-top: 1rem
}

.ui-banner--within-page .ui-banner__dismissible-button {
  position: absolute;
  top: 0.75rem;
  right: 0.5rem
}

.ui-banner--hidden {
  display: none
}

.ui-banner--dismissible {
  padding-right: 3.5rem
}

.ui-banner__heading {
  padding-top: 0.125rem
}

.ui-banner__content {
  padding: 0.25rem 0;
  word-break: break-word;
  overflow-wrap: break-word
}

.ui-banner__ribbon {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 32px;
  -ms-flex: 0 0 32px;
  flex: 0 0 32px;
  padding-right: 1rem
}

.ui-banner__ribbon>.next-icon,
.ui-banner__ribbon>.next-icon__text {
  top: 0.1875rem;
  left: 0.25rem
}

.ui-banner__ribbon::before {
  content: '';
  position: absolute;
  top: 0;
  right: -0.25rem;
  bottom: -0.25rem;
  left: 0;
  display: block;
  height: 28px;
  width: 28px;
  border-radius: 50%
}

@media screen and (max-width: 720px) {
  .ui-layout .ui-banner--within-page {
    border-radius: 0;
    border-left-width: 0;
    border-right-width: 0
  }
}

.ui-banner__section:not(:first-of-type) {
  padding-top: 1rem
}

.ui-banner__section:not(:last-of-type) {
  padding-bottom: 1rem
}

.ui-banner__section+.ui-banner__section {
  border-top: 1px solid var(--p-border)
}

.ui-banner__dismissible-button {
  padding: 0.6875rem 0.5rem;
  line-height: 1
}

.ui-banner__dismissible-button>.next-icon,
.ui-banner__dismissible-button>.next-icon__text {
  fill: var(--p-icon, #637381)
}

.ui-banner__dismissible-button:hover>.next-icon,
.ui-banner__dismissible-button:hover>.next-icon__text,
.ui-banner__dismissible-button:focus>.next-icon,
.ui-banner__dismissible-button:focus>.next-icon__text,
.ui-banner__dismissible-button:active>.next-icon,
.ui-banner__dismissible-button:active>.next-icon__text {
  fill: var(--p-icon-hovered, #212b36)
}

.empty-search-results {
  text-align: center;
  padding: 1.25rem 0
}

.empty-search-results .empty-search-results__illustration {
  margin-bottom: 30px
}

.empty-search-results .empty-search-results__title {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif
}

.empty-search-results .empty-search-results__message {
  color: var(--p-text-subdued);
  margin: 0 0 0.5rem
}

.icon-symbols {
  display: none !important
}

.next-icon,
.next-icon__text {
  display: inline-block;
  vertical-align: middle;
  fill: currentColor
}

.next-icon--left-spacing-halved {
  margin-left: 10px
}

.next-icon--right-spacing-halved {
  margin-right: 10px
}

.next-icon--left-spacing-quartered {
  margin-left: 5px
}

.next-icon--right-spacing-quartered {
  margin-right: 5px
}

.next-icon--color-white {
  color: transparent;
  fill: var(--p-icon-subdued, #fff)
}

.next-icon--color-ink {
  color: transparent;
  fill: var(--p-icon, #212b36)
}

.next-icon--color-ink-light {
  color: transparent;
  fill: var(--p-icon, #454f5b)
}

.next-icon--color-ink-lighter {
  color: transparent;
  fill: var(--p-icon, #637381)
}

.next-icon--color-ink-lightest {
  color: transparent;
  fill: var(--p-icon, #919eab)
}

.next-icon--color-sky {
  color: transparent;
  fill: var(--p-icon-subdued, #ebeef0)
}

.next-icon--color-sky-light {
  color: transparent;
  fill: var(--p-icon-subdued, #f5f6f7)
}

.next-icon--color-sky-dark {
  color: transparent;
  fill: var(--p-icon-subdued, #d3dbe2)
}

.next-icon--color-sky-darker {
  color: transparent;
  fill: var(--p-icon-subdued, #c3cfd8)
}

.next-icon--color-slate-lightest {
  color: transparent;
  fill: var(--p-icon, #95a7b7)
}

.next-icon--color-slate-lighter {
  color: transparent;
  fill: var(--p-icon, #798c9c)
}

.next-icon--color-slate-light {
  color: transparent;
  fill: var(--p-icon, #454e57)
}

.next-icon--color-slate {
  color: transparent;
  fill: var(--p-icon, #31373d)
}

.next-icon--color-slate-darker {
  color: transparent;
  fill: var(--p-icon, #212529)
}

.next-icon--color-blue-lighter {
  color: transparent;
  fill: var(--p-icon, #ebf5fa)
}

.next-icon--color-blue-light {
  color: transparent;
  fill: var(--p-icon, #b4e1fa)
}

.next-icon--color-blue {
  color: transparent;
  fill: var(--p-icon, #006fbb)
}

.next-icon--color-green {
  color: transparent;
  fill: var(--p-icon-success, #96bf48)
}

.next-icon--color-green-dark {
  color: transparent;
  fill: var(--p-icon-success, #7ba232)
}

.next-icon--color-yellow {
  color: transparent;
  fill: var(--p-icon-warning, #ffd117)
}

.next-icon--color-yellow-dark {
  color: transparent;
  fill: var(--p-icon-warning, #d4a002)
}

.next-icon--color-orange-light {
  color: transparent;
  fill: var(--p-icon-warning, #fec97d)
}

.next-icon--color-orange {
  color: transparent;
  fill: var(--p-icon-warning, #ff9517)
}

.next-icon--color-red {
  color: transparent;
  fill: var(--p-icon-critical, #ff5d5d)
}

.next-icon--color-red-dark {
  color: transparent;
  fill: var(--p-icon-critical, #d83e3e)
}

.next-icon--color-teal {
  color: transparent;
  fill: var(--p-icon-highlight, #47c1bf)
}

.fresh-ui .next-icon--color-white {
  color: transparent;
  fill: var(--p-icon-subdued, white)
}

.fresh-ui .next-icon--color-sky {
  color: transparent;
  fill: var(--p-icon-subdued, #dfe3e8)
}

.fresh-ui .next-icon--color-sky-light {
  color: transparent;
  fill: var(--p-icon-subdued, #f4f6f8)
}

.fresh-ui .next-icon--color-sky-dark {
  color: transparent;
  fill: var(--p-icon-subdued, #c4cdd5)
}

.fresh-ui .next-icon--color-blue-lighter {
  color: transparent;
  fill: var(--p-icon, #ebf5fa)
}

.fresh-ui .next-icon--color-blue-light {
  color: transparent;
  fill: var(--p-icon, #b4e1fa)
}

.fresh-ui .next-icon--color-blue {
  color: transparent;
  fill: var(--p-icon, #006fbb)
}

.fresh-ui .next-icon--color-green {
  color: transparent;
  fill: var(--p-icon-success, #50b83c)
}

.fresh-ui .next-icon--color-green-dark {
  color: transparent;
  fill: var(--p-icon-success, #108043)
}

.fresh-ui .next-icon--color-yellow {
  color: transparent;
  fill: var(--p-icon-warning, #eec200)
}

.fresh-ui .next-icon--color-yellow-dark {
  color: transparent;
  fill: var(--p-icon-warning, #8a6116)
}

.fresh-ui .next-icon--color-orange-light {
  color: transparent;
  fill: var(--p-icon-warning, #ffc58b)
}

.fresh-ui .next-icon--color-orange {
  color: transparent;
  fill: var(--p-icon-warning, #f49342)
}

.fresh-ui .next-icon--color-red {
  color: transparent;
  fill: var(--p-icon-critical, #de3618)
}

.fresh-ui .next-icon--color-red-dark {
  color: transparent;
  fill: var(--p-icon-critical, #bf0711)
}

.fresh-ui .next-icon--color-ink {
  color: transparent;
  fill: var(--p-icon, #212b36)
}

.fresh-ui .next-icon--color-ink-light {
  color: transparent;
  fill: var(--p-icon, #454f5b)
}

.fresh-ui .next-icon--color-ink-lighter {
  color: transparent;
  fill: var(--p-icon, #637381)
}

.fresh-ui .next-icon--color-ink-lightest {
  color: transparent;
  fill: var(--p-icon, #919eab)
}

.fresh-ui .next-icon--color-teal {
  color: transparent;
  fill: var(--p-icon-highlight, #47c1bf)
}

.fresh-ui .next-icon--color-base {
  color: transparent;
  fill: var(--p-icon)
}

.fresh-ui .next-icon--color-subdued {
  color: transparent;
  fill: var(--p-icon-subdued)
}

.fresh-ui .next-icon--color-critical {
  color: transparent;
  fill: var(--p-icon-critical)
}

.fresh-ui .next-icon--color-warning {
  color: transparent;
  fill: var(--p-icon-warning)
}

.fresh-ui .next-icon--color-highlight {
  color: transparent;
  fill: var(--p-icon-highlight)
}

.fresh-ui .next-icon--color-success {
  color: transparent;
  fill: var(--p-icon-success)
}

.fresh-ui .next-icon--color-primary {
  color: transparent;
  fill: var(--p-action-primary)
}

.next-icon--size-8 {
  width: 8px;
  height: 8px
}

.next-icon--size-10 {
  width: 10px;
  height: 10px
}

.next-icon--size-12 {
  width: 12px;
  height: 12px
}

.next-icon--size-16 {
  width: 16px;
  height: 16px
}

.next-icon--size-20 {
  width: 20px;
  height: 20px
}

.next-icon--size-24 {
  width: 24px;
  height: 24px
}

.next-icon--size-40 {
  width: 40px;
  height: 40px
}

.next-icon--size-80 {
  width: 80px;
  height: 80px
}

.next-icon--size-140 {
  width: 140px;
  height: 140px
}

.next-icon--rotate-45 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.next-icon--rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.next-icon--rotate-135 {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg)
}

.next-icon--rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.next-icon--rotate-225 {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg)
}

.next-icon--rotate-270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg)
}

.keyboard-commands {
  list-style: none;
  margin: 0
}

@media (max-width: 450px) {
  .keyboard-commands__container>.next-grid {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

.keyboard-command {
  padding-top: 0.0625rem;
  padding-bottom: 0.25rem
}

.keyboard-key {
  background-color: white;
  border: 1px solid #c4cdd5;
  border-radius: var(--p-border-radius-base);
  -webkit-box-shadow: inset 0 0 1px white, inset 0 0 0.1em white, 0 0.1em 0 #dfe3e8, 0 0.12em 0.1em #212529;
  box-shadow: inset 0 0 1px white, inset 0 0 0.1em white, 0 0.1em 0 #dfe3e8, 0 0.12em 0.1em #212529;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: default;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  height: 1.625rem;
  line-height: 1.625rem;
  margin: 0 0.125rem 0.125rem;
  min-width: 1.625rem;
  padding: 0 0.375rem;
  text-align: center;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.keyboard-key~.keyboard-key {
  margin-left: 0.1875rem
}

.keyboard-name {
  margin-left: 0.25rem
}

@media (min-width: 1024px) {
  .navigating-commands {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2
  }
}

.fresh-ui .keyboard-key {
  border: 0;
  -webkit-box-shadow: 0 0 0 1px #c4cdd5, 0 0.18em 0 0 white, 0 0.18em 0 1px #c4cdd5;
  box-shadow: 0 0 0 1px #c4cdd5, 0 0.18em 0 0 white, 0 0.18em 0 1px #c4cdd5;
  color: #454f5b;
  height: auto;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.875rem;
  margin: 0 0.25rem 0.5rem 0;
  min-width: 1.375rem;
  padding: 0.25rem
}

.fresh-ui .keyboard-key~.keyboard-key {
  margin-left: 0.25rem
}

.next-label {
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: initial;
  letter-spacing: initial;
  cursor: pointer
}

@media screen and (min-width: 640px) {
  .next-label {
    font-size: 0.875rem
  }
}

.next-field__connected-wrapper .next-label {
  min-width: 0;
  max-width: 100%
}

.next-label--inline {
  display: inline-block;
  padding-right: 0.5rem
}

.next-label--no-margin {
  margin: 0
}

.next-label--switch {
  display: inline-block;
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0
}

.next-label--color-picker {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 0.5rem;
  margin-bottom: 0;
  min-height: 1.1875rem
}

.ui-select__wrapper {
  position: relative;
  border: 0.0625rem solid var(--p-border-neutral-subdued);
  border-radius: var(--p-border-radius-base);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: var(--p-surface);
  overflow: visible;
  vertical-align: bottom;
  background-image: none;
  background-color: var(--p-surface);
  border-bottom-color: var(--p-border-shadow-subdued);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  position: relative
}

.ui-select__wrapper::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(var(--p-control-border-width)/2 + 0.0625rem));
  right: calc(-1 * calc(var(--p-control-border-width)/2 + 0.0625rem));
  bottom: calc(-1 * calc(var(--p-control-border-width)/2 + 0.0625rem));
  left: calc(-1 * calc(var(--p-control-border-width)/2 + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(var(--p-control-border-width)/2 + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(var(--p-control-border-width)/2 + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.0625rem)
}

.ui-select__wrapper.next-input--is-focused {
  border-color: var(--p-border-neutral-subdued);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05)
}

.ui-select__wrapper.next-input--is-focused::after {
  -webkit-box-shadow: 0 0 0 0.125rem var(--p-focused);
  box-shadow: 0 0 0 0.125rem var(--p-focused)
}

.ui-select__wrapper .next-icon,
.ui-select__wrapper .next-icon__text {
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  display: block;
  fill: var(--p-icon);
  margin-top: -0.5rem;
  pointer-events: none
}

.next-field--connected .ui-select__wrapper {
  border-radius: 0 var(--p-border-radius-base) var(--p-border-radius-base) 0
}

.next-input-wrapper--is-error .ui-select__wrapper {
  border-color: var(--p-border-critical);
  background-color: var(--p-surface-critical)
}

.ui-select__wrapper--inline {
  display: inline-block
}

.ui-select {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  height: 100%;
  width: 100%;
  background: transparent;
  text-rendering: auto;
  border-radius: var(--p-border-radius-base);
  margin: 0;
  border: 0;
  max-width: none;
  text-overflow: ellipsis;
  padding: 0.25rem 0.5rem;
  padding-right: 1.75rem
}

@media screen and (min-width: 640px) {
  .ui-select {
    font-size: 0.875rem
  }
}

.ui-select:focus {
  border: 0;
  outline: none
}

.ui-select option {
  color: var(--p-text)
}

.ui-select:-moz-focusring {
  color: transparent;
  text-shadow: var(--p-override-none)
}

.ui-select::-ms-expand {
  display: none
}

.ui-select:focus::-ms-value {
  background: transparent;
  color: var(--p-text)
}

.ui-select[disabled] {
  background: var(--p-action-secondary-disabled);
  color: var(--p-text-disabled);
  border-color: var(--p-border-disabled);
  -webkit-box-shadow: none;
  box-shadow: none
}

.ui-select[disabled]~.next-icon,
.ui-select[disabled]~.next-icon__text {
  fill: var(--p-icon-disabled)
}

.ui-text-area-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border);
  border-radius: var(--p-border-radius-base);
  background-color: var(--p-surface);
  color: var(--p-text);
  padding: 0.25rem 0.5rem;
  position: relative
}

.ui-text-area-container::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(var(--p-control-border-width)/2 + 0.0625rem));
  right: calc(-1 * calc(var(--p-control-border-width)/2 + 0.0625rem));
  bottom: calc(-1 * calc(var(--p-control-border-width)/2 + 0.0625rem));
  left: calc(-1 * calc(var(--p-control-border-width)/2 + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(var(--p-control-border-width)/2 + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(var(--p-control-border-width)/2 + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.0625rem)
}

.ui-text-area-container.ui-text-area-container--is-focused::after {
  -webkit-box-shadow: 0 0 0 0.125rem var(--p-focused);
  box-shadow: 0 0 0 0.125rem var(--p-focused)
}

.ui-text-area-container[disabled],
.ui-text-area-container.ui-text-area-container--is-disabled {
  background-color: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  border-color: var(--p-border-disabled)
}

.next-input-wrapper--is-error .ui-text-area-container {
  background-color: var(--p-surface-critical-subdued);
  border-color: var(--p-border-critical)
}

.next-input-wrapper--is-error .ui-text-area-container.ui-text-area-container--is-focused {
  border-top-color: var(--p-border-critical)
}

.ui-text-area {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: initial;
  letter-spacing: initial;
  border: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: var(--p-text);
  display: block;
  height: 6.625rem;
  margin: 0;
  width: 100%;
  max-width: 100%;
  min-height: 0;
  outline: none
}

@media screen and (min-width: 640px) {
  .ui-text-area {
    font-size: 0.875rem
  }
}

.ui-text-area::-webkit-input-placeholder {
  color: var(--p-text-disabled);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased
}

.ui-text-area::-moz-placeholder {
  color: var(--p-text-disabled);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased
}

.ui-text-area:-ms-input-placeholder {
  color: var(--p-text-disabled);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased
}

.ui-text-area::-ms-input-placeholder {
  color: var(--p-text-disabled);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased
}

.ui-text-area::placeholder {
  color: var(--p-text-disabled);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased
}

.ui-text-area--resize-vertical {
  max-height: 14.125rem;
  resize: vertical
}

.ui-text-area--expanding {
  height: 2.125rem;
  overflow: hidden;
  resize: none
}

.only-when-printing {
  display: none
}

@media print {
  .only-when-printing {
    display: block
  }
}

.validation-error {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0.5rem
}

.validation-error__icon {
  top: 0;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  margin-right: 4px;
  fill: #de3618
}

.validation-error__message {
  color: #bf0711
}

.fresh-ui .validation-error {
  margin-top: 0.5rem
}

.buttons {
  text-align: right;
  padding: 0.5rem;
  background: #f9fafb;
  border-top: 1px solid var(--p-border);
  border-bottom: 1px solid var(--p-border)
}

.buttons.slim {
  padding: 0.25rem 0.5rem
}

.buttons.slim .btn {
  padding: 0.375rem 0.625rem
}

.buttons.plain {
  background: none;
  padding: 0.8125rem 0;
  border-bottom: none;
  border-top: 1px solid var(--p-border)
}

.buttons.connected {
  background: #f9fafb;
  border: 1px solid var(--p-border);
  border-top: none
}

.buttons .text-action {
  line-height: 2rem
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0
}

table.no-hover tr:hover td {
  background-color: transparent;
  border-color: #ececec
}

table.no-hover tr.summary:hover td {
  background: #f9f9f9;
  border-color: #ececec
}

table.no-hover tr:hover td.table-dropdown {
  background-color: #f9f9f9;
  border-color: #dcdcdc;
  border-top-color: #ccc
}

table.border-bottom tr:last-child td {
  border-bottom: 1px solid var(--p-divider)
}

table.border-bottom tr:last-child td.table-dropdown {
  border: 1px solid #dcdcdc
}

table.border-top th {
  border: 0
}

table.border-top td {
  border-bottom: 0;
  border-top: 1px solid var(--p-divider)
}

table.border-top tr.no-border td {
  border-top: 0
}

table.border-left td,
table .border-left {
  border-left: solid 1px #d3dbe2
}

table.expanded td {
  padding-top: 1rem;
  padding-bottom: 1rem
}

tr.selected:hover td,
tr.selected td,
tr.selected td:hover {
  background-color: #fffbe9
}

tr:last-child>td {
  border-bottom: none
}

th {
  font-weight: 400;
  text-align: left;
  color: #31373d;
  padding: 1rem;
  border-bottom: 1px solid var(--p-divider);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

th.is-sortable {
  cursor: pointer
}

th.is-sortable:hover {
  background: #ebf5fa;
  color: #006fbb
}

th.is-sortable:hover>span::before {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20xmlns:xlink=%22http://www.w3.org/1999/xlink%22%20xmlns:sketch=%22http://www.bohemiancoding.com/sketch/ns%22%20width=%229px%22%20height=%224px%22%20viewBox=%220%200%209%204%22%20version=%221.1%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,ellipse,line,path,polygon,polyline,rect,text%7Bfill:rgb(0,%20111,%20187)%20!important;%20%7D%3C/style%3E%3Ctitle%3Edisclosure-icon%3C/title%3E%3Cdesc%3ECreated%20with%20Sketch.%3C/desc%3E%3Cdefs/%3E%3Cg%20id=%22Page-1%22%20stroke=%22none%22%20stroke-width=%221%22%20fill=%22none%22%20fill-rule=%22evenodd%22%20sketch:type=%22MSPage%22%3E%3Cpolygon%20id=%22disclosure-icon%22%20fill=%22%23929292%22%20sketch:type=%22MSShapeGroup%22%20transform=%22translate(4.500000,%202.000000)%20scale(1,%20-1)%20translate(-4.500000,%20-2.000000)%20%22%20points=%224.5%200%209%204%200%204%20%22/%3E%3C/g%3E%3C/svg%3E") !important
}

th.sorted-desc>span,
th.sorted-asc>span {
  padding-right: 16px;
  position: relative
}

th.sorted-desc>span::before,
th.sorted-asc>span::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20xmlns:xlink=%22http://www.w3.org/1999/xlink%22%20xmlns:sketch=%22http://www.bohemiancoding.com/sketch/ns%22%20width=%229px%22%20height=%224px%22%20viewBox=%220%200%209%204%22%20version=%221.1%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,ellipse,line,path,polygon,polyline,rect,text%7Bfill:rgb(69,%2078,%2087)%20!important;%20%7D%3C/style%3E%3Ctitle%3Edisclosure-icon%3C/title%3E%3Cdesc%3ECreated%20with%20Sketch.%3C/desc%3E%3Cdefs/%3E%3Cg%20id=%22Page-1%22%20stroke=%22none%22%20stroke-width=%221%22%20fill=%22none%22%20fill-rule=%22evenodd%22%20sketch:type=%22MSPage%22%3E%3Cpolygon%20id=%22disclosure-icon%22%20fill=%22%23929292%22%20sketch:type=%22MSShapeGroup%22%20transform=%22translate(4.500000,%202.000000)%20scale(1,%20-1)%20translate(-4.500000,%20-2.000000)%20%22%20points=%224.5%200%209%204%200%204%20%22/%3E%3C/g%3E%3C/svg%3E");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center
}

th.sorted-desc>span::before {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg)
}

th.sorted-asc>span::before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

th.right-aligned {
  text-align: right
}

th.right-aligned>span {
  padding-right: 0;
  padding-left: 16px
}

th.right-aligned.sorted-desc>span::before {
  right: auto;
  left: 0
}

th.right-aligned.sorted-asc>span::before {
  right: auto;
  left: 0
}

th.select {
  width: 32px;
  max-width: 32px;
  z-index: 7
}

th.select input[type='checkbox'] {
  position: relative;
  z-index: 20
}

th>span {
  display: inline-block
}

td {
  border-bottom: 1px solid var(--p-divider);
  padding: 1rem;
  background: transparent
}

td.key {
  width: 15%;
  text-align: right
}

td.value {
  width: 85%
}

td.border-right {
  border-right: solid 1px #d3dbe2
}

td.select {
  width: 18px;
  max-width: 18px
}

td img {
  display: block
}

label.disabled {
  color: #a6a6a6
}

th.drag-handle,
td.drag-handle {
  padding-right: 0;
  padding-left: 0;
  width: 21px
}

th.link-name,
td.link-name {
  padding-left: 0;
  padding-right: 0
}

th.link-value,
td.link-value {
  width: 100%;
  padding-right: 0
}

th.link-remove,
td.link-remove {
  text-align: right;
  padding-right: 0
}

th.customer,
td.customer {
  width: 250px
}

th.section,
td.section {
  width: 20%
}

th.language,
td.language {
  width: 200px
}

th.theme_language-actions,
td.theme_language-actions {
  width: 180px
}

th.remove,
td.remove {
  width: 34px
}

th.select,
td.select {
  padding-right: 7px !important;
  padding-left: 7px !important
}

th.draggable,
td.draggable {
  width: 21px;
  padding-right: 5px
}

th.border-bottom-none,
td.border-bottom-none {
  border-bottom: none !important
}

tfoot,
tfoot td {
  text-align: right
}

tfoot td {
  border: none
}

.table-hover tr:hover td {
  background: var(--p-surface-hovered, #ebf5fa);
  cursor: pointer
}

.table-hover .table-blank-slate tr:hover td {
  background: transparent
}

.summary td {
  background: #fafbfc
}

td a.subdued,
td .dropdown li a.subdued,
.dropdown li td a.subdued {
  text-decoration: none
}

td a.subdued:hover {
  text-decoration: underline
}

.is-archived td {
  color: var(--p-text-subdued)
}

.is-archived td>a {
  color: var(--p-text-subdued)
}

.is-archived td>a:hover {
  color: #212b36
}

.is-cancelled td {
  color: #d83e3e
}

.is-cancelled td>a {
  color: #d83e3e
}

.is-cancelled td.total {
  text-decoration: line-through
}

.is-abandoned td {
  color: #e06b0c
}

.is-abandoned td>a {
  color: #e06b0c
}

.data-table-th {
  border-right: 1px solid #ececec;
  background: #fafafa
}

.data-table-td {
  border-right: 1px solid #ececec;
  -webkit-transition: all 0.15s;
  transition: all 0.15s
}

.table-label {
  background: #fafafa;
  border-right: 1px solid #e6e6e6;
  padding-left: 20px;
  padding-top: 8px;
  min-width: 220px;
  vertical-align: top
}

.table-label-copy {
  color: #444444;
  margin-top: 10px
}

.column-type {
  background: #fafafa
}

.column-type-ignored {
  color: #444444;
  text-decoration: line-through
}

.is-selected>td,
.is-selected:hover>td {
  background-color: #fffbe9;
  border-color: #ebebda
}

.first-row td {
  padding-top: 15px
}

.last-row td {
  color: #333;
  border-top: 1px solid var(--p-divider)
}

.summary-total td {
  padding: 0
}

.summary-total td span {
  display: block;
  margin-top: 14px;
  padding: 6px 10px;
  border-top: 1px solid var(--p-divider);
  font-weight: bold;
  background: #f6f6f6
}

.select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.select input[type='checkbox'] {
  margin: 0;
  height: auto
}

.image {
  width: 50px;
  padding-right: 5px
}

.image img {
  max-width: 50px;
  margin: 0 auto;
  border-radius: 4px
}

.image.thumb {
  height: 50px
}

.table-img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px
}

.sortable-placeholder {
  display: block
}

.table-fixed {
  table-layout: fixed
}

.table-fixed td {
  word-wrap: break-word;
  word-break: break-word
}

.table--no-border {
  border: none
}

.table--no-border tbody {
  border: none
}

.table--no-border thead {
  border: none
}

.table--no-border tr {
  border: none
}

.table--no-border tfoot {
  border: none
}

.table--no-border th {
  border: none
}

.table--no-border td {
  border: none
}

.table__row--no-border td {
  border: none !important
}

.no-border-bottom {
  border-bottom: none !important
}

.table-dropdown {
  position: relative;
  background: #f9f9f9;
  padding: 25px 28px !important;
  border: 1px solid #dcdcdc;
  margin-top: -1px
}

.table-dropdown .heading {
  width: 100%;
  position: relative;
  left: -4px
}

.table-dropdown .buttons {
  position: relative;
  width: 100%;
  padding: 10px 10px 10px 46px;
  top: 25px;
  left: -28px;
  background: #fafafa;
  border-top: 1px solid var(--p-divider);
  border-bottom: none
}

.table-dropdown .buttons p {
  line-height: 32px
}

.table-dropdown table {
  border: none
}

.table-dropdown table th {
  cursor: default;
  border-top: none !important
}

.table-dropdown table th,
.table-dropdown table tr:hover td,
.table-dropdown table td {
  background: transparent !important
}

.table-dropdown table td {
  border-bottom: 1px solid var(--p-divider)
}

.table-dropdown table th:first-child,
.table-dropdown table td:first-child {
  padding-left: 0
}

.table-dropdown table th:last-child,
.table-dropdown table td:last-child {
  padding-right: 0
}

.fulfillment-table th:first-child {
  width: 250px
}

.fulfillment-table th:last-child {
  width: 140px
}

.number-of-items {
  width: 90px
}

.table--no-side-padding th:first-child,
.table--no-side-padding td:first-child {
  padding-left: 0
}

.table--no-side-padding th:last-child,
.table--no-side-padding td:last-child {
  padding-right: 0
}

.table--nested td:first-child {
  padding-left: 0
}

.table--nested td:last-child {
  padding-right: 0
}

.table--nested tr:first-child td {
  padding-top: 0 !important
}

.table--extra-expanded td {
  padding-top: 10px;
  padding-bottom: 10px
}

.table--rounded-border {
  border: 1px solid #ebeef0;
  border-radius: 4px
}

.table--no-horizontal-padding td:first-child {
  padding-left: 0
}

.table--no-horizontal-padding td:last-child {
  padding-right: 0
}

.table-wrapper {
  width: 1px;
  max-width: 100%;
  min-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch
}

.table--divided {
  border-collapse: collapse
}

.table--divided tr {
  border-top: 1px solid var(--p-divider)
}

.table--divided tr:first-child {
  border-top: 0
}

.table--divided td {
  border: 0
}

.table-cell--no-left-padding {
  padding-left: 0
}

.table-cell--no-right-padding {
  padding-right: 0
}

.table-cell--no-wrap {
  white-space: nowrap
}

.ui-sortable-helper>tr>td {
  background: #ffffff
}

.table-wrapper-sticky {
  position: relative
}

.table-wrapper-sticky--is-sticky td,
.table-wrapper-sticky--is-sticky th {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.table-wrapper-sticky--is-sticky .table__cell--sticky {
  background-color: #ffffff;
  position: absolute;
  z-index: 4;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.table-wrapper-sticky--is-sticky .table__cell--sticky:first-child {
  left: 0;
  -webkit-box-shadow: 4px 0 3px -1px rgba(69, 78, 87, 0.05);
  box-shadow: 4px 0 3px -1px rgba(69, 78, 87, 0.05);
  border-right: solid 1px #d3dbe2
}

.table-wrapper-sticky--is-sticky .table__cell--sticky:last-child {
  right: 0;
  -webkit-box-shadow: -4px 0 3px -1px rgba(69, 78, 87, 0.05);
  box-shadow: -4px 0 3px -1px rgba(69, 78, 87, 0.05);
  border-left: solid 1px #d3dbe2
}

.selected .table__cell--sticky {
  background-color: #fffbe9
}

.table-wrapper-sticky--supports-position-sticky .table__cell--sticky {
  display: table-cell
}

.table-wrapper-sticky--is-sticky .table__cell--sticky--bulk-selection-active:first-child {
  width: 100% !important;
  border-left: none;
  border-right: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: block;
  position: absolute;
  z-index: 6
}

.table-wrapper-sticky--is-sticky.table-wrapper-sticky--is-resizing .table__cell--sticky--bulk-selection-active {
  width: auto !important
}

.fresh-ui th {
  color: var(--p-text);
  font-weight: 400
}

.fresh-ui th.is-sortable:hover {
  color: var(--p-interactive-hovered);
  background-color: transparent;
  -webkit-transition: color 200ms cubic-bezier(0.64, 0, 0.35, 1);
  transition: color 200ms cubic-bezier(0.64, 0, 0.35, 1)
}

.fresh-ui th.is-sortable:hover>span::before {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2020%2020%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,ellipse,line,path,polygon,polyline,rect,text%7Bfill:rgb(33,%2043,%2054)%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M6.28%209.28l3.366%203.366c.196.196.512.196.708%200L13.72%209.28c.293-.293.293-.767%200-1.06-.14-.14-.332-.22-.53-.22H6.81c-.414%200-.75.336-.75.75%200%20.2.08.39.22.53z%22/%3E%3C/svg%3E") !important
}

.fresh-ui th.sorted-desc>span::before,
.fresh-ui th.sorted-asc>span::before {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2020%2020%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,ellipse,line,path,polygon,polyline,rect,text%7Bfill:rgb(33,%2043,%2054)%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M6.28%209.28l3.366%203.366c.196.196.512.196.708%200L13.72%209.28c.293-.293.293-.767%200-1.06-.14-.14-.332-.22-.53-.22H6.81c-.414%200-.75.336-.75.75%200%20.2.08.39.22.53z%22/%3E%3C/svg%3E");
  width: 20px;
  right: -0.25rem
}

.fresh-ui .table-hover tr:hover td {
  background-color: var(--p-surface-hovered)
}

.fresh-ui .table-hover a:hover {
  text-decoration: none;
  color: var(--p-interactive-hovered)
}

.fresh-ui .table-hover .is-archived td>a:hover {
  color: var(--p-text-subdued)
}

.fresh-ui table tr.selected td,
.fresh-ui table tr.selected:hover td {
  background-color: var(--p-surface-pressed)
}

.section {
  position: relative;
  padding: 25px 0;
  border-bottom: 1px solid var(--p-divider)
}

.section h5 {
  color: #000;
  padding: 0 0 7px 0;
  font-weight: bold
}

.section.row {
  margin: 0
}

.section.first-section {
  padding-top: 5px
}

.section.last-section {
  padding-bottom: 5px;
  border-bottom: none
}

.section .section-summary {
  float: left;
  padding-left: 50px;
  padding-right: 0px
}

.section .section-summary h1,
.section .section-summary h2 {
  font-size: 18px;
  margin-bottom: 10px
}

.section .section-summary p {
  margin-bottom: 15px
}

.section .section-content {
  float: right;
  padding: 0 20px 0 25px
}

.section>.section-content-full-width {
  float: none;
  padding: 0 20px 0 50px
}

.section.setting-summary {
  padding: 10px 50px 30px 50px
}

.section.setting-summary.setting-summary-centered {
  text-align: center;
  padding: 10px 0 30px 0
}

.section.setting-summary p {
  font-size: 18px;
  color: #000;
  line-height: 28px
}

.section.section-vertically-centered {
  display: table
}

.section.section-vertically-centered>.section-summary {
  float: none;
  display: table-cell
}

.section.section-vertically-centered>.section-content {
  float: none;
  display: table-cell;
  vertical-align: middle
}

.section-heading {
  font-size: 18px;
  margin: 0 10px 20px 0;
  display: inline-block
}

.section-heading-label {
  display: inline-block;
  margin: 0 5px 10px 0
}

.section-actions {
  list-style: none;
  display: inline-block;
  font-size: 0;
  margin: 0
}

.section-action {
  display: inline-block;
  margin: 0;
  font-size: 14px
}

.section-action::after {
  content: '\b7\a0';
  display: inline-block;
  padding: 0 2px 0 5px
}

.section-action:last-child::after {
  content: ''
}

.section--divided {
  border-bottom: none
}

.section--divided+.section--divided {
  border-top: 1px solid var(--p-divider)
}

.section--highlighted {
  background: #fff7b2;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: flash-highlight;
  animation-name: flash-highlight;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards
}

@-webkit-keyframes flash-highlight {
  from {
    background: #fff7b2
  }

  to {
    background: rgba(255, 247, 178, 0)
  }
}

@keyframes flash-highlight {
  from {
    background: #fff7b2
  }

  to {
    background: rgba(255, 247, 178, 0)
  }
}

.next-ui .section-summary,
.next-ui .section-content,
.next-ui .section {
  float: none
}

.next-ui .section {
  border-color: #d3dbe2;
  margin: 0;
  padding: 0
}

.next-ui .section-content {
  padding: 0
}

.next-ui .section-summary {
  padding: 20px 20px 20px 30px
}

.next-ui .section-summary h1 {
  color: #31373d;
  line-height: 1.325;
  margin-top: -4px
}

.next-ui .section-summary p {
  color: #798c9c
}

.next-ui .section-summary p:last-child {
  margin-bottom: 0
}

.next-ui .setting-summary {
  padding: 30px 50px
}

.next-ui .section--no-border {
  border: none
}

.next-ui .section .next-grid--space-between>.next-grid__cell:first-child .btn {
  margin-left: 30px
}

.next-ui .section-content .bulk-actions {
  left: 25%;
  margin-left: 34px;
  width: calc(75% - 54px)
}

.next-ui .section--with-padding {
  padding: 20px
}

.has-dropdown {
  display: inline-block;
  position: relative
}

.has-dropdown .dropdown,
.has-dropdown .next-dropdown {
  z-index: 224
}

.next-dropdown__container {
  position: relative
}

.dropdown,
.next-dropdown {
  z-index: 197;
  display: none;
  position: absolute;
  top: 100%;
  margin-top: 11px;
  border: 1px solid #c0c0c0;
  background: #ffffff;
  -webkit-box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border-radius: 3px
}

.dropdown::after,
.dropdown::before,
.next-dropdown::after,
.next-dropdown::before {
  position: absolute;
  right: 10px;
  content: '';
  width: 0;
  height: 0;
  border-top: none;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent
}

.dropdown::before,
.next-dropdown::before {
  top: -7px;
  border-bottom: 7px solid #c2c2c2
}

.dropdown::after,
.next-dropdown::after {
  top: -6px;
  border-bottom: 7px solid #ffffff
}

.dropdown.left-aligned,
.next-dropdown.left-aligned {
  right: auto;
  left: 0px
}

.dropdown.left-aligned::before,
.dropdown.left-aligned::after,
.next-dropdown.left-aligned::before,
.next-dropdown.left-aligned::after {
  left: 10px;
  border-top: none;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent
}

.dropdown.left-aligned::before,
.next-dropdown.left-aligned::before {
  top: -7px;
  border-bottom: 7px solid #c2c2c2
}

.dropdown.left-aligned::after,
.next-dropdown.left-aligned::after {
  top: -6px;
  border-bottom: 7px solid #ffffff
}

.dropdown.display-from-right,
.next-dropdown.display-from-right {
  top: 0;
  left: 100%;
  margin-top: 0;
  margin-left: 11px
}

.dropdown.display-from-right::before,
.dropdown.display-from-right::after,
.next-dropdown.display-from-right::before,
.next-dropdown.display-from-right::after {
  top: 10px;
  border-left: none;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent
}

.dropdown.display-from-right::before,
.next-dropdown.display-from-right::before {
  left: -7px;
  border-right: 7px solid #c2c2c2
}

.dropdown.display-from-right::after,
.next-dropdown.display-from-right::after {
  left: -6px;
  border-right: 7px solid #ffffff
}

.dropdown.display-from-bottom,
.next-dropdown.display-from-bottom {
  top: auto;
  bottom: 100%;
  margin-bottom: 8px
}

.dropdown.display-from-bottom::before,
.dropdown.display-from-bottom::after,
.next-dropdown.display-from-bottom::before,
.next-dropdown.display-from-bottom::after {
  top: 100%;
  left: 10px;
  border-bottom: none;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent
}

.dropdown.display-from-bottom::before,
.next-dropdown.display-from-bottom::before {
  border-top: 7px solid #c2c2c2
}

.dropdown.display-from-bottom::after,
.next-dropdown.display-from-bottom::after {
  margin-top: -1px;
  border-top: 7px solid #ffffff
}

.dropdown {
  line-height: 18px;
  right: 0;
  text-align: left;
  min-width: 175px;
  font-size: 0
}

.dropdown.show-content {
  font-size: 13px
}

.dropdown.dropdown-padded {
  padding: 10px
}

.dropdown ul {
  margin: 5px 0
}

.dropdown li {
  text-transform: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400
}

.dropdown li a {
  font-weight: 400;
  display: block;
  padding: 3px 10px;
  border-radius: 0px;
  text-decoration: none
}

.dropdown li a:hover,
.dropdown li a.is-selected {
  text-decoration: none;
  color: #ffffff;
  background: #006fbb
}

.dropdown li a.destroy:hover {
  background: #ba4747
}

.dropdown li a.is-disabled {
  color: #bbbbbb;
  pointer-events: none
}

.dropdown li a.is-disabled:hover {
  color: #bbbbbb;
  cursor: default;
  background: #ffffff
}

.dropdown li a.subdued {
  padding: 0
}

.dropdown li a.subdued:hover {
  background: none;
  text-decoration: underline
}

.dropdown .app-icon-list {
  padding: 5px 10px;
  overflow: hidden;
  position: relative
}

.dropdown .app-icon-list img {
  margin-right: 10px;
  float: left;
  width: 16px;
  height: 16px;
  display: block;
  position: absolute
}

.dropdown .app-icon-list:hover {
  cursor: pointer;
  background: #006fbb
}

.dropdown .app-icon-list:hover a {
  color: #ffffff
}

.dropdown .app-icon-list:hover img {
  background-color: #ffffff
}

.dropdown .app-icon-list a {
  padding: 0 0 0 28px
}

.dropdown.focused::before {
  border-bottom: 7px solid #3578d5
}

.dropdown.display-from-right.focused::before {
  border-right: 7px solid #3578d5
}

.dropdown .break {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--p-divider)
}

.dropdown .break-top {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid var(--p-divider)
}

.dropdown.filter-dropdown .filter-heading {
  font-size: 14px;
  margin-bottom: 4px
}

.dropdown.filter-dropdown .add-filters {
  font-size: 14px
}

.dropdown.filter-dropdown .active-filters {
  margin: 0
}

.dropdown.filter-dropdown .filter-builder select,
.dropdown.filter-dropdown .filter-builder a,
.dropdown.filter-dropdown .filter-builder input,
.dropdown.filter-dropdown .filter-builder span {
  margin-right: 5px
}

.dropdown .dropdown-body {
  font-size: 14px
}

.active .dropdown,
.active .next-dropdown {
  display: block;
  -webkit-animation: reveal 0.2s ease-out;
  animation: reveal 0.2s ease-out
}

.dropdown.disabled a {
  color: #aaa !important;
  pointer-events: none !important
}

.dropdown-with-close .close-dropdown {
  height: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: right;
  width: 10px
}

.dropdown-with-close .dropdown-body {
  padding-right: 20px
}

.selected-result>a {
  text-decoration: none;
  color: #ffffff;
  background: #006fbb
}

.selected-result {
  text-decoration: none;
  color: #ffffff;
  background: #ebeef0
}

.dropdown-container {
  position: relative;
  display: inline-block
}

.dropdown-container--full-width {
  position: relative;
  display: block;
  text-align: center
}

.dropdown-header {
  background: #fafbfc;
  border-bottom: solid 1px #ebeef0;
  border-radius: 3px 3px 0 0;
  padding: 10px;
  font-size: 13px
}

.dropdown-header::after {
  content: '';
  display: table;
  clear: both
}

.dropdown-header-title {
  float: left;
  font-weight: 700
}

.dropdown-header-title a {
  text-decoration: none
}

.dropdown-header-action {
  float: right
}

.next-dropdown {
  min-width: 220px;
  line-height: 18px
}

.next-dropdown--full-width {
  width: 100%
}

.next-dropdown__section {
  padding: 10px
}

.next-dropdown__section~.next-dropdown__section {
  border-top: 1px solid #ebeef0
}

.next-dropdown__section--full-padding {
  padding: 20px
}

.next-dropdown__section--no-padding {
  padding: 0
}

.next-dropdown__footer {
  padding: 0 20px 20px 20px;
  text-align: right
}

.next-dropdown__footer .btn {
  margin-left: 5px
}

.dropdown-container--is-error>.btn,
.next-dropdown__container--is-error>.btn {
  background-color: #fef6f5;
  border-color: #ff5d5d;
  color: #ff5d5d
}

.progress-bar {
  position: relative;
  height: 24px;
  background-color: #ebeef0;
  border-radius: 3px;
  overflow: hidden;
  cursor: default
}

.progress-bar.progress-bar--margin-top {
  margin-top: 5px
}

.progress-bar.super-skinny {
  height: 4px
}

.progress-bar.very-skinny {
  height: 6px
}

.progress-bar.skinny {
  height: 12px
}

.progress-bar.large {
  height: 36px
}

.progress-level {
  position: relative;
  width: 100%;
  height: 100%;
  background: #96bf48;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px
}

.progress-level.progress-level--next-purple-light {
  background: #d098ff
}

.progress-level.progress-level--next-red {
  background: #ff5d5d
}

.progress-bar--transition .progress-level {
  -webkit-transition-property: width;
  transition-property: width;
  -webkit-transition-duration: 600ms;
  transition-duration: 600ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out
}

.progress-bar-animated .progress-level {
  -webkit-animation: fillup 1.2s cubic-bezier(0.83, 0.04, 0.13, 0.99);
  animation: fillup 1.2s cubic-bezier(0.83, 0.04, 0.13, 0.99)
}

.progress-level.full {
  border-radius: 3px
}

.progress-data {
  font-size: 16px;
  color: #798c9c
}

.progress-data-item {
  color: #31373d;
  letter-spacing: -0.015em
}

.progress-data-item.secondary {
  color: #798c9c;
  font-weight: 300
}

.progress-data-item small {
  font-size: 1em;
  color: inherit
}

.drop-target {
  border: 2px dashed #ccc;
  border-radius: 6px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  color: #777;
  -webkit-transition: all 0.15s;
  transition: all 0.15s
}

.drop-target:hover {
  border-color: #aaa;
  color: #555
}

.progress-bar--flat,
.progress-bar--flat .progress-level {
  border-radius: 0
}

.progress-bar--flat .progress-level {
  background-color: #c3cfd8
}

.tooltip {
  position: relative !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.tooltip::before {
  content: '';
  display: none;
  position: absolute;
  left: 50%;
  margin-left: -4px;
  width: 0;
  height: 0;
  border: 4px solid transparent
}

.tooltip.is-active::before {
  display: block
}

.tooltip-ico,
.tooltip-ico:hover {
  text-decoration: none;
  font-size: 0;
  line-height: 1
}

.tooltip-top::before {
  border-top-color: rgba(33, 37, 41, 0.9);
  bottom: 100%
}

.tooltip-top .tooltip-top--inverse-arrow::before {
  border-top-color: rgba(250, 251, 252, 0.9)
}

.tooltip-bottom::before {
  border-bottom-color: rgba(33, 37, 41, 0.9);
  bottom: auto;
  top: 100%
}

.tooltip-bottom--light-arrow::before {
  border-bottom-color: rgba(250, 251, 252, 0.9)
}

.tooltip--delayed::before {
  display: block;
  opacity: 0;
  pointer-events: none;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
  -webkit-transition-duration: 1s;
  transition-duration: 1s
}

.tooltip--delayed .tooltip-container {
  display: block;
  opacity: 0;
  pointer-events: none;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
  -webkit-transition-duration: 1s;
  transition-duration: 1s
}

.tooltip--delayed.is-active::before {
  -webkit-transition-delay: 500ms;
  transition-delay: 500ms;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  opacity: 1
}

.tooltip--delayed.is-active .tooltip-container {
  -webkit-transition-delay: 500ms;
  transition-delay: 500ms;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  opacity: 1
}

.tooltip-container {
  display: none;
  left: 50%;
  line-height: 18px;
  position: absolute;
  z-index: 999
}

.tooltip.is-active .tooltip-container {
  display: block
}

.tooltip-top>.tooltip-container {
  bottom: calc(100% + 8px)
}

.tooltip-bottom>.tooltip-container {
  top: calc(100% + 8px)
}

.tooltip-left-align>.tooltip-container {
  left: 0
}

.tooltip-right-align>.tooltip-container {
  left: auto;
  right: 0
}

.tooltip-container.tooltip-sidebar-need-help {
  margin-left: 10px
}

.tooltip-container.tooltip-sidebar-announcements {
  margin-right: 10px
}

.tooltip-label {
  border-radius: 3px;
  background-color: rgba(33, 37, 41, 0.9);
  color: #ffffff;
  display: block;
  font-size: 12px;
  font-weight: normal;
  left: -50%;
  padding: 4px 9px;
  position: relative;
  text-indent: 0;
  text-shadow: none;
  white-space: nowrap
}

.tooltip-left-align .tooltip-label {
  left: 0
}

.tooltip-right-align .tooltip-label {
  left: auto;
  right: 0
}

.tooltip-label.tooltip--view-website {
  right: 5px
}

.tooltip-label--light {
  background: rgba(250, 251, 252, 0.9);
  color: #454e57
}

.tooltip--no-decoration:hover {
  text-decoration: none
}

.tooltip-wordy .tooltip-label {
  min-width: 180px;
  white-space: normal;
  line-height: 1.4
}

.tooltip-embiggened .tooltip-container {
  white-space: normal
}

.tooltip-embiggened .tooltip-label {
  font-size: 13px;
  padding: 6px;
  width: 275px
}

.tooltip-error-container {
  position: relative
}

.tooltip-error {
  padding: 2px 9px;
  margin-bottom: 9px;
  background: #ec6060;
  position: absolute;
  top: -7px;
  z-index: 50;
  border-radius: 3px
}

.tooltip-error::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-width: 5px;
  border-style: solid;
  border-color: #ec6060 transparent transparent transparent;
  bottom: -10px;
  left: 15px
}

.tooltip-error.attached-to-bottom {
  top: 100%;
  margin-top: 9px
}

.tooltip-error.attached-to-bottom::after {
  border-color: transparent transparent #ec6060 transparent;
  top: -10px
}

.tooltip-error.attached-to-top {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%)
}

.error-list {
  padding: 0;
  margin: 0;
  line-height: 20px
}

.error-list li {
  list-style-type: none;
  font-size: 13px;
  color: #ffffff
}

.error-highlight {
  border: 1px solid #ec6060
}

.error-highlight-warning {
  /* background-image: url(/admin/alert3.png); */
  background-repeat: no-repeat;
  background-position: right;
  border: 1px solid #e0aa4c
}

.warning-desc {
  color: #c27c00;
  font-size: 13px;
  padding-bottom: 15px
}

.table-wrapper tr:last-child .tooltip-container,
.table-wrapper tr:last-child .tooltip-label {
  right: auto;
  left: 0
}

.table-wrapper tr:last-child .tooltip::before {
  left: 100%;
  margin-right: 0
}

.table-wrapper tr:last-child .tooltip-bottom::before,
.table-wrapper tr:last-child .tooltip-top::before {
  border-bottom-color: transparent;
  border-right-color: rgba(33, 37, 41, 0.9);
  border-top-color: transparent;
  margin-top: -4px;
  top: 50%
}

.table-wrapper tr:last-child .tooltip-container {
  left: calc(100% + 4px);
  top: auto;
  bottom: 0
}

.table-wrapper tr:last-child td:last-child .tooltip-container,
.table-wrapper tr:last-child td:last-child .tooltip-label {
  left: auto;
  right: 0
}

.table-wrapper tr:last-child td:last-child .tooltip::before {
  left: -8px;
  margin-left: 0
}

.table-wrapper tr:last-child td:last-child .tooltip-bottom::before,
.table-wrapper tr:last-child td:last-child .tooltip-top::before {
  border-left-color: rgba(33, 37, 41, 0.9);
  border-right-color: transparent
}

.table-wrapper tr:last-child td:last-child .tooltip-container {
  right: calc(100% + 8px)
}

.ui-scrollable-container {
  padding: 20px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 3px;
  border: 1px solid #ebeef0;
  max-height: 260px
}

.ui-scrollable-container:not(:first-child) {
  margin-top: 20px
}

.ui-scrollable-container:not(:last-child) {
  margin-bottom: 20px
}

.next-heading {
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin: 0 0 20px
}

@media screen and (min-width: 640px) {
  .next-heading {
    font-size: 1rem
  }
}

.next-heading--1 {
  font-size: 22px
}

.next-heading--2 {
  font-size: 20px
}

.next-heading--3 {
  font-size: 18px
}

.next-heading--tiny {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: initial;
  letter-spacing: initial
}

@media screen and (min-width: 640px) {
  .next-heading--tiny {
    font-size: 0.875rem
  }
}

.next-heading--small {
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1rem;
  text-transform: uppercase
}

@media screen and (min-width: 640px) {
  .next-heading--small {
    font-size: 0.75rem
  }
}

.next-heading--large {
  font-size: 24px
}

.next-heading--xl {
  font-size: 32px
}

.next-heading--light-weight {
  font-weight: 300
}

.next-heading--semi-bold {
  font-weight: 500
}

.next-heading--subdued {
  color: var(--p-text-subdued)
}

.next-heading--callout {
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1rem;
  text-transform: uppercase;
  color: #c3cfd8
}

@media screen and (min-width: 640px) {
  .next-heading--callout {
    font-size: 0.75rem
  }
}

.next-heading--callout a.subdued,
.next-heading--callout .dropdown li a.subdued,
.dropdown li .next-heading--callout a.subdued {
  text-decoration: none
}

.next-heading--callout a.subdued:hover {
  text-decoration: underline
}

.next-heading--truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.next-heading--callout--darker {
  color: #798c9c
}

.next-heading--micro-uppercase-bordered {
  color: #798c9c;
  padding: 5px 20px;
  padding-top: 10px;
  font-size: 11px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--p-divider);
  margin: 0
}

.next-heading--no-margin {
  margin: 0
}

.next-heading--half-margin {
  margin: 0 0 10px
}

.next-heading--quarter-margin {
  margin: 0 0 5px
}

.next-form {
  margin: 0;
  padding: 0
}

.next-input--number,
input.next-input--number[type='number'] {
  width: auto
}

.next-input--button,
input.next-input--button[type='button'] {
  cursor: default;
  width: auto;
  max-width: none;
  margin: 2px;
  padding: 3px 6px
}

.next-input[disabled],
.next-input--stylized[disabled],
.next-input--is-disabled {
  -webkit-appearance: none;
  -moz-appearance: none
}

.card-fields-iframe {
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  border: 0;
  background: transparent;
  line-height: normal;
  width: 100%
}

.next-form.next-form--full-width {
  width: 100%;
  min-width: 0;
  max-width: 100%
}

.next-input__help-text {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  word-wrap: break-word;
  word-break: break-word;
  margin-top: 0.25rem
}

@media screen and (min-width: 640px) {
  .next-input__help-text {
    font-size: 0.875rem
  }
}

.next-input__help-text--top {
  margin-bottom: 0.25rem
}

.next-radio--styled+.next-input__help-text,
.next-checkbox--styled+.next-input__help-text {
  padding-left: 1.5rem
}

.next-input--inline {
  display: inline-block
}

.next-input--card-field {
  position: relative;
  height: 36px;
  padding: 0;
  overflow: hidden
}

.next-input--card-field>.next-input--card-fields__container {
  height: 34px
}

.next-input--card-field>.next-input__add-on--after {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.card-fields-iframe {
  height: 34px
}

.next-input--number,
input.next-input--number[type='number'] {
  min-width: 0
}

.next-input--width-2-characters,
input.next-input--width-2-characters[type='number'] {
  width: 3em;
  max-width: 3em;
  min-width: 3em
}

.next-input--width-4-characters,
input.next-input--width-4-characters[type='number'] {
  width: 5em;
  max-width: 5em;
  min-width: 5em
}

.next-input[disabled],
.next-input--stylized[disabled],
.next-input--is-disabled {
  background: var(--p-surface-disabled);
  border-color: var(--p-border);
  color: var(--p-text)
}

.next-input.next-input--invisible[disabled],
.next-input--invisible.next-input--stylized[disabled],
.next-input--is-disabled.next-input--invisible {
  background: none;
  border: 0
}

.next-input-wrapper {
  position: relative
}

.next-input-wrapper+.next-input-wrapper {
  margin-top: 1rem
}

.next-input-wrapper.hide+.next-input-wrapper {
  margin-top: 0
}

.next-input-wrapper+.next-input-wrapper--halved {
  margin-top: 0.5rem
}

.next-input-wrapper+.wrappable {
  padding-top: 1rem
}

.wrappable+.next-input-wrapper {
  margin-top: 1rem
}

.next-fieldset-wrapper .next-input-wrapper {
  margin-top: 0.25rem
}

.select .next-input-wrapper {
  height: 1.375rem
}

.next-input-wrapper--inline-group>*+* {
  margin-left: 0.5rem
}

.next-input-wrapper--inline {
  display: inline-block
}

.next-input-wrapper--inline .next-radio[type='radio'],
.next-input-wrapper--inline .next-checkbox[type='checkbox'] {
  left: calc(50% - 15px);
  top: calc(50% - 15px)
}

.next-input-wrapper--inline .next-radio--styled,
.next-input-wrapper--inline .next-checkbox--styled {
  left: calc(50% - 8px);
  top: calc(50% - 8px)
}

.next-input-wrapper--half-spacing {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem
}

.next-input--stylized {
  position: relative;
  overflow: hidden;
  background: #ffffff;
  margin: 0.5px
}

.next-input--stylized .next-input,
.next-input--stylized .next-input--stylized {
  min-width: 0
}

.next-input--stylized .field_with_errors {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.next-input--scrollable {
  overflow: auto
}

.next-input--invisible {
  display: inline-block;
  min-width: 75px;
  vertical-align: baseline;
  margin: 0;
  color: var(--p-text);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  height: auto;
  padding: 0;
  border: 0;
  color: var(--p-text)
}

.next-input--invisible:focus {
  border: 0
}

.next-input__add-on {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  white-space: nowrap;
  color: var(--p-text-subdued)
}

.next-input--has-content .next-input__add-on {
  color: var(--p-text)
}

.next-input__add-on--before {
  padding-right: 4px
}

.next-input__add-on--before>.next-icon,
.next-input__add-on--before>.next-icon__text {
  margin-right: 0.25rem
}

.next-input__add-on--after {
  padding-left: 4px
}

.next-input__add-on--url-base {
  color: var(--p-text-disabled);
  padding-right: 0
}

.next-input--align-with-radio {
  margin-left: 26px
}

.next-radio--styled,
.next-checkbox--styled,
.next-radio,
input.next-radio[type='radio'],
.next-checkbox,
input.next-checkbox[type='checkbox'] {
  position: absolute;
  left: 0;
  top: 0.15em;
  display: block;
  height: 16px;
  width: 16px;
  border: none;
  background-color: transparent;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  cursor: pointer
}

.next-radio,
input.next-radio[type='radio'],
.next-checkbox,
input.next-checkbox[type='checkbox'] {
  z-index: 2;
  opacity: 0;
  left: -7.5px;
  top: -7.5px;
  width: 30px;
  height: 30px
}

.next-input-wrapper--inline .next-radio,
.next-input-wrapper--inline input.next-radio[type='radio'],
.next-input-wrapper--inline .next-checkbox,
.next-input-wrapper--inline input.next-checkbox[type='checkbox'] {
  position: relative;
  display: inline-block
}

.next-color-picker {
  display: none
}

.next-color-picker--fallback {
  display: block;
  width: 4.375rem
}

.next-label--switch--centered .next-radio--styled,
.next-label--switch--centered .next-checkbox--styled {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.next-input--is-focused {
  border: 1px solid var(--p-border)
}

.next-input--has-error {
  border: 1px solid var(--p-border-critical);
  background: var(--p-surface-critical)
}

.next-field__connected-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.next-form--multi-column .next-field__connected-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column
}

.next-field__connected-wrapper .btn,
.next-field__connected-wrapper .ui-button {
  white-space: nowrap
}

.next-field__connected-wrapper--align-right {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.next-form--align-center .next-field__connected-wrapper--align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.next-field--connected,
.next-field__connected-wrapper .btn,
.next-field__connected-wrapper .ui-button:not(.add-filter),
.next-field__connected-wrapper input {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0%;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  margin: 0;
  max-width: 100%
}

.next-field--connected.next-field--connected--no-flex,
.next-field__connected-wrapper .btn.next-field--connected--no-flex,
.next-field__connected-wrapper .ui-button.next-field--connected--no-flex:not(.add-filter),
.next-field__connected-wrapper input.next-field--connected--no-flex {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto
}

.next-field--connected--extra-padding {
  padding-left: 10px
}

.next-form--multi-column .next-field--connected--extra-padding {
  padding-left: 0;
  padding-top: 10px
}

.next-field__connected-wrapper .ui-popover__container>.ui-button {
  left: 0;
  margin-right: 0
}

.next-input--search {
  -webkit-appearance: textfield
}

.next-input--search::-webkit-search-decoration,
.next-input--search::-webkit-search-cancel-button {
  -webkit-appearance: none
}

.unstyled .next-input-wrapper,
.dropdown .next-input-wrapper {
  margin-top: 0.5rem
}

.next-input,
.next-input--stylized {
  display: inline-block;
  min-width: 75px;
  vertical-align: baseline;
  margin: 0;
  color: var(--p-text);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  padding: 5px 10px;
  border-radius: var(--p-border-radius-base);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: initial;
  letter-spacing: initial;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  width: 100%;
  color: var(--p-text);
  border: 1px solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border);
  background-color: var(--p-surface)
}

@media screen and (min-width: 640px) {

  .next-input,
  .next-input--stylized {
    font-size: 0.875rem
  }
}

.next-input::-webkit-input-placeholder,
.next-input--stylized::-webkit-input-placeholder {
  color: var(--p-text-disabled)
}

.next-input::-moz-placeholder,
.next-input--stylized::-moz-placeholder {
  color: var(--p-text-disabled)
}

.next-input:-ms-input-placeholder,
.next-input--stylized:-ms-input-placeholder {
  color: var(--p-text-disabled)
}

.next-input::-ms-input-placeholder,
.next-input--stylized::-ms-input-placeholder {
  color: var(--p-text-disabled)
}

.next-input::placeholder,
.next-input--stylized::placeholder {
  color: var(--p-text-disabled)
}

.next-input.next-input--is-focused,
.next-input--is-focused.next-input--stylized,
.next-input:focus,
.next-input--stylized:focus {
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  -webkit-box-shadow: 0 0 0 0.0625rem var(--p-surface), 0 0 0 0.1875rem var(--p-focused);
  box-shadow: 0 0 0 0.0625rem var(--p-surface), 0 0 0 0.1875rem var(--p-focused)
}

.next-input.next-input--is-focused[readonly],
.next-input--is-focused.next-input--stylized[readonly],
.next-input[readonly]:focus,
.next-input--stylized[readonly]:focus {
  -webkit-box-shadow: none;
  box-shadow: none
}

.next-input[readonly],
.next-input--stylized[readonly],
.next-input.next-input--readonly,
.next-input--readonly.next-input--stylized,
.next-input[disabled],
.next-input--stylized[disabled],
.next-input.next-input--is-disabled,
.next-input--is-disabled.next-input--stylized {
  background: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  border-color: var(--p-border-disabled)
}

.next-input-wrapper--is-error .next-input,
.next-input-wrapper--is-error .next-input--stylized {
  border: 1px solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued)
}

.next-input-wrapper--is-error .next-input:focus,
.next-input-wrapper--is-error .next-input--stylized:focus {
  border-color: var(--p-focused);
  -webkit-box-shadow: 0 0 0 1px var(--p-focused);
  box-shadow: 0 0 0 1px var(--p-focused)
}

.next-field__connected-wrapper .next-field--connected,
.next-field__connected-wrapper .btn,
.next-field__connected-wrapper .ui-button {
  z-index: 10
}

.next-field__connected-wrapper :focus,
.next-field__connected-wrapper .next-input--is-focused {
  z-index: 20
}

.next-checkbox--styled {
  position: relative;
  z-index: 1;
  color: var(--p-text);
  position: absolute;
  left: calc(var(--p-control-border-width) / 2);
  top: calc(var(--p-control-border-width) / 2);
  display: block;
  height: calc(var(--p-choice-size) - var(--p-control-border-width));
  width: calc(var(--p-choice-size) - var(--p-control-border-width));
  border: var(--p-control-border-width) solid var(--p-border);
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-base)
}

.next-checkbox--styled::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(var(--p-control-border-width) + 0.0625rem));
  right: calc(-1 * calc(var(--p-control-border-width) + 0.0625rem));
  bottom: calc(-1 * calc(var(--p-control-border-width) + 0.0625rem));
  left: calc(-1 * calc(var(--p-control-border-width) + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(var(--p-control-border-width) + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(var(--p-control-border-width) + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.0625rem)
}

.next-checkbox--styled::before {
  content: '';
  position: absolute;
  top: calc(-1 * var(--p-control-border-width));
  right: calc(-1 * var(--p-control-border-width));
  bottom: calc(-1 * var(--p-control-border-width));
  left: calc(-1 * var(--p-control-border-width));
  border-radius: var(--p-border-radius-base);
  background-color: var(--p-interactive);
  opacity: 0;
  -webkit-transform: scale(0.25);
  transform: scale(0.25);
  -webkit-transition: opacity var(--p-duration-1-0-0) var(--p-ease), -webkit-transform var(--p-duration-1-0-0) var(--p-ease);
  transition: opacity var(--p-duration-1-0-0) var(--p-ease), -webkit-transform var(--p-duration-1-0-0) var(--p-ease);
  transition: opacity var(--p-duration-1-0-0) var(--p-ease), transform var(--p-duration-1-0-0) var(--p-ease);
  transition: opacity var(--p-duration-1-0-0) var(--p-ease), transform var(--p-duration-1-0-0) var(--p-ease), -webkit-transform var(--p-duration-1-0-0) var(--p-ease)
}

@media (-ms-high-contrast: active) {
  .next-checkbox--styled::before {
    background: transparent;
    border: 2px solid;
    -webkit-transition: none;
    transition: none
  }
}

.next-checkbox--styled .next-icon,
.next-checkbox--styled .next-icon__text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  pointer-events: none;
  fill: var(--p-icon-on-interactive);
  -webkit-transform: translate(-50%, -50%) scale(0.25);
  transform: translate(-50%, -50%) scale(0.25);
  opacity: 0;
  -webkit-transition: opacity var(--p-duration-1-0-0) var(--p-ease), -webkit-transform var(--p-duration-1-0-0) var(--p-ease);
  transition: opacity var(--p-duration-1-0-0) var(--p-ease), -webkit-transform var(--p-duration-1-0-0) var(--p-ease);
  transition: opacity var(--p-duration-1-0-0) var(--p-ease), transform var(--p-duration-1-0-0) var(--p-ease);
  transition: opacity var(--p-duration-1-0-0) var(--p-ease), transform var(--p-duration-1-0-0) var(--p-ease), -webkit-transform var(--p-duration-1-0-0) var(--p-ease)
}

.next-checkbox:focus+.next-checkbox--styled::after {
  -webkit-box-shadow: 0 0 0 0.125rem var(--p-focused);
  box-shadow: 0 0 0 0.125rem var(--p-focused)
}

.next-checkbox:indeterminate+.next-checkbox--styled,
.next-checkbox:checked+.next-checkbox--styled {
  border-color: var(--p-interactive)
}

.next-checkbox:indeterminate+.next-checkbox--styled::before,
.next-checkbox:checked+.next-checkbox--styled::before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1)
}

.next-checkbox:indeterminate+.next-checkbox--styled .next-icon,
.next-checkbox:indeterminate+.next-checkbox--styled .next-icon__text,
.next-checkbox:checked+.next-checkbox--styled .next-icon,
.next-checkbox:checked+.next-checkbox--styled .next-icon__text {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1)
}

.next-checkbox:indeterminate+.next-checkbox--styled .checkmark,
.next-checkbox:checked+.next-checkbox--styled .indeterminate {
  opacity: 0
}

.next-checkbox:disabled+.next-checkbox--styled {
  color: var(--p-text-disabled);
  border-color: var(--p-border-disabled);
  background: var(--p-surface-disabled)
}

.next-checkbox:disabled+.next-checkbox--styled .next-icon,
.next-checkbox:disabled+.next-checkbox--styled .next-icon__text,
.next-checkbox:disabled+.next-checkbox--styled:before {
  background: var(--p-border-disabled)
}

.next-input-wrapper--inline .next-radio--styled,
.next-input-wrapper--inline .next-checkbox--styled {
  left: calc(50% - (var(--p-choice-size) / 2) + 0.0625rem);
  top: calc(50% - (var(--p-choice-size) / 2) + 0.0625rem)
}

.next-input-wrapper--is-error .next-checkbox~.next-checkbox--styled {
  border-color: var(--p-border-critical);
  background: var(--p-surface-critical)
}

.next-input-wrapper--is-error .next-checkbox:indeterminate~.next-checkbox--styled::before,
.next-input-wrapper--is-error .next-checkbox:checked~.next-checkbox--styled::before {
  background: var(--p-border-critical)
}

@media (-ms-high-contrast: active) {

  .next-input-wrapper--is-error .next-checkbox:indeterminate~.next-checkbox--styled::before,
  .next-input-wrapper--is-error .next-checkbox:checked~.next-checkbox--styled::before {
    background: transparent
  }
}

.next-radio--styled {
  position: relative;
  z-index: 1;
  border-radius: 100%;
  position: absolute;
  background-color: var(--p-surface);
  border: var(--p-control-border-width) solid var(--p-border);
  left: calc(var(--p-control-border-width) / 2);
  top: calc(var(--p-control-border-width) / 2);
  width: calc(var(--p-choice-size) - var(--p-control-border-width));
  height: calc(var(--p-choice-size) - var(--p-control-border-width))
}

.next-radio--styled::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(var(--p-control-border-width) + 0.0625rem));
  right: calc(-1 * calc(var(--p-control-border-width) + 0.0625rem));
  bottom: calc(-1 * calc(var(--p-control-border-width) + 0.0625rem));
  left: calc(-1 * calc(var(--p-control-border-width) + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(var(--p-control-border-width) + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(var(--p-control-border-width) + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.0625rem)
}

.next-radio--styled:after {
  border-radius: 50%
}

.next-radio--styled::before {
  opacity: 0;
  -webkit-transition: opacity var(--p-duration-1-0-0) var(--p-ease), -webkit-transform var(--p-duration-1-0-0) var(--p-ease);
  transition: opacity var(--p-duration-1-0-0) var(--p-ease), -webkit-transform var(--p-duration-1-0-0) var(--p-ease);
  transition: opacity var(--p-duration-1-0-0) var(--p-ease), transform var(--p-duration-1-0-0) var(--p-ease);
  transition: opacity var(--p-duration-1-0-0) var(--p-ease), transform var(--p-duration-1-0-0) var(--p-ease), -webkit-transform var(--p-duration-1-0-0) var(--p-ease);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  height: var(--p-icon-size);
  width: var(--p-icon-size);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  -webkit-transition: -webkit-transform 0.15s ease-in-out;
  transition: -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out
}

@media (-ms-high-contrast: active) {
  .next-radio--styled::before {
    background: transparent;
    border: 5px solid;
    -webkit-transition: none;
    transition: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
  }
}

.next-radio:focus+.next-radio--styled::after {
  -webkit-box-shadow: 0 0 0 0.125rem var(--p-focused);
  box-shadow: 0 0 0 0.125rem var(--p-focused)
}

.next-radio:checked+.next-radio--styled {
  border-color: var(--p-interactive)
}

.next-radio:checked+.next-radio--styled:before {
  background-color: var(--p-interactive);
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  -webkit-transition: opacity var(--p-duration-1-5-0) var(--p-ease), -webkit-transform var(--p-duration-1-5-0) var(--p-ease);
  transition: opacity var(--p-duration-1-5-0) var(--p-ease), -webkit-transform var(--p-duration-1-5-0) var(--p-ease);
  transition: opacity var(--p-duration-1-5-0) var(--p-ease), transform var(--p-duration-1-5-0) var(--p-ease);
  transition: opacity var(--p-duration-1-5-0) var(--p-ease), transform var(--p-duration-1-5-0) var(--p-ease), -webkit-transform var(--p-duration-1-5-0) var(--p-ease)
}

.next-radio:disabled+.next-radio--styled {
  border-color: var(--p-border-disabled)
}

.next-radio:disabled+.next-radio--styled:before {
  background-color: var(--p-surface)
}

.next-field--connected:not(:last-child),
.next-field__connected-wrapper .btn:not(:last-child),
.next-field__connected-wrapper .ui-button:not(.add-filter):not(:last-child),
.next-field__connected-wrapper input:not(:last-child) {
  margin-right: 0.5rem
}

.next-input--stylized {
  border-color: var(--p-border-neutral-subdued);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.next-input--stylized[disabled],
.next-input--stylized.next-input--is-disabled {
  background-color: var(--p-surface-disabled);
  color: var(--p-text);
  -webkit-box-shadow: none;
  box-shadow: none
}

.next-input--stylized .next-input--invisible {
  border: 0;
  padding: 0
}

.next-input--stylized .next-input--invisible:focus {
  -webkit-box-shadow: none;
  box-shadow: none
}

.next-table--line-items,
.next-table--data {
  width: 100%;
  border-collapse: collapse
}

.next-table--data tr>th {
  font-weight: bold;
  text-align: center;
  padding: 1px;
  border-bottom: none
}

.next-table--data tr>td {
  background-color: transparent;
  padding: 1px;
  border-bottom: none
}

.next-table--data tr>th {
  color: #798c9c;
  text-align: left
}

.next-table--data tr>td {
  border-bottom: 1px solid var(--p-divider)
}

.next-table--fixed-layout {
  table-layout: fixed
}

.next-table--line-items td {
  padding-top: 20px;
  padding-bottom: 20px
}

.next-table--line-items .next-table__image-cell {
  vertical-align: top;
  min-width: 48px
}

.next-table--line-items .next-table__properties-cell {
  padding-top: 5px;
  padding-bottom: 5px
}

.next-table--line-items td:first-of-type,
.next-table--line-items th:first-of-type {
  padding-left: 0
}

.next-table--line-items td:last-of-type,
.next-table--line-items th:last-of-type {
  padding-right: 0
}

@media (max-width: 520px),
(min-width: 769px) and (max-width: 784px) {
  .next-table--line-items {
    display: block
  }

  .next-table--line-items thead {
    display: none
  }

  .next-table--line-items tbody {
    width: 100%;
    display: block
  }

  .next-table--line-items tr {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 0 10px 0
  }

  .next-table--line-items tr+tr {
    border-top: 1px solid var(--p-divider)
  }

  .next-table--line-items tr.selected {
    background: #fffbe9
  }

  .next-table--line-items td {
    padding: 0 10px 10px 0;
    display: block;
    border: none
  }

  .next-table--line-items td:first-of-type {
    padding-left: 0
  }

  .next-table--line-items td:last-of-type {
    padding-right: 0
  }

  .next-table--line-items .next-table__cell--hide-when-condensed {
    display: none
  }

  .next-table--line-items .next-table__cell--full-width-when-condensed {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 100%;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%
  }

  .next-table--line-items .next-table__cell--grow-when-condensed {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: auto
  }

  .next-table--line-items .next-table__cell--no-flex {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
  }

  .next-table--line-items .next-table__cell--top-right-when-condensed {
    position: absolute;
    top: 20px;
    right: 10px
  }

  .next-table--line-items .next-table__cell--item-name {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 calc(100% - 58px);
    -ms-flex: 1 0 calc(100% - 58px);
    flex: 1 0 calc(100% - 58px);
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start
  }

  .next-table--line-items .only-when-printing {
    display: none
  }
}

.next-table--with-price td:last-of-type {
  width: 15%
}

.next-table--compact td {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px
}

.next-table--condensed {
  display: block
}

.next-table--condensed thead {
  display: none
}

.next-table--condensed tbody {
  width: 100%;
  display: block
}

.next-table--condensed tr {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0 10px 0
}

.next-table--condensed tr+tr {
  border-top: 1px solid var(--p-divider)
}

.next-table--condensed tr.selected {
  background: #fffbe9
}

.next-table--condensed td {
  padding: 0 10px 10px 0;
  display: block;
  border: none
}

.next-table--condensed td:first-of-type {
  padding-left: 0
}

.next-table--condensed td:last-of-type {
  padding-right: 0
}

.next-table--condensed .next-table__cell--hide-when-condensed {
  display: none
}

.next-table--condensed .next-table__cell--full-width-when-condensed {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%
}

.next-table--condensed .next-table__cell--grow-when-condensed {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: auto
}

.next-table--condensed .next-table__cell--no-flex {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto
}

.next-table--condensed .next-table__cell--top-right-when-condensed {
  position: absolute;
  top: 20px;
  right: 10px
}

.next-table--condensed .next-table__cell--item-name {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 calc(100% - 58px);
  -ms-flex: 1 0 calc(100% - 58px);
  flex: 1 0 calc(100% - 58px);
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start
}

.next-table--condensed .only-when-printing {
  display: none
}

.next-table--condensed--no-wrap tr {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}

.next-table__inline-heading {
  display: none
}

.next-table--collapsible {
  padding: 20px
}

.next-table--collapsible .next-table__btn--expand {
  display: none
}

.next-table--collapsible.next-table--condensed {
  padding: 0
}

.next-table--collapsible.next-table--condensed tr {
  padding: 0;
  width: 100%
}

.next-table--collapsible.next-table--condensed td {
  padding: 10px 20px
}

.next-table--collapsible.next-table--condensed td:first-of-type {
  padding-left: 20px
}

.next-table--collapsible.next-table--condensed td:last-of-type {
  padding-right: 20px
}

.next-table--collapsible.next-table--condensed .next-table__inline-heading {
  width: 40%;
  display: inline-block;
  color: #798c9c
}

.next-table--collapsible.next-table--condensed .next-table__btn--expand {
  display: block
}

.next-table--collapsible.next-table--condensed .next-table__cell--collapse {
  display: none
}

.next-table--collapsible.next-table--condensed .next-table__cell--visible {
  display: block;
  background: #f5f6f7
}

.next-table__row--no-border td {
  border-bottom: none
}

.next-table__row--align-top td {
  vertical-align: top
}

.next-table--clickable-row tbody tr {
  cursor: pointer
}

.table__row--subdued {
  background-color: #f5f6f7
}

.next-table--ultra-expanded td,
.next-table--ultra-expanded th {
  padding: 20px
}

.next-table__row--extra-padding-top td {
  padding-top: 20px
}

.next-table__row--extra-padding-bottom td {
  padding-bottom: 20px
}

.next-table--no-outside-padding tr>td:first-child,
.next-table--no-outside-padding tr>th:first-child {
  padding-left: 0
}

.next-table--no-outside-padding tr>td:last-child,
.next-table--no-outside-padding tr>th:last-child {
  padding-right: 0
}

.next-table--no-bottom-padding tr:last-child>td {
  padding-bottom: 0
}

.next-table--row-group-no-spacing tr:not(:first-child) td {
  padding-top: 0
}

.next-table--row-group-no-spacing tr:not(:last-child) td {
  padding-bottom: 0
}

.next-table--even-cell-padding td,
.next-table--even-cell-padding th {
  padding: 10px 10px
}

.next-card {
  background-color: var(--p-surface, #fff);
  border-radius: var(--p-border-radius-wide, 3px);
  -webkit-box-shadow: var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
  box-shadow: var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15))
}

.next-card~.next-card,
.next-card~.ui-card {
  margin-top: 20px
}

@media screen and (max-width: 720px) {
  .next-card {
    border-radius: 0
  }
}

@media (max-width: 590px),
(min-width: 769px) and (max-width: 854px) {
  .next-card+.next-card {
    margin-top: 10px
  }
}

.next-card--aside {
  background-color: var(--p-surface-subdued, #f9fafb)
}

.next-card--aside-lighter {
  background-color: var(--p-surface-subdued, #fafbfc)
}

.next-card--plain {
  -webkit-box-shadow: var(--p-card-shadow, none);
  box-shadow: var(--p-card-shadow, none)
}

.next-card--hide-overflow {
  overflow: hidden
}

.next-card--stacked {
  margin: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0
}

.next-card--stacked~.next-card--stacked {
  margin-top: 0;
  border-top: 1px solid var(--p-divider)
}

.next-card--preserve-first-and-last-section-spacing .next-card__section--half-spacing:first-of-type {
  padding-top: 20px
}

.next-card--preserve-first-and-last-section-spacing .next-card__section--half-spacing:last-of-type {
  padding-bottom: 20px
}

.next-card--sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 76px
}

.next-card--js-sticky {
  position: fixed;
  top: 56px;
  margin-top: 20px
}

.next-card__header {
  padding: 20px 20px 0
}

.next-card__header .next-heading {
  margin-bottom: 0
}

.next-card__footer {
  padding: 0 20px 20px
}

.next-card__footer--no-padding {
  padding: 0
}

.next-card__section {
  padding: 20px
}

.next-card__section~.next-card__section {
  border-top: 1px solid var(--p-divider)
}

.next-card__section~.next-card__section.next-card__section--no-border {
  border-top: 0
}

.next-card__section:first-child {
  border-radius: var(--p-border-radius-wide, 3px) var(--p-border-radius-wide, 3px) 0 0
}

.next-card__section:last-child {
  border-radius: 0 0 var(--p-border-radius-wide, 3px) var(--p-border-radius-wide, 3px)
}

.next-card__section--no-vertical-spacing {
  padding-top: 0;
  padding-bottom: 0
}

.next-card__section--no-vertical-spacing+.next-card__section {
  border-top: none
}

.next-card__section--no-horizontal-spacing {
  padding-left: 0;
  padding-right: 0
}

.next-card__section--half-spacing {
  padding-top: 10px;
  padding-bottom: 10px
}

.next-card__section--half-spacing.next-card__header {
  padding-bottom: 0
}

.next-card__section--half-spacing.next-card__footer {
  padding-top: 0
}

.next-card__section--double-spacing {
  padding-top: 40px;
  padding-bottom: 40px
}

.next-card__section--no-padding {
  padding: 0
}

.next-card__section--subdued {
  background-color: var(--p-surface-subdued, #f9fafb)
}

.next-card__section--more-subdued {
  background-color: var(--p-surface-subdued, #ebeef0)
}

.next-card__section--less-subdued {
  background-color: var(--p-surface-subdued, #fafbfc)
}

.next-card__section--accentuated {
  background-color: var(--p-surface-subdued, #006fbb)
}

.next-card__section--accentuated .next-heading {
  color: var(--p-text, #fff)
}

.next-card__section--bordered {
  border-top: 1px solid var(--p-divider)
}

.next-card__section--no-bottom-spacing {
  padding-bottom: 0
}

.next-card__section--no-bottom-spacing+.next-card__section {
  border-top: 0
}

.next-card__section--no-top-spacing {
  padding-top: 0
}

.next-card__section~.next-card__section--no-top-spacing {
  border-top: 0
}

.next-card__section--border-left {
  border-left: 1px solid var(--p-border-subdued)
}

.next-card__section--border-bottom {
  border-bottom: 1px solid var(--p-border-subdued)
}

.next-card__section--centered {
  text-align: center
}

.next-card--with-full-height-image {
  position: relative;
  padding-left: 250px;
  max-width: calc(100% - 250px)
}

.next-card--with-full-height-image .next-card__image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  background-size: cover;
  background-position: center;
  border-top-left-radius: var(--p-border-radius-wide, 3px);
  border-bottom-left-radius: var(--p-border-radius-wide, 3px)
}

@media (max-width: 590px),
(min-width: 769px) and (max-width: 854px) {
  .next-card--with-full-height-image {
    padding-left: 0;
    max-width: 100%
  }

  .next-card--with-full-height-image .next-card__image {
    position: static;
    display: block;
    width: 100%;
    height: 120px;
    border-radius: 0
  }
}

.next-card__section__separator {
  margin: 20px auto;
  background-color: var(--p-border-subdued, #ebeef0)
}

.next-card__section__separator--no-margin {
  margin: 0
}

.next-card__separator {
  margin: 0;
  height: 1px;
  background: var(--p-border-subdued, #ebeef0)
}

.next-card__separator--thick {
  height: 5px
}

.next-card__separator--inline {
  margin: 10px 20px
}

.next-card--top-rounded-corners {
  border-radius: var(--p-border-radius-wide, 3px) var(--p-border-radius-wide, 3px) 0 0 !important
}

.next-card--bottom-rounded-corners {
  border-radius: 0 0 var(--p-border-radius-wide, 3px) var(--p-border-radius-wide, 3px) !important
}

.next-card--all-rounded-corners {
  border-radius: var(--p-border-radius-wide, 3px) !important
}

.next-card--left-rounded-corners {
  border-radius: var(--p-border-radius-wide, 3px) 0 0 var(--p-border-radius-wide, 3px)
}

.next-card--right-rounded-corners {
  border-radius: 0 var(--p-border-radius-wide, 3px) var(--p-border-radius-wide, 3px) 0
}

.next-card--top-right-rounded-corner {
  border-top-right-radius: var(--p-border-radius-wide, 3px)
}

.next-card--bottom-right-rounded-corner {
  border-bottom-right-radius: var(--p-border-radius-wide, 3px)
}

.next-card--top-left-rounded-corner {
  border-top-left-radius: var(--p-border-radius-wide, 3px)
}

.next-card--bottom-left-rounded-corner {
  border-bottom-left-radius: var(--p-border-radius-wide, 3px)
}

.next-card__ribbon {
  background: #fafbfc;
  border-right: solid 1px #ebeef0;
  border-radius: 3px 0 0 3px;
  padding: 20px;
  display: block
}

.next-card__ribbon--warning {
  background: #ffd117;
  border-right: solid 1px rgba(212, 160, 2, 0.25)
}

.next-card__ribbon--vertically-centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center
}

.next-ui .next-text-slate {
  color: #31373d
}

.next-card__header--fully-padded {
  padding-bottom: 20px
}

.next-card--semi-transparent {
  background-color: rgba(255, 255, 255, 0.9)
}

.next-card__channel-image {
  margin-right: 20px
}

@media (max-width: 540px),
(min-width: 769px) and (max-width: 804px) {
  .next-card__channel-image {
    display: none
  }
}

.next-card__channel-illustration {
  margin: 0 auto
}

@media screen and (max-width: 568px) {

  .fresh-ui .next-card__header,
  .fresh-ui .next-card__section,
  .fresh-ui .next-card__footer {
    padding-left: 1rem;
    padding-right: 1rem
  }
}

.next-tab__list {
  padding: 0;
  padding-top: 0.5rem;
  margin: 0;
  list-style: none;
  overflow: visible;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-bottom: 1px solid var(--p-divider)
}

.next-tab__list>li {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

.next-tab__list .next-tab__list__disclosure-item {
  display: none
}

.next-tab__list>li>.next-tab.next-tab--disclosure {
  margin-bottom: 0.5rem;
  border-radius: var(--p-border-radius-base);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.next-tab__list>li:first-child .next-tab {
  border-top-left-radius: 3px;
  margin-left: 0.5rem
}

.next-tab__list .next-tab__body {
  display: block;
  line-height: 20px;
  padding: 0.5rem 1rem;
  margin-bottom: 5px;
  border-radius: var(--p-border-radius-base);
  font-weight: 400;
  position: relative
}

.next-tab__list .next-tab__body::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: calc(-1 * calc(0px + 0.0625rem));
  right: calc(-1 * calc(0px + 0.0625rem));
  bottom: calc(-1 * calc(0px + 0.0625rem));
  left: calc(-1 * calc(0px + 0.0625rem));
  display: block;
  pointer-events: none;
  -webkit-box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  box-shadow: 0 0 0 calc(-1 * calc(0px + 0.0625rem)) var(--p-focused);
  -webkit-transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: -webkit-box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease);
  transition: box-shadow 100ms var(--p-ease), -webkit-box-shadow 100ms var(--p-ease);
  border-radius: calc(var(--p-border-radius-base) + 0.0625rem)
}

.next-tab__list .next-tab:focus:not(:active) .next-tab__body {
  -webkit-box-shadow: none;
  box-shadow: none
}

.next-tab__list .next-tab:focus:not(:active) .next-tab__body::after {
  -webkit-box-shadow: 0 0 0 0.125rem var(--p-focused);
  box-shadow: 0 0 0 0.125rem var(--p-focused)
}

.next-tab__list--full::after {
  display: none
}

.next-tab__list--full>li {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.next-tab__list--full .next-tab__list__disclosure-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0
}

.next-tab__list--fitted::after {
  display: none
}

.next-tab__list--fitted>li {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0%;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%
}

.next-tab__list--fitted>li>.next-tab {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center
}

.next-tab__list--fitted .next-tab__list__disclosure-item {
  display: none
}

.next-tab__list--vertical {
  display: block;
  padding: 0;
  margin: 0
}

.next-tab__list--vertical .next-tab {
  text-align: left
}

.next-tab__list--vertical .next-tab:hover,
.next-tab__list--vertical .next-tab:focus {
  background: transparent;
  color: var(--p-text)
}

.next-tab__list--vertical .next-tab .next-tab__body {
  margin: 0
}

.next-tab__list--page-navigation {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 62.375rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem
}

@media (max-width: 769px) and (min-width: 490px),
(min-width: 730px) {
  .next-tab__list--page-navigation {
    padding-right: 1.25rem;
    padding-left: 1.25rem
  }
}

@media (max-width: 769px) and (min-width: 744px),
(min-width: 984px) {
  .next-tab__list--page-navigation {
    padding-right: 2rem;
    padding-left: 2rem
  }
}

.next-tab {
  color: var(--p-text-subdued)
}

.next-tab:focus,
.next-tab:hover {
  color: var(--p-text);
  outline: none;
  text-decoration: none
}

.next-tab__list>li>.next-tab {
  margin-right: 0.5rem;
  display: block
}

.next-tab__list>li>.next-tab:focus .next-tab__border,
.next-tab__list>li>.next-tab:hover .next-tab__border {
  background-color: var(--p-border-hovered)
}

.next-tab__list>li>.next-tab:active .next-tab__border {
  background-color: var(--p-surface-primary-selected-pressed)
}

.next-tab__list>li>.next-tab .next-tab__border {
  display: block;
  border-radius: var(--p-border-radius-base) var(--p-border-radius-base) 0px 0px;
  height: 3px;
  width: 100%
}

.next-tab__list>li>.next-tab.next-tab--is-active .next-tab__body {
  color: var(--p-text)
}

.next-tab__list>li>.next-tab.next-tab--is-active .next-tab__border {
  background-color: var(--p-action-primary)
}

.next-tab--disclosure {
  padding: 0 20px
}

.next-tab--disclosure .helper-hidden {
  display: none
}

.next-tab--disclosure .next-tab {
  display: inline-block
}

.next-tab__panel {
  display: none
}

.next-tab__panel.next-tab__panel--is-active {
  display: block
}

.next-tab__panel>*:first-child:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none
}

.next-tab__border {
  display: none
}

.next-list {
  list-style-type: none;
  margin: 0;
  padding: 0
}

.next-list>li {
  width: 100%
}

.next-list>li .next-list__item {
  padding-right: 20px;
  padding-left: 20px
}

.next-list>li button.next-list__item {
  border: 0;
  cursor: pointer;
  color: #006fbb;
  text-decoration: none;
  vertical-align: initial;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  width: 100%
}

.next-list>li button.next-list__item:hover,
.next-list>li button.next-list__item:focus,
.next-list>li button.next-list__item:active {
  -webkit-box-shadow: none;
  box-shadow: none
}

.next-list>li .next-list__item--indented {
  padding-left: 40px
}

.next-list--divided {
  list-style-type: none;
  margin: 0;
  padding: 0
}

.next-list--divided>li {
  width: 100%;
  border-bottom: 1px solid var(--p-divider);
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.next-list--divided>li a,
.next-list--divided>li button,
.next-list--divided>li .next-list__item {
  display: block;
  color: #31373d;
  padding-top: 10px;
  padding-bottom: 10px
}

.next-list--divided>li .next-list__item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer
}

.next-list--divided>li .next-list__item:hover,
.next-list--divided>li .next-list__item.is-selected {
  background: #ebf5fa;
  -webkit-box-shadow: 0 1px 0 #ebeef0;
  box-shadow: 0 1px 0 #ebeef0
}

.next-list--divided>li .next-list__item--disabled,
.next-list--divided>li .next-list__item--no-hover {
  cursor: default
}

.next-list--divided>li .next-list__item--disabled:hover,
.next-list--divided>li .next-list__item--no-hover:hover {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none
}

.next-list--divided>li a:hover,
.next-list--divided>li a.is-selected,
.next-list--divided>li button:hover,
.next-list--divided>li button.is-selected {
  background: #ebf5fa;
  text-decoration: none
}

.next-list--divided>li a:focus,
.next-list--divided>li a:active,
.next-list--divided>li button:focus,
.next-list--divided>li button:active {
  background: #ebf5fa;
  outline: none
}

.next-list--divided>li a.disabled,
.next-list--divided>li button.disabled {
  color: #c3cfd8
}

.next-list--divided-selectable {
  list-style-type: none;
  margin: 0;
  padding: 0
}

.next-list--divided-selectable li+li {
  border-top: 1px solid var(--p-divider)
}

.next-list--divided-selectable>li {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.next-list--divided-selectable>li a,
.next-list--divided-selectable>li button,
.next-list--divided-selectable>li .next-list__item {
  display: block;
  color: #31373d;
  padding-top: 10px;
  padding-bottom: 10px
}

.next-list--divided-selectable>li a:hover,
.next-list--divided-selectable>li a.is-selected,
.next-list--divided-selectable>li button:hover,
.next-list--divided-selectable>li button.is-selected,
.next-list--divided-selectable>li .next-list__item:hover,
.next-list--divided-selectable>li .next-list__item.is-selected {
  background: #006fbb;
  text-decoration: none;
  color: #ffffff
}

.next-list--divided--top>li {
  border-bottom: 0
}

.next-list--divided--top>li:not(:first-child) .next-list__item {
  border-top: 1px solid var(--p-divider)
}

.next-list--divided--top>li:not(:first-child) .next-list__item.next-list__item--indented {
  border-top: 0
}

.next-list--divided-half-padding>li a,
.next-list--divided-half-padding>li button,
.next-list--divided-half-padding>li .next-list__item {
  padding: 10px
}

.next-list__item--subdued,
.next-list--divided-subdued {
  background-color: #f5f6f7
}

.next-list>li .next-list__item--vertically-condensed {
  padding-top: 5px;
  padding-bottom: 5px
}

.next-list--subdued .next-list__item {
  background: #f5f6f7;
  color: var(--p-text-subdued)
}

.next-list--subdued .next-list__item.next-list__item--is-current {
  background: #ffffff;
  font-weight: bold
}

.next-list--subdued .next-list__item:focus,
.next-list--subdued .next-list__item:hover {
  background: #fafbfc
}

.next-list--compact {
  margin: 5px 0
}

.next-list--compact .next-list__item {
  background: transparent;
  border: 0;
  display: block;
  font-size: 14px;
  padding: 5px 10px;
  text-align: left;
  text-decoration: none;
  color: #31373d;
  word-wrap: break-word;
  border-radius: 0;
  line-height: 24px;
  white-space: normal;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.next-list--compact .next-list__item.next-list__item--is-applied {
  background: #ebf5fa;
  color: #798c9c
}

.next-list--compact .next-list__item:hover,
.next-list--compact .next-list__item:focus,
.next-list--compact .next-list__item.next-list__item--is-selected {
  background: #006fbb;
  color: #ffffff;
  text-decoration: none;
  outline: none
}

.next-list--compact .next-list__item:hover .subdued,
.next-list--compact .next-list__item:focus .subdued,
.next-list--compact .next-list__item.next-list__item--is-selected .subdued {
  color: #d3dbe2
}

.next-list--compact .next-list__item:hover .next-heading,
.next-list--compact .next-list__item:hover small,
.next-list--compact .next-list__item:focus .next-heading,
.next-list--compact .next-list__item:focus small,
.next-list--compact .next-list__item.next-list__item--is-selected .next-heading,
.next-list--compact .next-list__item.next-list__item--is-selected small {
  color: #ffffff
}

.next-list--compact .next-list__item:active {
  color: #ffffff;
  background: #084e8a
}

.next-list--compact .next-list__item.is-disabled {
  color: #c3cfd8;
  pointer-events: none;
  cursor: default
}

.next-list--compact button.next-list__item {
  width: 100%;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif
}

.next-list--compact+.next-list--compact {
  border-top: 1px solid var(--p-divider);
  padding-top: 5px
}

.next-list--toggles .next-icon,
.next-list--toggles .next-icon__text {
  visibility: hidden
}

.next-list--toggles .next-list__item--is-applied .next-icon,
.next-list--toggles .next-list__item--is-applied .next-icon__text {
  visibility: visible
}

.next-list--toggles .next-list__item--promoted-action,
.next-list--divided>li .next-list__item--promoted-action {
  color: #006fbb
}

.next-list__item--promoted-action .next-icon,
.next-list__item--promoted-action .next-icon__text {
  fill: #006fbb;
  visibility: visible
}

.next-list__item--is-applied .next-icon,
.next-list__item--is-applied .next-icon__text {
  fill: #798c9c
}

.next-list--toggles .next-list__item:focus .next-icon,
.next-list--toggles .next-list__item:focus .next-icon__text,
.next-list--toggles .next-list__item:hover .next-icon,
.next-list--toggles .next-list__item:hover .next-icon__text,
.next-list--toggles .next-list__item:active .next-icon,
.next-list--toggles .next-list__item:active .next-icon__text,
.next-list--toggles .next-list__item.next-list__item--is-selected .next-icon,
.next-list--toggles .next-list__item.next-list__item--is-selected .next-icon__text {
  fill: #ffffff
}

.next-list--plain-divided>li {
  border-bottom: 1px solid var(--p-divider);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px 0
}

.next-list--plain-divided>li:last-child {
  border-bottom: none
}

.next-list--plain-divided .next-card--aside>li {
  border-color: rgba(211, 219, 226, 0.5)
}

.next-list--row-hover>li {
  padding: 5px 10px
}

.next-list--row-hover>li:hover {
  background-color: #ebeef0
}

.next-list--row-hover.next-list--no-vertical-padding>li {
  padding: 0 10px
}

.next-list--heading {
  margin: 10px 0;
  border: none
}

.next-list>li .resource-browser-variant,
.next-list>li .resource-browser-variant.next-list__item--disabled:hover,
.next-list>li .resource-browser-variant.next-list__item--no-hover:hover {
  margin-left: 46px;
  padding-left: 0
}

.next-list>li .resource-browser-variant:hover,
.next-list>li .resource-browser-variant.is-selected {
  margin-left: 0;
  padding-left: 46px
}

.next-list>li .resource-browser-variant--review-step {
  margin-left: 20px;
  padding-left: 40px
}

.next-list[data-searchable-select-results] .searchable-select-group-divider {
  border-bottom: 1px solid var(--p-border-subdued)
}

.fresh-ui .next-list>li a,
.fresh-ui .next-list>li button,
.fresh-ui .next-list>li .next-list__item,
.fresh-ui .next-list>li.next-list__item,
.fresh-ui .next-list--compact>li a,
.fresh-ui .next-list--compact>li button,
.fresh-ui .next-list--compact>li .next-list__item,
.fresh-ui .next-list--compact>li.next-list__item {
  background: white;
  color: #212b36;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  transition: none
}

.fresh-ui .next-list>li a:focus,
.fresh-ui .next-list>li a:hover,
.fresh-ui .next-list>li a:active,
.fresh-ui .next-list>li a.is-selected,
.fresh-ui .next-list>li a.next-list__item--is-selected,
.fresh-ui .next-list>li button:focus,
.fresh-ui .next-list>li button:hover,
.fresh-ui .next-list>li button:active,
.fresh-ui .next-list>li button.is-selected,
.fresh-ui .next-list>li button.next-list__item--is-selected,
.fresh-ui .next-list>li .next-list__item:focus,
.fresh-ui .next-list>li .next-list__item:hover,
.fresh-ui .next-list>li .next-list__item:active,
.fresh-ui .next-list>li .next-list__item.is-selected,
.fresh-ui .next-list>li .next-list__item.next-list__item--is-selected,
.fresh-ui .next-list>li.next-list__item:focus,
.fresh-ui .next-list>li.next-list__item:hover,
.fresh-ui .next-list>li.next-list__item:active,
.fresh-ui .next-list>li.next-list__item.is-selected,
.fresh-ui .next-list>li.next-list__item.next-list__item--is-selected,
.fresh-ui .next-list--compact>li a:focus,
.fresh-ui .next-list--compact>li a:hover,
.fresh-ui .next-list--compact>li a:active,
.fresh-ui .next-list--compact>li a.is-selected,
.fresh-ui .next-list--compact>li a.next-list__item--is-selected,
.fresh-ui .next-list--compact>li button:focus,
.fresh-ui .next-list--compact>li button:hover,
.fresh-ui .next-list--compact>li button:active,
.fresh-ui .next-list--compact>li button.is-selected,
.fresh-ui .next-list--compact>li button.next-list__item--is-selected,
.fresh-ui .next-list--compact>li .next-list__item:focus,
.fresh-ui .next-list--compact>li .next-list__item:hover,
.fresh-ui .next-list--compact>li .next-list__item:active,
.fresh-ui .next-list--compact>li .next-list__item.is-selected,
.fresh-ui .next-list--compact>li .next-list__item.next-list__item--is-selected,
.fresh-ui .next-list--compact>li.next-list__item:focus,
.fresh-ui .next-list--compact>li.next-list__item:hover,
.fresh-ui .next-list--compact>li.next-list__item:active,
.fresh-ui .next-list--compact>li.next-list__item.is-selected,
.fresh-ui .next-list--compact>li.next-list__item.next-list__item--is-selected {
  background: var(--p-surface-selected-hovered, #5c6ac4);
  color: var(--p-text, white)
}

.fresh-ui .next-list>li a:focus .type--subdued,
.fresh-ui .next-list>li a:hover .type--subdued,
.fresh-ui .next-list>li a:active .type--subdued,
.fresh-ui .next-list>li a.is-selected .type--subdued,
.fresh-ui .next-list>li a.next-list__item--is-selected .type--subdued,
.fresh-ui .next-list>li button:focus .type--subdued,
.fresh-ui .next-list>li button:hover .type--subdued,
.fresh-ui .next-list>li button:active .type--subdued,
.fresh-ui .next-list>li button.is-selected .type--subdued,
.fresh-ui .next-list>li button.next-list__item--is-selected .type--subdued,
.fresh-ui .next-list>li .next-list__item:focus .type--subdued,
.fresh-ui .next-list>li .next-list__item:hover .type--subdued,
.fresh-ui .next-list>li .next-list__item:active .type--subdued,
.fresh-ui .next-list>li .next-list__item.is-selected .type--subdued,
.fresh-ui .next-list>li .next-list__item.next-list__item--is-selected .type--subdued,
.fresh-ui .next-list>li.next-list__item:focus .type--subdued,
.fresh-ui .next-list>li.next-list__item:hover .type--subdued,
.fresh-ui .next-list>li.next-list__item:active .type--subdued,
.fresh-ui .next-list>li.next-list__item.is-selected .type--subdued,
.fresh-ui .next-list>li.next-list__item.next-list__item--is-selected .type--subdued,
.fresh-ui .next-list--compact>li a:focus .type--subdued,
.fresh-ui .next-list--compact>li a:hover .type--subdued,
.fresh-ui .next-list--compact>li a:active .type--subdued,
.fresh-ui .next-list--compact>li a.is-selected .type--subdued,
.fresh-ui .next-list--compact>li a.next-list__item--is-selected .type--subdued,
.fresh-ui .next-list--compact>li button:focus .type--subdued,
.fresh-ui .next-list--compact>li button:hover .type--subdued,
.fresh-ui .next-list--compact>li button:active .type--subdued,
.fresh-ui .next-list--compact>li button.is-selected .type--subdued,
.fresh-ui .next-list--compact>li button.next-list__item--is-selected .type--subdued,
.fresh-ui .next-list--compact>li .next-list__item:focus .type--subdued,
.fresh-ui .next-list--compact>li .next-list__item:hover .type--subdued,
.fresh-ui .next-list--compact>li .next-list__item:active .type--subdued,
.fresh-ui .next-list--compact>li .next-list__item.is-selected .type--subdued,
.fresh-ui .next-list--compact>li .next-list__item.next-list__item--is-selected .type--subdued,
.fresh-ui .next-list--compact>li.next-list__item:focus .type--subdued,
.fresh-ui .next-list--compact>li.next-list__item:hover .type--subdued,
.fresh-ui .next-list--compact>li.next-list__item:active .type--subdued,
.fresh-ui .next-list--compact>li.next-list__item.is-selected .type--subdued,
.fresh-ui .next-list--compact>li.next-list__item.next-list__item--is-selected .type--subdued {
  color: var(--p-text-subdued, white)
}

.fresh-ui .next-list>li a.is-disabled,
.fresh-ui .next-list>li button.is-disabled,
.fresh-ui .next-list>li .next-list__item.is-disabled,
.fresh-ui .next-list>li.next-list__item.is-disabled,
.fresh-ui .next-list--compact>li a.is-disabled,
.fresh-ui .next-list--compact>li button.is-disabled,
.fresh-ui .next-list--compact>li .next-list__item.is-disabled,
.fresh-ui .next-list--compact>li.next-list__item.is-disabled {
  color: var(--p-text-disabled, #c4cdd5)
}

.fresh-ui .next-list__item--is-applied .next-icon,
.fresh-ui .next-list__item--is-applied .next-icon__text {
  fill: #212b36
}

.fresh-ui .next-list--toggles .next-list__item:focus .next-icon,
.fresh-ui .next-list--toggles .next-list__item:focus .next-icon__text,
.fresh-ui .next-list--toggles .next-list__item:hover .next-icon,
.fresh-ui .next-list--toggles .next-list__item:hover .next-icon__text,
.fresh-ui .next-list--toggles .next-list__item:active .next-icon,
.fresh-ui .next-list--toggles .next-list__item:active .next-icon__text,
.fresh-ui .next-list--toggles .next-list__item.next-list__item--is-selected .next-icon,
.fresh-ui .next-list--toggles .next-list__item.next-list__item--is-selected .next-icon__text {
  fill: white
}

.fresh-ui .next-list--divided>li a:hover,
.fresh-ui .next-list--divided>li a.is-selected {
  background: var(--p-surface-selected-hovered, #5c6ac4);
  color: var(--p-text, white)
}

.fresh-ui .next-list--divided>li a:hover .next-icon--color-sky-darker,
.fresh-ui .next-list--divided>li a:hover .next-icon--slate-lighter,
.fresh-ui .next-list--divided>li a.is-selected .next-icon--color-sky-darker,
.fresh-ui .next-list--divided>li a.is-selected .next-icon--slate-lighter {
  color: transparent;
  fill: var(--p-text, white)
}

.fresh-ui .product_browser .next-list--divided>li .next-list__item:hover,
.fresh-ui .product_browser .next-list--divided>li .next-list__item.is-selected {
  background: #f9fafb;
  color: inherit
}

.fresh-ui .product_browser .next-list--divided>li .next-list__item:hover .next-icon--color-sky-darker,
.fresh-ui .product_browser .next-list--divided>li .next-list__item:hover .next-icon--slate-lighter,
.fresh-ui .product_browser .next-list--divided>li .next-list__item.is-selected .next-icon--color-sky-darker,
.fresh-ui .product_browser .next-list--divided>li .next-list__item.is-selected .next-icon--slate-lighter {
  color: transparent;
  fill: #c4cdd5
}

.fresh-ui .product_browser .next-list--divided>li .next-list__item:hover .type--subdued,
.fresh-ui .product_browser .next-list--divided>li .next-list__item.is-selected .type--subdued {
  color: #637381
}

.fresh-ui.page-product-variants-show .variant-list .next-list__item--is-current,
.fresh-ui.page-product-variants-show .variant-list .next-list__item--is-current:hover,
.fresh-ui.page-product-variants-show .variant-list .next-list__item--is-current:focus {
  background: var(--p-surface-selected-hovered, #5c6ac4);
  color: var(--p-text, white);
  font-weight: 500
}

.resource-browser__info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.resource-browser__title {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0%;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%
}

@media screen and (max-width: 450px) {
  .resource-browser__info {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }

  .resource-browser__title {
    width: 100%;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
  }
}

.next-token,
.tagit-choice,
.tagit-new .ui-autocomplete-input {
  border-radius: 3px
}

.next-token-list {
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none
}

.next-token-list--is-input {
  padding: 5px 10px;
  border-radius: var(--p-border-radius-base);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: initial;
  letter-spacing: initial;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  width: 100%;
  background: #ffffff;
  cursor: text;
  padding: 3px;
  padding-bottom: 0
}

@media screen and (min-width: 640px) {
  .next-token-list--is-input {
    font-size: 0.875rem
  }
}

.next-token-list--is-input::-webkit-input-placeholder {
  color: var(--p-text-disabled)
}

.next-token-list--is-input::-moz-placeholder {
  color: var(--p-text-disabled)
}

.next-token-list--is-input:-ms-input-placeholder {
  color: var(--p-text-disabled)
}

.next-token-list--is-input::-ms-input-placeholder {
  color: var(--p-text-disabled)
}

.next-token-list--is-input::placeholder {
  color: var(--p-text-disabled)
}

.next-label--is-focused .next-token-list--is-input {
  border-color: #006fbb
}

.next-token-list__wrapper {
  position: relative
}

.next-token-list--is-input-invisible {
  border: none;
  padding: 2px 0 0 0
}

.next-token-list--large-tokens {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: initial;
  letter-spacing: initial
}

@media screen and (min-width: 640px) {
  .next-token-list--large-tokens {
    font-size: 0.875rem
  }
}

.tagit-new {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto
}

.tagit-new .ui-autocomplete-input {
  padding: 4px 8px;
  border: 0;
  height: auto;
  width: 120px;
  background: transparent;
  margin-bottom: 3px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: initial;
  letter-spacing: initial
}

@media screen and (min-width: 640px) {
  .tagit-new .ui-autocomplete-input {
    font-size: 0.875rem
  }
}

.tagit-new:only-child .ui-autocomplete-input {
  width: 100%
}

.next-token-list--is-input .next-token,
.next-token-list--is-input .tagit-choice {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-transform: initial;
  letter-spacing: initial
}

@media screen and (min-width: 640px) {

  .next-token-list--is-input .next-token,
  .next-token-list--is-input .tagit-choice {
    font-size: 0.875rem
  }
}

.next-token,
.tagit-choice {
  background-color: #ebf5fa;
  color: #001429;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  word-wrap: break-word;
  max-width: 100%
}

.next-token-list .next-token,
.next-token-list .tagit-choice {
  margin-right: 3px;
  margin-bottom: 3px
}

.next-token-list--expanded {
  margin-bottom: -6px
}

.next-token-list--expanded .next-token {
  margin-right: 6px;
  margin-bottom: 6px
}

.next-token__label,
.tagit-label {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  min-width: 0;
  width: 100%;
  display: block;
  padding: 4px 8px
}

.next-token__remove,
.tagit-close {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: block;
  padding: 4px 8px 4px 0
}

.next-token__remove .text-icon,
.tagit-close .text-icon {
  display: none
}

.next-token__remove .next-token__remove__icon,
.tagit-close .next-token__remove__icon {
  width: 9px;
  height: 9px
}

.next-token__remove .ui-icon-close,
.tagit-close .ui-icon-close {
  display: inline-block;
  background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2032%2032%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,ellipse,line,path,polygon,polyline,rect,text%7Bfill:rgb(49,%2055,%2061)%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M18.263%2016l10.07-10.07c.625-.625.625-1.636%200-2.26s-1.638-.627-2.263%200L16%2013.737%205.933%203.667c-.626-.624-1.637-.624-2.262%200s-.624%201.64%200%202.264L13.74%2016%203.67%2026.07c-.626.625-.626%201.636%200%202.26.312.313.722.47%201.13.47s.82-.157%201.132-.47l10.07-10.068%2010.068%2010.07c.312.31.722.468%201.13.468s.82-.157%201.132-.47c.626-.625.626-1.636%200-2.26L18.262%2016z%22/%3E%3C/svg%3E");
  background-size: 9px 9px;
  width: 9px;
  height: 9px
}

.next-token--clickable {
  cursor: pointer
}

.next-token--is-disabled {
  opacity: 0.5;
  cursor: default
}

.next-token--is-inactive {
  background: #ebeef0;
  color: #798c9c
}

.next-token--is-inactive.next-token--is-disabled {
  cursor: pointer
}

.next-token-list--sortable {
  display: block;
  overflow: hidden
}

.next-token-list--sortable .next-token {
  float: left;
  display: block;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none
}

.next-token-list--sortable .next-token--is-draggable {
  padding-left: 23px;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2020%2020%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,ellipse,line,path,polygon,polyline,rect,text%7Bfill:rgb(255,%20255,%20255)%20!important;%20%7D%3C/style%3E%3Ctitle%3EDrag-Handle%3C/title%3E%3Cpath%20d=%22M7%202c-1.104%200-2%20.896-2%202s.896%202%202%202%202-.896%202-2-.896-2-2-2zm0%206c-1.104%200-2%20.896-2%202s.896%202%202%202%202-.896%202-2-.896-2-2-2zm0%206c-1.104%200-2%20.896-2%202s.896%202%202%202%202-.896%202-2-.896-2-2-2zm6-8c1.104%200%202-.896%202-2s-.896-2-2-2-2%20.896-2%202%20.896%202%202%202zm0%202c-1.104%200-2%20.896-2%202s.896%202%202%202%202-.896%202-2-.896-2-2-2zm0%206c-1.104%200-2%20.896-2%202s.896%202%202%202%202-.896%202-2-.896-2-2-2z%22/%3E%3C/svg%3E");
  background-position: 8px 50%;
  background-size: 15px 15px;
  background-repeat: no-repeat
}

.fresh-ui .next-token__list .next-token,
.fresh-ui .next-token__list .tagit-choice {
  margin-right: 0.25rem;
  margin-bottom: 0.25rem
}

.fresh-ui .next-token,
.fresh-ui .tagit-choice {
  background-color: #dfe3e8;
  color: #454f5b;
  min-height: 1.75rem
}

.fresh-ui .next-token__label,
.fresh-ui .tagit-label {
  padding: 0.25rem 0.5rem
}

.fresh-ui .next-token__remove,
.fresh-ui .tagit-close {
  padding: 0.25rem 0.5rem 0.25rem 0
}

.fresh-ui .next-token__remove .next-token__remove__icon,
.fresh-ui .tagit-close .next-token__remove__icon {
  color: #798c9c
}

.fresh-ui .next-token__remove .next-icon,
.fresh-ui .next-token__remove .next-icon__text,
.fresh-ui .tagit-close .next-icon,
.fresh-ui .tagit-close .next-icon__text {
  fill: #798c9c
}

.fresh-ui .next-token--is-inactive {
  background: #f4f6f8;
  color: #637381
}

.fresh-ui .next-label--is-focused .next-token-list--is-input {
  border: 1px solid #5c6ac4;
  -webkit-box-shadow: 0 0 0 1px #5c6ac4;
  box-shadow: 0 0 0 1px #5c6ac4
}

.next-notice {
  color: #31373d;
  position: relative;
  padding: 20px;
  background: #d3dbe2;
  border-color: #c3cfd8;
  border-bottom-style: solid;
  border-bottom-width: 1px
}

.next-card .next-notice,
.modal .next-notice,
.ui-modal .next-notice {
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 20px
}

.next-notice a {
  color: #31373d;
  text-decoration: underline
}

.next-notice--info {
  background: #ebf5fa;
  border-color: #accbd8
}

.next-notice--attention {
  background: #fff7b2;
  border-color: #ffe477
}

.next-notice--critical {
  background: #ff9797;
  border-color: #ff5d5d
}

.next-notice--no-bottom-margin {
  margin-bottom: 0
}

.next-card .next-notice--no-bottom-margin,
.modal .next-notice--no-bottom-margin {
  margin-bottom: 0
}

.scroll-shadow--bottom {
  -webkit-box-shadow: inset 0px -20px 20px -20px var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1)), inset 0 0 0 0 var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1));
  box-shadow: inset 0px -20px 20px -20px var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1)), inset 0 0 0 0 var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1))
}

.scroll-shadow--top {
  -webkit-box-shadow: inset 0 0 0 0 var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1)), inset 0px 20px 20px -20px var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1));
  box-shadow: inset 0 0 0 0 var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1)), inset 0px 20px 20px -20px var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1))
}

.scroll-shadow--top-and-bottom {
  -webkit-box-shadow: inset 0px -20px 20px -20px var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1)), inset 0px 20px 20px -20px var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1));
  box-shadow: inset 0px -20px 20px -20px var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1)), inset 0px 20px 20px -20px var(--p-hint-from-direct-light, rgba(0, 0, 0, 0.1))
}

[data-lock-scrolling] {
  overflow-y: scroll;
  margin: 0
}

[data-lock-scrolling] [data-lock-scrolling-wrapper] {
  overflow: hidden;
  height: 100%
}

.ui-modal {
  max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  width: 620px;
  -webkit-box-shadow: var(--p-modal-shadow);
  box-shadow: var(--p-modal-shadow);
  border-radius: var(--p-border-radius-wide);
  background: var(--p-surface);
  visibility: hidden;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: translate3d(0, 200px, 0);
  transform: translate3d(0, 200px, 0);
  -webkit-transition: all ease-in-out 260ms;
  transition: all ease-in-out 260ms;
  opacity: 0;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center
}

@media screen and (prefers-reduced-motion: reduce) {
  .ui-modal {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

.ui-modal:focus {
  outline: 0
}

.ui-modal form {
  min-height: 0;
  max-height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

@media screen and (max-width: 450px) {
  .ui-modal {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    max-width: 100%;
    max-height: calc(100% - 20px);
    border-radius: 0;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    opacity: 1
  }
}

@media screen and (max-width: 450px) and (prefers-reduced-motion: reduce) {
  .ui-modal {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@media screen and (max-width: 450px) {
  .ui-modal.ui-modal--is-visible {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

.ui-modal--is-transitioning {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  visibility: visible
}

.ui-modal--is-visible {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  overflow: auto
}

.ui-modal--instant {
  -webkit-transition: none;
  transition: none
}

.ui-modal__header {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid var(--p-border-subdued)
}

.ui-modal__header-title {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.ui-modal__header-actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: -4.5px -15px 0 0;
  padding-left: 10px
}

.ui-modal__body {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: height ease-in-out 260ms, -webkit-box-shadow 260ms ease-in-out 0s;
  transition: height ease-in-out 260ms, -webkit-box-shadow 260ms ease-in-out 0s;
  transition: box-shadow 260ms ease-in-out 0s, height ease-in-out 260ms;
  transition: box-shadow 260ms ease-in-out 0s, height ease-in-out 260ms, -webkit-box-shadow 260ms ease-in-out 0s
}

.ui-modal__body--contains-iframe {
  position: relative
}

.ui-modal__footer {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 20px;
  border-top: 1px solid var(--p-divider)
}

.ui-modal__footer-actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: -10px;
  margin-left: -10px
}

.ui-modal__secondary-actions {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  margin-top: 10px;
  margin-left: 10px
}

.ui-modal__tertiary-actions {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-top: 10px;
  margin-left: 10px
}

.ui-modal__primary-actions {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  margin-top: 10px;
  margin-left: 10px
}

.ui-modal__section {
  padding: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto
}

.ui-modal__section:not(:last-child) {
  border-bottom: 1px solid var(--p-border-subdued)
}

.ui-modal__section--contains-iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0
}

.ui-modal__section--subdued {
  background: var(--p-surface-subdued)
}

.ui-modal__section--no-padding {
  padding: 0
}

.ui-modal__section--footer-text {
  padding-left: 0
}

.ui-modal-backdrop {
  height: 100vh;
  width: 100vw;
  background: var(--p-backdrop);
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 550
}

.ui-modal-backdrop--is-visible {
  display: block
}

.ui-modal-contents {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 551
}

.ui-modal-contents--is-visible {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center
}

.fresh-ui .ui-modal {
  border-radius: var(--p-border-radius-wide);
  -webkit-box-shadow: 0 31px 41px 0 rgba(32, 42, 53, 0.2), 0 2px 16px 0 rgba(32, 42, 54, 0.08);
  box-shadow: 0 31px 41px 0 rgba(32, 42, 53, 0.2), 0 2px 16px 0 rgba(32, 42, 54, 0.08);
  max-height: calc(100% - (1.25rem + 3.5rem));
  max-width: calc(100% - 1.25rem);
  width: 640px;
  margin-top: 3.5rem
}

@media screen and (max-width: 450px) {
  .fresh-ui .ui-modal {
    border-radius: 0;
    max-height: calc(100% - 3.5rem);
    max-width: 100%
  }
}

.fresh-ui .ui-modal__header {
  padding: 1rem 1.25rem;
  border-bottom: 1px solid var(--p-divider)
}

.fresh-ui .ui-modal__header-title {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center
}

.fresh-ui .ui-modal__header-actions {
  margin-top: 0;
  padding-left: 0.5rem
}

.fresh-ui .ui-modal__footer {
  border-top: 1px solid var(--p-divider);
  padding: 1rem 1.25rem
}

.fresh-ui .ui-modal__section {
  padding: 1.25rem
}

.fresh-ui .ui-modal__section:not(:last-child) {
  border-bottom: 1px solid var(--p-divider)
}

.fresh-ui .ui-modal__section--no-padding {
  padding: 0
}

.fresh-ui .ui-modal__section--contains-iframe {
  padding: 0
}

.fresh-ui .ui-modal__section--subdued {
  background-color: var(--p-surface-subdued)
}

.fresh-ui .ui-modal-backdrop {
  background: rgba(32, 42, 53, 0.4)
}

.ui-slider {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative
}

.ui-slide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%
}

.ui-slide--is-hidden {
  opacity: 0;
  visibility: hidden
}

.ui-slide--is-active {
  opacity: 1;
  visibility: visible
}

.ui-slide__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%
}

.ui-slider--horizontal .ui-slide {
  -webkit-transition: opacity 500ms ease-in-out, -webkit-transform 400ms ease-in-out;
  transition: opacity 500ms ease-in-out, -webkit-transform 400ms ease-in-out;
  transition: transform 400ms ease-in-out, opacity 500ms ease-in-out;
  transition: transform 400ms ease-in-out, opacity 500ms ease-in-out, -webkit-transform 400ms ease-in-out
}

.ui-slider--horizontal .ui-slide--is-active {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1
}

.ui-slider--horizontal .ui-slide--is-after {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  opacity: 0
}

.ui-slider--horizontal .ui-slide--is-before {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  opacity: 0
}

.ui-slide__modal-title {
  margin-left: 10px;
  margin-right: 10px
}

.ui-slider .ui-modal__header,
.ui-slider .ui-modal__footer {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0
}

.ui-slider .ui-modal__header-title,
.ui-slider .ui-modal__header,
.ui-slider .ui-modal__footer {
  opacity: 0;
  -webkit-transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
  transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
  transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
  transition: transform 400ms ease-in-out, opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out
}

.ui-slide--is-active .ui-modal__header-title,
.ui-slide--is-active .ui-modal__header,
.ui-slide--is-active .ui-modal__footer {
  opacity: 1
}

.ui-slider .ui-modal__body {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
  transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
  transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
  transition: transform 400ms ease-in-out, opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out
}

.ui-slide--is-active .ui-modal__body {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
  transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
  transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
  transition: transform 400ms ease-in-out, opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out
}

.ui-slide--is-after .ui-modal__body {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0)
}

.ui-slide--is-before .ui-modal__body {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0)
}

.ui-choice-list {
  margin: 0 0 20px 0;
  padding: 0
}

.ui-choice-list:last-child {
  margin-bottom: 0
}

.ui-choice-list li {
  position: relative;
  list-style: none;
  margin-bottom: 10px
}

.ui-choice-list li:last-child {
  margin-bottom: 0
}

.ui-choice-list input[type='radio'],
.ui-choice-list input[type='checkbox'] {
  position: absolute;
  left: 0
}

.ui-choice-list__title {
  margin-bottom: 10px;
  font-weight: 400
}

.ui-choice-list--sub-choice-list {
  padding-top: 5px;
  padding-left: 20px
}

.ui-choice-list--sub-choice-list>ul>li {
  margin-bottom: 5px
}

.ui-form__section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -8px
}

.ui-form__section .ui-form__section {
  margin: 0;
  width: 100%
}

.ui-form__section+.ui-form__section {
  margin-top: 8px
}

.ui-form__section .next-input-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 8px;
  max-width: 100%
}

.ui-form__section .next-input-wrapper.ui-form__element--horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.ui-form__section .next-input-wrapper.ui-form__element--centered {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.ui-form__section .next-radio,
.ui-form__section .next-radio--styled,
.ui-form__section .next-radio[type='radio'],
.ui-form__section .next-checkbox,
.ui-form__section .next-checkbox--styled,
.ui-form__section .next-checkbox[type='checkbox'] {
  top: 10px;
  left: 8px
}

.ui-form__section--full-width {
  width: 100%
}

.ui-form__group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.ui-form__group>.ui-form__element,
.ui-form__group>.next-input-wrapper {
  max-width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 220px;
  -ms-flex: 1 0 220px;
  flex: 1 0 220px;
  min-width: 220px
}

.ui-form__group--cell-size-auto>.ui-form__element,
.ui-form__group--cell-size-auto>.next-input-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  min-width: auto
}

.ui-form__group--cell-size-small>.ui-form__element,
.ui-form__group--cell-size-small>.next-input-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 110px;
  -ms-flex: 1 0 110px;
  flex: 1 0 110px;
  min-width: 110px
}

.ui-form__label-wrapper {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 -4px
}

.ui-form__label-wrapper>* {
  margin: 0 4px 4px 4px
}

.ui-form__label-wrapper.ui-form__element--horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.ui-form__element {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 220px;
  padding: 8px
}

.ui-form__element.ui-form__section--help-text {
  padding-top: 0
}

.ui-form__element.ui-form__section--label {
  padding-bottom: 0
}

.ui-form__element .next-input-wrapper {
  padding: 0
}

.ui-form__element .next-radio,
.ui-form__element .next-radio--styled,
.ui-form__element .next-radio[type='radio'],
.ui-form__element .next-checkbox,
.ui-form__element .next-checkbox--styled,
.ui-form__element .next-checkbox[type='checkbox'] {
  top: 0.0625rem;
  left: 0.0625rem
}

.ui-form__element.ui-form__element--bottom-spacing-half {
  padding-bottom: 4px
}

.ui-form__element.ui-form__element--top-spacing-half {
  padding-top: 4px
}

.ui-form__element.ui-form__element--bottom-spacing-double {
  padding-bottom: 16px
}

.ui-form__element.ui-form__element--top-spacing-double {
  padding-top: 16px
}

.ui-data-table {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.ui-data-table * {
  -webkit-box-sizing: inherit;
  box-sizing: inherit
}

.ui-data-table__wrapper {
  position: relative;
  overflow: hidden
}

.ui-data-table__cell {
  padding: 1rem;
  border-bottom: 1px solid var(--p-divider);
  white-space: normal;
  word-wrap: break-word
}

.ui-data-table__cell img {
  max-width: inherit
}

.ui-data-table__row--expand-last .ui-data-table__cell:last-child {
  width: 100%
}

.ui-data-table__cell--header {
  background: var(--p-override-transparent)
}

.ui-data-table__header .ui-data-table__cell--header {
  padding: 1rem;
  border-bottom: 1px solid var(--p-divider)
}

.ui-data-table__body .ui-data-table__cell--header {
  border-right: 1px solid var(--p-divider)
}

.ui-data-table__cell:first-of-type,
.ui-data-table__cell--header:first-of-type {
  padding-left: 1rem
}

.ui-data-table__cell:last-of-type,
.ui-data-table__cell--header:last-of-type {
  padding-right: 1rem
}

.ui-data-table__cell--alignment-left {
  text-align: left
}

.ui-data-table__cell--alignment-right {
  text-align: right
}

.ui-data-table__cell--single-line {
  white-space: nowrap
}

.ui-data-table__cell--single-line .btn--link {
  white-space: nowrap
}

.ui-data-table--scroll .ui-data-table__row:nth-child(even) {
  background: var(--p-override-transparent)
}

.ui-data-table--scroll .ui-data-table__row:nth-child(odd) {
  background: var(--p-override-transparent)
}

.ui-data-table__footer .ui-data-table__cell {
  border-top: 1px solid var(--p-divider);
  background: var(--p-surface-subdued);
  color: var(--p-text);
  font-weight: bold;
  font-size: 14px
}

.ui-data-table__scroll-wrapper {
  -webkit-overflow-scrolling: touch
}

.ui-data-table__fixed-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none
}

.ui-data-table--fixed {
  opacity: 0
}

.ui-data-table--fixed .ui-data-table__cell:not(:first-child) {
  visibility: hidden
}

.ui-data-table__wrapper--is-collapsed .ui-data-table__cell:first-child {
  min-width: 145px;
  max-width: 145px
}

@media screen and (min-width: 1024px) {
  .ui-data-table__wrapper--is-collapsed .ui-data-table__cell:first-child {
    width: auto;
    max-width: 100%
  }
}

.ui-data-table__wrapper--is-collapsed .ui-data-table__cell--header {
  padding-top: 3.5rem
}

.ui-data-table__wrapper--is-collapsed .ui-data-table__cell--header:first-child .btn,
.ui-data-table__wrapper--is-collapsed .ui-data-table__cell--header:first-child .btn--link {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%
}

.ui-data-table__wrapper--is-collapsed .ui-data-table--fixed {
  opacity: 1
}

.ui-data-table__wrapper--is-collapsed .ui-data-table--fixed .ui-data-table__cell:first-child {
  pointer-events: all;
  -webkit-box-shadow: 2px 0 4px -1px var(--p-shadow-from-direct-light, rgba(0, 0, 0, 0.1));
  box-shadow: 2px 0 4px -1px var(--p-shadow-from-direct-light, rgba(0, 0, 0, 0.1))
}

.ui-data-table__wrapper--is-collapsed .ui-data-table--fixed .ui-data-table__row:nth-child(even) .ui-data-table__cell {
  background: var(--p-override-transparent)
}

.ui-data-table__wrapper--is-collapsed .ui-data-table--fixed .ui-data-table__row:nth-child(odd) .ui-data-table__cell {
  background: var(--p-override-transparent)
}

.ui-data-table__wrapper--is-collapsed .ui-data-table--fixed .ui-data-table__footer .ui-data-table__row:nth-child(odd) .ui-data-table__cell {
  background: var(--p-surface-subdued)
}

.ui-data-table__wrapper--is-collapsed .ui-data-table__scroll-wrapper {
  overflow-x: scroll;
  margin-left: 145px
}

.ui-data-table__wrapper--is-collapsed .ui-data-table--scroll {
  margin-left: -145px;
  width: calc(100% + 145px)
}

.ui-data-table__wrapper--is-collapsed .ui-data-table--scroll .ui-data-table__cell:first-child {
  color: transparent
}

.ui-data-table__navigation {
  position: absolute;
  top: 8px;
  right: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1
}

.ui-data-table__wrapper--is-hiding-navigation .ui-data-table__navigation {
  display: none
}

.ui-data-table__navigation-pip {
  text-indent: -9999px;
  background: var(--p-text);
  width: 6px;
  height: 6px;
  border-radius: var(--p-border-radius-base);
  border: 0;
  padding: 0;
  margin-right: 5px
}

.ui-data-table__navigation-button--is-not-visible {
  background: var(--p-text-subdued)
}

.ui-data-table__next-button {
  margin-left: -5px;
  margin-right: -15px
}

.ui-breadcrumbs__item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.ui-breadcrumbs__separator {
  margin: 0 0.25rem
}

.ui-breadcrumbs {
  color: var(--p-text);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap
}

.ui-breadcrumbs a {
  color: inherit
}

.ui-breadcrumbs .ui-breadcrumb {
  color: var(--p-icon);
  margin-left: 0;
  padding: 0.5rem
}

.ui-breadcrumbs .next-icon,
.ui-breadcrumbs .next-icon__text {
  display: inline-block
}

.ui-breadcrumbs .next-icon:hover svg,
.ui-breadcrumbs .next-icon__text:hover svg,
.ui-breadcrumbs .next-icon:active svg,
.ui-breadcrumbs .next-icon__text:active svg {
  fill: var(--p-icon-pressed)
}

.ui-breadcrumbs .ui-breadcrumb__item {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important
}

.body:hover .ui-breadcrumbs .ui-breadcrumb__item a,
.body:hover .ui-breadcrumbs .ui-breadcrumb__item input,
.body:hover .ui-breadcrumbs .ui-breadcrumb__item button {
  display: none !important
}

.ui-breadcrumbs--is-collapsed {
  cursor: pointer
}

.ui-breadcrumbs--is-collapsed .ui-breadcrumb__link {
  pointer-events: none
}

.ui-breadcrumb__link:hover {
  color: var(--p-interactive-hover)
}

.ui-breadcrumb-ellipsis {
  border: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  background: transparent
}

.ui-breadcrumb__separator,
.ui-breadcrumb-ellipsis {
  color: var(--p-icon);
  margin: 0 0.25rem
}

.ui-breadcrumb--is-hidden,
.ui-breadcrumb-ellipsis--is-hidden {
  display: none
}

.collapsible-header {
  position: fixed;
  top: 56px;
  left: 0;
  z-index: 196;
  padding: 1rem;
  background: #f4f6f8;
  width: 100%;
  -webkit-box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-duration: 195ms;
  transition-duration: 195ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out
}

@media screen and (min-width: 769px) {
  .collapsible-header {
    left: 240px
  }
}

@media screen and (max-width: 769px) {
  .collapsible-header {
    width: 100%
  }
}

.collapsible-header .ui-breadcrumb {
  display: inline
}

.collapsible-header .ui-breadcrumb__link:hover {
  color: var(--p-interactive)
}

.collapsible-header .collapsible-header__heading {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem
}

@media (min-width: 640px) {
  .collapsible-header .collapsible-header__heading {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.collapsible-header--is-visible {
  opacity: 1;
  pointer-events: all;
  -webkit-transform: translateY(0);
  transform: translateY(0)
}

.ui-breadcrumbs__ellipsis {
  display: none
}

.ui-title-bar {
  display: grid;
  gap: 0.5rem 0.25rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 'breadcrumbs action-bar actions' 'heading heading heading' 'meta-data meta-data meta-data'
}

@media screen and (min-width: 480px) {
  .ui-title-bar {
    gap: 0.25rem;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    grid-template-columns: auto 1fr auto auto auto;
    grid-template-rows: 1fr auto;
    grid-template-areas: 'breadcrumbs heading action-bar actions pagination' 'meta-data meta-data meta-data meta-data meta-data'
  }
}

@media screen and (min-width: 768px) {
  .ui-title-bar {
    grid-template-columns: auto 1fr auto auto auto
  }
}

.ui-title-bar__title {
  margin-right: 0.5rem;
  font-weight: 600
}

@media screen and (min-width: 768px) {
  .ui-title-bar__title {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.ui-action-list-action {
  width: 100%
}

.ui-title-bar-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 1.25rem;
  margin: 1rem auto 0 auto;
  max-width: 62.375rem
}

@media screen and (min-width: 768px) {
  .ui-title-bar-container {
    padding: 0 2rem
  }
}

.ui-title-bar-container--full-width {
  max-width: 100%
}

.ui-title-bar-container--single-column {
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
  max-width: 41.375rem
}

@media screen and (min-width: 768px) {
  .ui-title-bar-container--single-column {
    padding: 0 2rem
  }
}

.ui-title-bar-container--no-actions .ui-title-bar {
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'breadcrumbs heading pagination'
}

.ui-title-bar-container--no-actions.ui-title-bar-container--no-breadcrumbs .ui-title-bar {
  grid-template-columns: 1fr auto;
  grid-template-areas: 'heading pagination'
}

.ui-title-bar-container--no-breadcrumbs .ui-title-bar {
  grid-template-columns: 1fr 1fr auto;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'heading action-bar actions' 'meta-data meta-data meta-data'
}

@media screen and (min-width: 768px) {
  .ui-title-bar-container--no-breadcrumbs .ui-title-bar {
    grid-template-columns: 1fr 1fr auto auto;
    grid-template-areas: 'heading action-bar actions pagination' 'meta-data meta-data meta-data meta-data'
  }
}

.ui-title-bar-container--no-breadcrumbs .ui-title-bar .ui-title-bar__metadata {
  margin-left: 0
}

.ui-breadcrumbs {
  grid-area: breadcrumbs;
  margin-right: 0.75rem
}

.ui-title-bar__heading-group {
  grid-area: heading;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  align-self: center
}

.ui-title-bar__heading-group .ui-title-bar__metadata {
  display: none
}

.ui-title-bar__pagination {
  display: none
}

@media screen and (min-width: 768px) {
  .ui-title-bar__pagination {
    display: block;
    grid-area: pagination
  }
}

.action-bar {
  grid-area: action-bar;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 0;
  width: 100%
}

.action-bar .ui-button:not(.ui-button--primary) {
  color: var(--p-text) !important;
  background: var(--p-background) !important;
  border: 1px solid var(--p-border-neutral-subdued) !important;
  -webkit-transition: none !important;
  transition: none !important
}

.action-bar .ui-button:not(.ui-button--primary):hover {
  background-color: var(--p-background-hovered) !important;
  text-decoration: none
}

.action-bar .ui-button:not(.ui-button--primary):active {
  background-color: var(--p-background-pressed) !important
}

.action-bar .ui-button:not(.ui-button--primary):focus:not(:active) {
  background: var(--p-background) !important
}

.action-bar .ui-button:not(.ui-button--primary)::after {
  background: none;
  -webkit-animation: none;
  animation: none;
  width: unset;
  height: unset
}

.action-bar .ui-button:not(.ui-button--primary):disabled,
.action-bar .ui-button[disabled='disabled']:not(.ui-button--primary) {
  color: var(--p-text-disabled) !important;
  background-color: transparent !important
}

@media screen and (min-width: 1024px) {
  .action-bar .ui-button:not(.ui-button--primary) {
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
  }
}

.action-bar .ui-button:not(.ui-button--primary):disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: var(--p-border-disabled);
  background: var(--p-surface-disabled);
  color: var(--p-text-disabled)
}

.action-bar .ui-title-bar__action.ui-button--primary .action-bar__more-label {
  white-space: nowrap
}

.action-bar .action-bar__link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.action-bar .ui-title-bar__icon,
.action-bar .action-bar__link-icon {
  display: none
}

.ui-title-bar__metadata {
  color: var(--p-text-subdued);
  grid-area: meta-data
}

@media screen and (min-width: 480px) {
  .ui-title-bar__metadata {
    margin-left: 3.375rem
  }
}

.ui-title-bar__actions.ui-title-bar__actions--secondary {
  grid-area: action-bar;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-right: 0.25rem
}

.ui-title-bar__actions {
  display: none;
  grid-area: actions;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

@media screen and (min-width: 768px) {
  .ui-title-bar__actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
  }
}

.action-bar__top-links>.ui-button,
.action-bar__more--has-many .ui-popover__container>.ui-button {
  color: var(--p-text) !important;
  background: var(--p-background) !important;
  border: 1px solid var(--p-border-neutral-subdued) !important;
  -webkit-transition: none !important;
  transition: none !important
}

.action-bar__top-links>.ui-button:hover,
.action-bar__more--has-many .ui-popover__container>.ui-button:hover {
  background-color: var(--p-background-hovered) !important;
  text-decoration: none
}

.action-bar__top-links>.ui-button:active,
.action-bar__more--has-many .ui-popover__container>.ui-button:active {
  background-color: var(--p-background-pressed) !important
}

.action-bar__top-links>.ui-button:focus:not(:active),
.action-bar__more--has-many .ui-popover__container>.ui-button:focus:not(:active) {
  background: var(--p-background) !important
}

.action-bar__top-links>.ui-button::after,
.action-bar__more--has-many .ui-popover__container>.ui-button::after {
  background: none;
  -webkit-animation: none;
  animation: none;
  width: unset;
  height: unset
}

.action-bar__top-links>.ui-button:disabled,
.action-bar__top-links>.ui-button[disabled='disabled'],
.action-bar__more--has-many .ui-popover__container>.ui-button:disabled,
.action-bar__more--has-many .ui-popover__container>.ui-button[disabled='disabled'] {
  color: var(--p-text-disabled) !important;
  background-color: transparent !important
}

@media screen and (min-width: 1024px) {

  .action-bar__top-links>.ui-button,
  .action-bar__more--has-many .ui-popover__container>.ui-button {
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
  }
}

.ui-title-bar__pagination>.ui-button {
  color: var(--p-text) !important;
  background: var(--p-background) !important;
  border: 1px solid var(--p-border-neutral-subdued) !important;
  -webkit-transition: none !important;
  transition: none !important
}

.ui-title-bar__pagination>.ui-button:hover {
  background-color: var(--p-background-hovered) !important;
  text-decoration: none
}

.ui-title-bar__pagination>.ui-button:active {
  background-color: var(--p-background-pressed) !important
}

.ui-title-bar__pagination>.ui-button:focus:not(:active) {
  background: var(--p-background) !important
}

.ui-title-bar__pagination>.ui-button::after {
  background: none;
  -webkit-animation: none;
  animation: none;
  width: unset;
  height: unset
}

.ui-title-bar__pagination>.ui-button:disabled,
.ui-title-bar__pagination>.ui-button[disabled='disabled'] {
  color: var(--p-text-disabled) !important;
  background-color: transparent !important
}

.action-bar__more-button--large-screen {
  display: none !important
}

@media screen and (min-width: 768px) {
  .action-bar__more-button--large-screen {
    display: block !important;
    background: var(--p-action-secondary);
    border: none
  }
}

.action-bar__more-button--small-screen {
  display: block
}

@media screen and (min-width: 768px) {
  .action-bar__more-button--small-screen {
    display: none
  }
}

.ui-title-bar__badge {
  display: block;
  margin: 0
}

.ui-title-bar__badge .badge {
  margin: 0
}

.action-bar__item--link-container {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.action-bar__item--link-container .ui-button--transparent {
  white-space: nowrap
}

.ui-title-bar__mobile-primary-actions {
  padding-left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.ui-title-bar__mobile-primary-actions .ui-title-bar__actions {
  display: block
}

@media screen and (min-width: 768px) {
  .ui-title-bar__mobile-primary-actions .ui-title-bar__actions {
    display: none
  }
}

.ui-title-bar__actions--secondary {
  display: none
}

@media screen and (min-width: 450px) {
  .ui-title-bar__actions--secondary {
    display: block
  }

  .ui-title-bar__actions--secondary+.ui-title-bar__actions--popover {
    display: none
  }
}

.ui-title-bar__action+.ui-title-bar__action {
  margin-left: 10px
}

.ui-title-bar__action.ui-button--primary {
  margin-left: 0.25rem
}

.ui-title-bar__actions-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.action-bar--page-width {
  max-width: 62.375rem
}

.action-bar__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto
}

.action-bar__top-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.action-bar__top-links>.ui-button {
  padding: 0.5rem
}

.action-bar__top-links>.ui-button:not(:last-child) {
  margin-right: 0.25rem
}

.action-bar__more {
  margin-left: 0.25rem
}

.action-bar__popover-icon-wrapper {
  display: inline-block;
  width: 20px;
  margin-right: 0.5rem;
  text-align: center;
  vertical-align: middle;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto
}

.action-bar__popover-icon {
  position: relative;
  top: -0.15em;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto
}

.action-bar__popover-icon--image {
  border-radius: 3px
}

.action-bar__popover-wrapper {
  max-width: 400px;
  list-style: none;
  white-space: normal
}

.action-bar__popover-wrapper .ui-action-list-action__text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.action-bar__popover-hidden-item,
.action-bar__popover-empty-item {
  display: none
}

.action-bar__popover-label {
  padding: 0.5rem 1rem 0
}

[data-popover-index='0']:first-child .action-bar__popover-label {
  display: none
}

.action-bar__popover-hidden-item+[data-popover-index='0'] .action-bar__popover-label,
.action-bar__popover-empty-item+[data-popover-index='0'] .action-bar__popover-label {
  display: none
}

:not([data-popover-index])+[data-popover-index='0'] .action-bar__popover-label {
  display: none
}

.action-bar__label--has-new-feature::before {
  content: '';
  position: absolute;
  right: -0.25rem;
  top: -0.25rem;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 100%;
  background-color: #47c1bf
}

.action-bar__label--has-new-feature::before {
  z-index: 1;
  -webkit-animation: bounce 5s ease infinite;
  animation: bounce 5s ease infinite
}

.action-bar__label--has-new-feature--mobile::before,
.action-bar__label--has-new-feature--mobile::after {
  visibility: hidden
}

@media all and (max-width: 500px) {

  .action-bar__label--has-new-feature--mobile::before,
  .action-bar__label--has-new-feature--mobile::after {
    visibility: visible
  }
}

.action-bar__new-label {
  font-size: 0.6875rem;
  color: white;
  padding: 0 0.5rem;
  background: #47c1bf;
  display: inline-block;
  border-radius: 0.625rem;
  margin-left: 1rem;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start
}

.action-bar__item--visually-hidden {
  visibility: hidden
}

@-webkit-keyframes bounce {

  from,
  65%,
  85% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  75% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85)
  }

  82.5% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }
}

@keyframes bounce {

  from,
  65%,
  85% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  75% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85)
  }

  82.5% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }
}

@-webkit-keyframes pulse {

  from,
  75% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    opacity: 1
  }

  to {
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
    opacity: 0
  }
}

@keyframes pulse {

  from,
  75% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    opacity: 1
  }

  to {
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
    opacity: 0
  }
}

.icon-symbols {
  display: none !important
}

.next-icon,
.next-icon__text {
  display: inline-block;
  vertical-align: middle;
  fill: currentColor
}

.next-icon--left-spacing-halved {
  margin-left: 10px
}

.next-icon--right-spacing-halved {
  margin-right: 10px
}

.next-icon--left-spacing-quartered {
  margin-left: 5px
}

.next-icon--right-spacing-quartered {
  margin-right: 5px
}

.next-icon--color-white {
  color: transparent;
  fill: var(--p-icon-subdued, #fff)
}

.next-icon--color-ink {
  color: transparent;
  fill: var(--p-icon, #212b36)
}

.next-icon--color-ink-light {
  color: transparent;
  fill: var(--p-icon, #454f5b)
}

.next-icon--color-ink-lighter {
  color: transparent;
  fill: var(--p-icon, #637381)
}

.next-icon--color-ink-lightest {
  color: transparent;
  fill: var(--p-icon, #919eab)
}

.next-icon--color-sky {
  color: transparent;
  fill: var(--p-icon-subdued, #ebeef0)
}

.next-icon--color-sky-light {
  color: transparent;
  fill: var(--p-icon-subdued, #f5f6f7)
}

.next-icon--color-sky-dark {
  color: transparent;
  fill: var(--p-icon-subdued, #d3dbe2)
}

.next-icon--color-sky-darker {
  color: transparent;
  fill: var(--p-icon-subdued, #c3cfd8)
}

.next-icon--color-slate-lightest {
  color: transparent;
  fill: var(--p-icon, #95a7b7)
}

.next-icon--color-slate-lighter {
  color: transparent;
  fill: var(--p-icon, #798c9c)
}

.next-icon--color-slate-light {
  color: transparent;
  fill: var(--p-icon, #454e57)
}

.next-icon--color-slate {
  color: transparent;
  fill: var(--p-icon, #31373d)
}

.next-icon--color-slate-darker {
  color: transparent;
  fill: var(--p-icon, #212529)
}

.next-icon--color-blue-lighter {
  color: transparent;
  fill: var(--p-icon, #ebf5fa)
}

.next-icon--color-blue-light {
  color: transparent;
  fill: var(--p-icon, #b4e1fa)
}

.next-icon--color-blue {
  color: transparent;
  fill: var(--p-icon, #006fbb)
}

.next-icon--color-green {
  color: transparent;
  fill: var(--p-icon-success, #96bf48)
}

.next-icon--color-green-dark {
  color: transparent;
  fill: var(--p-icon-success, #7ba232)
}

.next-icon--color-yellow {
  color: transparent;
  fill: var(--p-icon-warning, #ffd117)
}

.next-icon--color-yellow-dark {
  color: transparent;
  fill: var(--p-icon-warning, #d4a002)
}

.next-icon--color-orange-light {
  color: transparent;
  fill: var(--p-icon-warning, #fec97d)
}

.next-icon--color-orange {
  color: transparent;
  fill: var(--p-icon-warning, #ff9517)
}

.next-icon--color-red {
  color: transparent;
  fill: var(--p-icon-critical, #ff5d5d)
}

.next-icon--color-red-dark {
  color: transparent;
  fill: var(--p-icon-critical, #d83e3e)
}

.next-icon--color-teal {
  color: transparent;
  fill: var(--p-icon-highlight, #47c1bf)
}

.fresh-ui .next-icon--color-white {
  color: transparent;
  fill: var(--p-icon-subdued, white)
}

.fresh-ui .next-icon--color-sky {
  color: transparent;
  fill: var(--p-icon-subdued, #dfe3e8)
}

.fresh-ui .next-icon--color-sky-light {
  color: transparent;
  fill: var(--p-icon-subdued, #f4f6f8)
}

.fresh-ui .next-icon--color-sky-dark {
  color: transparent;
  fill: var(--p-icon-subdued, #c4cdd5)
}

.fresh-ui .next-icon--color-blue-lighter {
  color: transparent;
  fill: var(--p-icon, #ebf5fa)
}

.fresh-ui .next-icon--color-blue-light {
  color: transparent;
  fill: var(--p-icon, #b4e1fa)
}

.fresh-ui .next-icon--color-blue {
  color: transparent;
  fill: var(--p-icon, #006fbb)
}

.fresh-ui .next-icon--color-green {
  color: transparent;
  fill: var(--p-icon-success, #50b83c)
}

.fresh-ui .next-icon--color-green-dark {
  color: transparent;
  fill: var(--p-icon-success, #108043)
}

.fresh-ui .next-icon--color-yellow {
  color: transparent;
  fill: var(--p-icon-warning, #eec200)
}

.fresh-ui .next-icon--color-yellow-dark {
  color: transparent;
  fill: var(--p-icon-warning, #8a6116)
}

.fresh-ui .next-icon--color-orange-light {
  color: transparent;
  fill: var(--p-icon-warning, #ffc58b)
}

.fresh-ui .next-icon--color-orange {
  color: transparent;
  fill: var(--p-icon-warning, #f49342)
}

.fresh-ui .next-icon--color-red {
  color: transparent;
  fill: var(--p-icon-critical, #de3618)
}

.fresh-ui .next-icon--color-red-dark {
  color: transparent;
  fill: var(--p-icon-critical, #bf0711)
}

.fresh-ui .next-icon--color-ink {
  color: transparent;
  fill: var(--p-icon, #212b36)
}

.fresh-ui .next-icon--color-ink-light {
  color: transparent;
  fill: var(--p-icon, #454f5b)
}

.fresh-ui .next-icon--color-ink-lighter {
  color: transparent;
  fill: var(--p-icon, #637381)
}

.fresh-ui .next-icon--color-ink-lightest {
  color: transparent;
  fill: var(--p-icon, #919eab)
}

.fresh-ui .next-icon--color-teal {
  color: transparent;
  fill: var(--p-icon-highlight, #47c1bf)
}

.fresh-ui .next-icon--color-base {
  color: transparent;
  fill: var(--p-icon)
}

.fresh-ui .next-icon--color-subdued {
  color: transparent;
  fill: var(--p-icon-subdued)
}

.fresh-ui .next-icon--color-critical {
  color: transparent;
  fill: var(--p-icon-critical)
}

.fresh-ui .next-icon--color-warning {
  color: transparent;
  fill: var(--p-icon-warning)
}

.fresh-ui .next-icon--color-highlight {
  color: transparent;
  fill: var(--p-icon-highlight)
}

.fresh-ui .next-icon--color-success {
  color: transparent;
  fill: var(--p-icon-success)
}

.fresh-ui .next-icon--color-primary {
  color: transparent;
  fill: var(--p-action-primary)
}

.next-icon--size-8 {
  width: 8px;
  height: 8px
}

.next-icon--size-10 {
  width: 10px;
  height: 10px
}

.next-icon--size-12 {
  width: 12px;
  height: 12px
}

.next-icon--size-16 {
  width: 16px;
  height: 16px
}

.next-icon--size-20 {
  width: 20px;
  height: 20px
}

.next-icon--size-24 {
  width: 24px;
  height: 24px
}

.next-icon--size-40 {
  width: 40px;
  height: 40px
}

.next-icon--size-80 {
  width: 80px;
  height: 80px
}

.next-icon--size-140 {
  width: 140px;
  height: 140px
}

.next-icon--rotate-45 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.next-icon--rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.next-icon--rotate-135 {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg)
}

.next-icon--rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.next-icon--rotate-225 {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg)
}

.next-icon--rotate-270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg)
}

.next-icon,
.next-icon__text {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  top: -0.15em
}

.next-icon--header {
  margin-left: 10px;
  margin-right: 5px;
  fill: #798c9c
}

.next-icon--sidebar {
  margin-right: 15px;
  vertical-align: top
}

.is-disabled .next-icon--rte {
  opacity: 0.5
}

.next-icon__text {
  margin-left: 5px;
  text-decoration: inherit
}

.next-icon__with-text-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.next-icon__with-text-wrapper--halved .next-icon+*,
.next-icon__with-text-wrapper--halved .next-icon__text+*,
.next-icon__with-text-wrapper--halved *+.next-icon,
.next-icon__with-text-wrapper--halved *+.next-icon__text {
  margin-left: 10px
}

.next-icon__with-text-wrapper--quartered .next-icon+*,
.next-icon__with-text-wrapper--quartered .next-icon__text+*,
.next-icon__with-text-wrapper--quartered *+.next-icon,
.next-icon__with-text-wrapper--quartered *+.next-icon__text {
  margin-left: 5px
}

.next-icon--flip-horizontal {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1)
}

.next-icon--flip-vertical {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1)
}

.next-icon--white {
  color: transparent;
  fill: var(--p-icon-subdued, #fff)
}

.next-icon--ink {
  color: transparent;
  fill: var(--p-icon, #212b36)
}

.next-icon--ink-light {
  color: transparent;
  fill: var(--p-icon, #454f5b)
}

.next-icon--ink-lighter {
  color: transparent;
  fill: var(--p-icon, #637381)
}

.next-icon--ink-lightest {
  color: transparent;
  fill: var(--p-icon, #919eab)
}

.next-icon--sky {
  color: transparent;
  fill: var(--p-icon-subdued, #ebeef0)
}

.next-icon--sky-light {
  color: transparent;
  fill: var(--p-icon-subdued, #f5f6f7)
}

.next-icon--sky-dark {
  color: transparent;
  fill: var(--p-icon-subdued, #d3dbe2)
}

.next-icon--sky-darker {
  color: transparent;
  fill: var(--p-icon-subdued, #c3cfd8)
}

.next-icon--slate-lightest {
  color: transparent;
  fill: var(--p-icon, #95a7b7)
}

.next-icon--slate-lighter {
  color: transparent;
  fill: var(--p-icon, #798c9c)
}

.next-icon--slate-light {
  color: transparent;
  fill: var(--p-icon, #454e57)
}

.next-icon--slate {
  color: transparent;
  fill: var(--p-icon, #31373d)
}

.next-icon--slate-darker {
  color: transparent;
  fill: var(--p-icon, #212529)
}

.next-icon--blue-lighter {
  color: transparent;
  fill: var(--p-icon, #ebf5fa)
}

.next-icon--blue-light {
  color: transparent;
  fill: var(--p-icon, #b4e1fa)
}

.next-icon--blue {
  color: transparent;
  fill: var(--p-icon, #006fbb)
}

.next-icon--green {
  color: transparent;
  fill: var(--p-icon-success, #96bf48)
}

.next-icon--green-dark {
  color: transparent;
  fill: var(--p-icon-success, #7ba232)
}

.next-icon--yellow {
  color: transparent;
  fill: var(--p-icon-warning, #ffd117)
}

.next-icon--yellow-dark {
  color: transparent;
  fill: var(--p-icon-warning, #d4a002)
}

.next-icon--orange-light {
  color: transparent;
  fill: var(--p-icon-warning, #fec97d)
}

.next-icon--orange {
  color: transparent;
  fill: var(--p-icon-warning, #ff9517)
}

.next-icon--red {
  color: transparent;
  fill: var(--p-icon-critical, #ff5d5d)
}

.next-icon--red-dark {
  color: transparent;
  fill: var(--p-icon-critical, #d83e3e)
}

.next-icon--teal {
  color: transparent;
  fill: var(--p-icon-highlight, #47c1bf)
}

.next-icon--inline-before {
  margin-right: 0.5em
}

.next-icon--inline-after {
  margin-left: 0.5em
}

.next-icon--no-nudge {
  top: 0
}

.next-icon--baseline {
  vertical-align: baseline
}

.frame .next-icon,
.frame .next-icon__text {
  top: 0
}

.next-icon--8 {
  width: 8px;
  height: 8px
}

.next-icon--10 {
  width: 10px;
  height: 10px
}

.next-icon--12 {
  width: 12px;
  height: 12px
}

.next-icon--16 {
  width: 16px;
  height: 16px
}

.next-icon--20 {
  width: 20px;
  height: 20px
}

.next-icon--24 {
  width: 24px;
  height: 24px
}

.next-icon--40 {
  width: 40px;
  height: 40px
}

.next-icon--80 {
  width: 80px;
  height: 80px
}

.next-icon--140 {
  width: 140px;
  height: 140px
}

.next-icon--inline {
  padding-left: 0.125em;
  margin-bottom: -0.125em;
  height: 1em;
  width: 1em
}

.email-typo-input+.next-input__help-text {
  margin-top: 0
}

.typo-suggestion {
  display: none;
  margin-top: 0.25rem
}

.typo-suggestion .ui-button--link:focus {
  outline-style: auto;
  outline-width: 5px
}

.typo-suggestion--visible {
  display: block
}

.typo-suggestion__suggestion--highlight {
  font-weight: bold
}

.hide-on-typo-suggestion--hide {
  display: none
}

.type-ahead-wrapper {
  position: relative
}

.type-ahead-wrapper .type-ahead-hint {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 0
}

.type-ahead-wrapper .type-ahead-hint .next-label {
  opacity: 0
}

.type-ahead-wrapper .type-ahead-hint .type-ahead-hint-field {
  background: white;
  color: transparent;
  -webkit-transition: none;
  transition: none
}

.type-ahead-wrapper .type-ahead-hint .type-ahead-hint-field--show {
  color: #637381
}

@media (max-width: 1024px) {
  .type-ahead-wrapper .type-ahead-hint {
    display: none
  }
}

.type-ahead-wrapper .next-input-wrapper:not(.next-input-wrapper--is-error) .type-ahead-input {
  background: transparent
}

.type-ahead-wrapper>.next-input-wrapper {
  margin-top: 0
}

.grecaptcha-badge {
  bottom: 54px !important
}

.g-recaptcha-nojs__iframe {
  border-right: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1
}

.captcha-loading--spinner {
  padding-top: 3.125rem
}

.captcha-loading--spinner:not(.captcha-v2-displayed) {
  display: none
}

.captcha-loading--spinner.captcha-v2-displayed {
  display: block
}

.captcha-loading--spinner.captcha-v2-loaded {
  display: none
}

.captcha-loading--spinner.captcha--loading-failed {
  display: none
}

.captcha-error:not(.captcha--loading-failed) {
  display: none
}

.captcha-error.captcha--loading-failed {
  display: block
}

div[data-recaptcha-enterprise] {
  margin: 0
}

@font-face {
  font-family: 'ShopifySans';
  /* src: url(/shopify-marketing_assets/static/ShopifySans--light.woff2) format("woff2"); */
  font-style: normal;
  font-weight: 300;
  font-display: swap
}

@font-face {
  font-family: 'ShopifySans';
  /* src: url(/shopify-marketing_assets/static/ShopifySans--regular.woff2) format("woff2"); */
  font-style: normal;
  font-weight: 400;
  font-display: swap
}

@font-face {
  font-family: 'ShopifySans';
  /* src: url(/shopify-marketing_assets/static/ShopifySans--medium.woff2) format("woff2"); */
  font-style: normal;
  font-weight: 500;
  font-display: swap
}

@font-face {
  font-family: 'ShopifySans';
  /* src: url(/shopify-marketing_assets/static/ShopifySans--bold.woff2) format("woff2"); */
  font-style: normal;
  font-weight: 700;
  font-display: swap
}

@font-face {
  font-family: 'ShopifySans';
  /* src: url(/shopify-marketing_assets/static/ShopifySans--black.woff2) format("woff2"); */
  font-style: normal;
  font-weight: 900;
  font-display: swap
}

html,
body {
  width: 100%
}

body {
  margin: 0
}

.page-container {
  position: relative
}

.page-container--splitted {
  background-color: #084c3f
}

.page-main {
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 0;
  background-color: var(--p-background);
}

.page-main:not(.split) {
  background-color: var(--p-background);
}

.page-main.split {
  background-color: white;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.artwork {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 0;
  background-color: #084c3f
}

.page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
  max-width: 31rem;
  width: 95%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: white;
  border-radius: 8px;
  margin-top: 2vw;
  margin-bottom: 2vw
}

@media (max-width: 500px) and (max-height: 1024px) {
  .page-content {
    margin: 1.5vw 3vw;
    margin-top: 17.5vw;
    margin-bottom: 2vw
  }
}

.split .page-content {
  width: 100%;
  max-width: 24.1875rem;
  margin: 0px;
  border-radius: 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.split .login-footer {
  border-radius: 0;
  background-color: white;
  max-width: 24.1875rem
}

.artwork--desktop {
  display: none
}

.artwork.split {
  display: none
}

.login-card,
.login-footer,
.recaptcha-branding {
  display: block
}

.login-footer.mobile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1
}

.login-footer.mobile:not(.split) a {
  color: #47c1bf
}

.recaptcha-branding {
  color: white;
  padding-top: 0.625rem;
  font-size: 0.75rem;
  text-align: center
}

.recaptcha-branding a {
  color: white;
  text-decoration: underline
}

@media all and (min-width: 500px) {
  .artwork {
    height: 100%
  }

  .artwork--desktop {
    display: block
  }

  .artwork--mobile {
    display: none
  }

  .page-main {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .page-main .page-content {
    width: 100%
  }

  .page-main.split {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .split .page-content {
    max-width: unset
  }

  .login-footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
  }

  .login-footer.mobile {
    display: none
  }
}

@media all and (min-width: 1025px) {
  .page-main {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
  }

  .page-main .page-content {
    margin-left: 10vw;
    margin-right: 10vw
  }

  .page-main .page-content.center {
    margin: auto;
    width: 100%
  }

  .page-main.split {
    width: 40%;
    position: relative
  }

  .artwork--desktop.split {
    display: block
  }

  .page-main:not(.split) {
    background-color: var(--p-background)
  }
}

.hidden-background {
  background-color: white
}

.hidden-background .page-main {
  background-color: white
}

@media all and (min-width: 1025px) {
  .hidden-background .page-main {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
  }
}

.hidden-background .artwork {
  display: none
}

.stack-paragraph {
  width: 100%;
  overflow: hidden;
  line-height: 1.5em
}

.stack-paragraph--auto-width {
  width: auto
}

.stack-captcha {
  margin: 0 !important
}

.grecaptcha-badge {
  z-index: 1
}

.ui-banner--dismissible p {
  padding-right: 2rem
}

.newDesignLanguage .full-width {
  width: 100%
}

.newDesignLanguage .ui-password .ui-button {
  margin: 0.4375rem !important
}

.legacy .ui-button--link {
  color: #5c6ac4;
  text-decoration: none
}

.legacy .ui-button--link:hover {
  color: #202e78
}

.legacy .ui-button--link:active,
.legacy .ui-button--link:focus {
  background: transparent
}

.legacy .ui-password {
  border-color: #8996a3;
  -webkit-box-shadow: none;
  box-shadow: none
}

.legacy .ui-password.ui-password--has-focus {
  border-color: #5c6ac4;
  -webkit-box-shadow: 0 0 0 0.0625rem #5c6ac4;
  box-shadow: 0 0 0 0.0625rem #5c6ac4
}

.legacy .ui-password .ui-password__input:focus,
.legacy .ui-password .ui-password__input:focus+.ui-button {
  border-color: transparent !important;
  border: 0;
  outline: transparent;
  -webkit-box-shadow: none;
  box-shadow: none
}

.legacy .ui-password .ui-button {
  margin: 0.625rem 0.5rem !important
}

.legacy .ui-password .ui-button:hover,
.legacy .ui-password .ui-button:focus {
  color: #454e57;
  border: 0;
  outline: transparent;
  -webkit-box-shadow: none;
  box-shadow: none
}

.legacy .ui-password .next-input,
.legacy .ui-password .next-input--stylized {
  border-color: transparent
}

.legacy .ui-password .next-input:focus,
.legacy .ui-password .next-input--stylized:focus {
  border-color: #5c6ac4
}

.legacy .ui-password .ui-password__button {
  color: #8996a3
}

.legacy .next-input,
.legacy .next-input--stylized {
  border-color: #8996a3;
  -webkit-box-shadow: 0 0 0 0.0625rem transparent;
  box-shadow: 0 0 0 0.0625rem transparent;
  -webkit-transition: border-color 100ms cubic-bezier(0.64, 0, 0.35, 1), -webkit-box-shadow 100ms cubic-bezier(0.64, 0, 0.35, 1);
  transition: border-color 100ms cubic-bezier(0.64, 0, 0.35, 1), -webkit-box-shadow 100ms cubic-bezier(0.64, 0, 0.35, 1);
  transition: box-shadow 100ms cubic-bezier(0.64, 0, 0.35, 1), border-color 100ms cubic-bezier(0.64, 0, 0.35, 1);
  transition: box-shadow 100ms cubic-bezier(0.64, 0, 0.35, 1), border-color 100ms cubic-bezier(0.64, 0, 0.35, 1), -webkit-box-shadow 100ms cubic-bezier(0.64, 0, 0.35, 1)
}

.legacy .next-input:focus,
.legacy .next-input--stylized:focus {
  border-color: #5c6ac4;
  -webkit-box-shadow: 0 0 0 0.0625rem #5c6ac4;
  box-shadow: 0 0 0 0.0625rem #5c6ac4
}

.legacy .next-input::-webkit-input-placeholder,
.legacy .next-input--stylized::-webkit-input-placeholder {
  color: #637381
}

.legacy .next-input::-moz-placeholder,
.legacy .next-input--stylized::-moz-placeholder {
  color: #637381
}

.legacy .next-input:-ms-input-placeholder,
.legacy .next-input--stylized:-ms-input-placeholder {
  color: #637381
}

.legacy .next-input::-ms-input-placeholder,
.legacy .next-input--stylized::-ms-input-placeholder {
  color: #637381
}

.legacy .next-input::placeholder,
.legacy .next-input--stylized::placeholder {
  color: #637381
}

.login-card__content a:not(.ui-button),
.login-card__content .ui-button--link {
  font-size: 1em
}

.login-card__content .password-strength-progress-bar {
  margin-top: 0
}

.login-card__content .ui-modal {
  max-width: 32.5rem
}

.login-card__content .main-card-section>.ui-heading,
.login-card__content .main-card-section__header>.ui-heading {
  font-size: 1.5rem;
  line-height: 1.2em;
  margin-bottom: 0.25rem
}

@media (max-width: 500px) and (max-height: 1024px) {

  .login-card__content .main-card-section>.ui-heading,
  .login-card__content .main-card-section__header>.ui-heading {
    font-size: 1.125rem
  }
}

.login-card__content .main-card-section>.ui-heading.activate-heading,
.login-card__content .main-card-section__header>.ui-heading.activate-heading {
  margin-bottom: 1.25rem
}

.login-card__content .main-card-section>.ui-subheading,
.login-card__content .main-card-section__header>.ui-subheading {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  line-height: 1.5rem;
  text-transform: none;
  margin-bottom: 1.25rem
}

@media (max-width: 500px) and (max-height: 1024px) {

  .login-card__content .main-card-section>.ui-subheading,
  .login-card__content .main-card-section__header>.ui-subheading {
    margin-bottom: 1.5rem;
    font-size: 0.875rem
  }
}

.login-card__content .user-checklist-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem
}

.login-card__content .user-checklist-item:last-child {
  margin-bottom: 0.5rem
}

.login-card__content .user-checklist-item .next-icon,
.login-card__content .user-checklist-item .next-icon__text {
  width: 35px;
  text-align: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 1rem;
  top: 0.25rem
}

.login-card__content .ui-content-list__item {
  margin-bottom: 0.625rem
}

.login-card__content .help-text {
  color: var(--p-text-subdued)
}

.login-card__content .ui-card__section {
  padding: 1.25rem 2rem
}

.login-card__content ol li {
  margin-left: 1em;
  margin-bottom: 1rem;
  list-style-position: outside
}

.login-card__content ol li:last-child {
  margin-bottom: 0
}

.login-card__content .polaris-reset-sub-heading>.ui-subheading {
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1rem;
  text-transform: uppercase;
  font-size: 1.4rem !important;
  text-transform: uppercase !important;
  margin: 0
}

@media screen and (min-width: 640px) {
  .login-card__content .polaris-reset-sub-heading>.ui-subheading {
    font-size: 0.75rem
  }
}

.login-card__content a.ui-action-list-action {
  font-size: 1.4rem;
  color: initial
}

.login-card__content a.ui-action-list-action:hover,
.login-card__content a.ui-action-list-action:focus {
  color: white
}

.login-card__content .tfa-heading,
.login-card__content .checkpoint-heading {
  width: 25rem
}

.login-card__content .ui-paragraph {
  margin-bottom: 1.25rem;
  line-height: 1.5rem
}

.login-card__content .next-label {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-bottom: 0.25rem
}

.login-card__content .ui-stack,
.login-card__content .ui-stack--vertical>* {
  width: 100%
}

.login-card__content .tfa-spinner {
  margin-top: 0.5rem
}

.login-card__content .ui-password {
  max-height: 3.25rem;
  overflow: hidden;
  -webkit-transition: border-color 200ms ease, -webkit-box-shadow 200ms ease;
  transition: border-color 200ms ease, -webkit-box-shadow 200ms ease;
  transition: border-color 200ms ease, box-shadow 200ms ease;
  transition: border-color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
  padding: 0 !important
}

.login-card__content .ui-password .ui-password__input {
  z-index: 1
}

.login-card__content .ui-password .ui-button {
  font-size: 1em;
  -webkit-flex-basis: 2.375rem;
  -ms-flex-preferred-size: 2.375rem;
  flex-basis: 2.375rem;
  color: #798c9c;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: var(--p-border-radius-base);
  -webkit-transition: color 100ms ease;
  transition: color 100ms ease;
  padding: 0 !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px)
}

.login-card__content .ui-password .ui-button .next-icon,
.login-card__content .ui-password .ui-button .next-icon__text {
  margin-top: -0.03125rem;
  margin-bottom: 0
}

.login-card__content .ui-select__wrapper,
.login-card__content .ui-select {
  height: 3.25rem
}

.login-card__content .next-input,
.login-card__content .next-input--stylized {
  max-height: 2.25rem;
  padding: 0.5rem 0.75rem
}

.login-card__content .ui-button--transparent {
  color: #8996a3;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important
}

.login-card__content .ui-button:not(.ui-button--link):not(.ui-banner__dismissible-button) {
  font: 1rem "ShopifySans", -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  font-weight: 700;
  -webkit-transition-duration: 0s;
  transition-duration: 0s
}

.login-card__content .ui-button.ui-button--emphasized:not(.ui-button--link):not(.ui-banner__dismissible-button),
.login-card__content .login-card__cta:not(.ui-banner__dismissible-button) {
  font-weight: 600
}

.login-card__content .badge {
  font-size: 1.3rem
}

.login-card__content .next-label--switch {
  position: relative;
  top: -0.125rem;
  margin-left: 0.5rem
}

.login-card__content .ui-modal__close-button .ui-button {
  padding: 1rem !important
}

.login-card__content .next-input-wrapper--is-error .next-input,
.login-card__content .next-input-wrapper--is-error .next-input--stylized {
  background-color: #fbeae5;
  border-color: #bf0711;
  -webkit-box-shadow: 0 0 0 0.0625rem transparent;
  box-shadow: 0 0 0 0.0625rem transparent
}

.login-card__content .next-input-wrapper--is-error .next-input:focus,
.login-card__content .next-input-wrapper--is-error .next-input--stylized:focus {
  -webkit-box-shadow: 0 0 0 0.0625rem #de3618;
  box-shadow: 0 0 0 0.0625rem #de3618
}

.login-card__content .next-input-wrapper--is-error .ui-password {
  border: solid 0.0625rem #bf0711;
  background: #fbeae5
}

.login-card__content .next-input-wrapper--is-error .ui-password.ui-password--has-focus {
  -webkit-box-shadow: 0 0 0 0.0625rem #de3618;
  box-shadow: 0 0 0 0.0625rem #de3618
}

.login-card__content .next-input-wrapper--is-error .ui-password .ui-password__input {
  border: 0;
  background: transparent;
  -webkit-box-shadow: none !important;
  box-shadow: none !important
}

.login-card__content .next-input-wrapper--is-error .ui-password .ui-button--transparent {
  color: #637381
}

.login-card__content .form-full-width .next-input-wrapper {
  width: 100%;
  padding-right: 1rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.login-card__content .ui-button-group {
  margin: 0
}

.login-card__content .ui-button-group button:only-of-type {
  margin-right: 0
}

.login-card__content .ui-button-group>.ui-button--size-large {
  margin-top: 1.5rem
}

.login-card__content .ui-button[type="submit"],
.login-card__content .ui-modal__primary-actions .ui-button,
.login-card__content .ui-modal__secondary-actions .ui-button {
  font-size: 1rem
}

.login-card__content .ui-button.login-activity[type="submit"],
.login-card__content .ui-modal__primary-actions .ui-button.login-activity,
.login-card__content .ui-modal__secondary-actions .ui-button.login-activity {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  font-weight: unset
}

.login-card__content .ui-button[type="submit"],
.login-card__content .ui-modal__primary-actions .ui-button {
  font-weight: 700
}

.login-card__content .ui-banner {
  margin-bottom: 1.25rem
}

@media (max-width: 500px) and (max-height: 1024px) {
  .login-card__content .ui-banner {
    margin-bottom: 1.25rem
  }
}

.login-card__content .no-bottom-padding {
  margin-bottom: 0
}

.login-card__content .g-recaptcha,
.login-card__content .button-top-padding {
  margin-top: 1rem
}

.login-card__content .merge-token-field {
  width: 14.375rem
}

.login-card__content .destination-heading {
  color: #212b36
}

.login-card__content .footnote {
  margin-top: 1rem
}

.login-card__content .lastpass-hidden {
  position: fixed;
  z-index: -100;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none
}

.login-card__content .changeable-label {
  width: calc(100% - 0.5rem);
  position: relative;
  font-size: 1em;
  padding: 0.5rem
}

@media (max-width: 500px) and (max-height: 1024px) {
  .login-card__content .changeable-label {
    display: block
  }
}

.login-card__content .changeable-label.changeable-label--clean {
  padding: 0
}

.login-card__content .changeable-label .changeable-label__text {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem
}

@media (max-width: 500px) and (max-height: 1024px) {
  .login-card__content .changeable-label .changeable-label__text {
    padding-right: 0;
    margin-bottom: 0.25rem
  }
}

.login-card__content .changeable-label .changeable-label__link {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.login-card__content .password-field .next-label,
.login-card__content .text-field-label-hidden .next-label,
.login-card__content .visually-hidden {
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  border: 0 !important
}

.login-card__content .help-link {
  margin-top: 1.5rem;
  display: block
}

@media (max-width: 500px) and (max-height: 1024px) {
  .login-card__content .help-link {
    font-size: 0.875rem
  }
}

.login-card__content .validation-error {
  font-size: 0.875rem
}

@media screen and (min-width: 640px) {
  .login-card__content .validation-error {
    font-size: 0.875rem
  }
}

.login-card__content .validation-error a {
  color: #de3618;
  text-decoration: underline
}

.login-card__content .validation-error a:hover,
.login-card__content .validation-error a:focus {
  color: #bf0711
}

.login-card__content .validation-error a:active {
  color: #330101
}

.remerges-continue-action-cotainer .merge-card__action-wrapper {
  margin-bottom: 2rem
}

.password-recovery-error {
  padding: 1rem 0.625rem 1.5rem 0.625rem
}

.password-recovery-error__email {
  font-weight: bold
}

.hidden {
  display: none
}

a {
  color: #008060
}

a:hover,
a:focus {
  color: #004c3f
}

a:active {
  color: #004c3f
}

.login-card {
  padding: 2.5rem 2.5rem 0
}

@media (max-width: 500px) and (max-height: 1024px) {
  .login-card {
    padding-top: 2rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1.5rem
  }
}

.login-card .ui-button-group--login-options {
  width: 100%
}

.login-card .ui-button-group--login-options [data-captcha-enterprise]:empty {
  margin: 0
}

@media (max-width: 500px) and (max-height: 1024px) {
  .login-card .ui-button-group--login-options>button {
    margin-right: 0
  }
}

.login-card p {
  color: #454f5b
}

.login-card .ui-button[type="submit"],
.login-card .ui-modal__primary-actions .ui-button {
  width: 100%
}

@media (max-width: 500px) and (max-height: 1024px) {
  .login-card.login-card--no-bottom-push-mobile {
    padding-bottom: 0
  }
}

@media (max-width: 500px) and (max-height: 1024px) {
  .login-card {
    padding-top: 1.25rem
  }
}

@media (max-width: 500px) {
  .login-card {
    padding-left: 1.4375rem;
    padding-right: 1.4375rem
  }
}

@media (max-height: 600px) {
  .login-card {
    padding-top: 3rem;
    padding-bottom: 0.9375rem
  }
}

.split .login-card {
  padding-left: 0;
  padding-right: 0;
  width: 80%;
  max-width: 24.1875rem
}

@media (max-width: 500px) and (max-height: 1024px) {
  .split .login-card {
    padding-left: 0;
    padding-right: 0
  }
}

.login-card--content-heavy {
  padding-top: 8vh
}

@media (max-width: 500px) and (max-height: 1024px) {
  .login-card--content-heavy {
    padding-top: 1.25rem
  }
}

.login-card__header {
  margin-bottom: 2.5rem
}

.login-card__header.login-card__header--has-breadcrumbs {
  margin-bottom: 2rem
}

@media (max-width: 500px) and (max-height: 1024px) {
  .login-card__header {
    margin-bottom: 1.375rem
  }
}

@media (max-height: 600px) {
  .login-card__header {
    margin-bottom: 1.5625rem
  }
}

.login-card__logo {
  width: 12.3125rem
}

@media (max-width: 500px) and (max-height: 1024px) {
  .login-card__logo {
    width: 10.25rem
  }
}

.login-card__cta {
  text-align: center;
  min-width: 8.125rem;
  font-size: 1rem
}

.login-card__cta.login-card__cta--secondary {
  font-weight: initial !important
}

.login-card__link-icon {
  color: #5c6ac4
}

.login-card__breadcrumbs {
  width: 111%;
  margin-top: 2rem;
  color: var(--p-text-subdued)
}

.login-card__breadcrumbs .login-card__breadcrumb {
  display: inline-block
}

.login-card__breadcrumbs .login-card__breadcrumb--active {
  color: #212b36
}

@media all and (max-width: 520px) {
  .login-card__breadcrumbs {
    width: 100%
  }
}

.login-card__breadcrumbs-arrow {
  padding: 0 0.25rem;
  position: relative;
  top: 0.0625rem
}

.login-card__heading--single {
  margin-bottom: 1rem !important
}

.flash-margin-top-20 {
  margin-top: 20px
}

.account-picker {
  margin-top: 2.5rem
}

.account-picker .ui-button {
  text-align: left;
  margin: 0
}

.account-picker .ui-button:active {
  background: transparent !important
}

.account-picker__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: relative;
  overflow: visible
}

.account-picker__item:focus .account-picker__item-backdrop,
.account-picker__item:hover .account-picker__item-backdrop {
  background-color: #f9fafb !important
}

.account-picker__item:focus {
  background: transparent
}

.account-picker__item-content {
  width: 100%;
  position: relative;
  z-index: 1
}

.account-picker__item-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1rem !important;
  right: -1rem !important;
  background-color: transparent;
  -webkit-transition: background-color 100ms ease !important;
  transition: background-color 100ms ease !important;
  border-radius: 0.5rem;
  z-index: 0
}

.account-picker__action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #212b36 !important
}

.account-picker__action-icon {
  width: 1.3125rem;
  height: 1.3125rem;
  border-radius: 100%;
  margin-right: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center
}

.account-picker__action-icon-with-card {
  width: 1.3125rem;
  height: 1.3125rem;
  border-radius: 100%;
  background-color: #f4f6f8;
  color: #919eab;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center
}

.login-footer {
  width: 100%;
  font-size: 0.875rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 3.5rem 2rem 2.5rem 2rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem
}

@media (max-width: 500px) and (max-height: 1024px) {
  .login-footer {
    display: none
  }
}

.login-footer__link {
  color: #637381;
  font-weight: 500;
  margin-right: 2rem;
  text-decoration: none
}

.login-footer__link:last-child {
  margin-right: 0
}

.login-footer__link:hover {
  color: #454f5b
}

.signup-footer .g-recaptcha {
  padding: 0.5rem;
  margin: 0
}

.signup-footer__agreement {
  padding: 0;
  margin: 0
}

@media (max-width: 500px) and (max-height: 1024px) {
  .signup-footer__agreement {
    font-size: 0.875rem
  }
}

.signup-footer .message-provider-using {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2rem 1rem
}

.signup-footer div {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex
}

.signup-footer .icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.signup-footer .message {
  font-size: 14px;
  color: #637381;
  min-width: 24rem
}

.signup-footer .message b {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.signup-footer .cancel {
  font-size: 14px;
  text-align: right;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  min-width: 12rem
}

.debug-box {
  position: fixed;
  bottom: 0;
  right: 0
}

.debug-box .ui-card__section--type-subdued {
  background: #212b36;
  text-align: center;
  color: #919eab
}

.debug-box .next-icon,
.debug-box .next-icon__text {
  top: 0
}

.debug-box a,
.debug-box a:hover {
  color: #919eab
}

.connectivity-hidden {
  display: none
}

.recovery-card__description {
  color: lightslategray
}

.recovery-card__tfa-method {
  font-weight: bold;
  color: lightslategray
}

.external-login-button.ui-button {
  font-weight: 500 !important;
  margin: 0.75rem 0 0 !important
}

.external-login-button.ui-button .next-icon,
.external-login-button.ui-button .next-icon__text {
  top: -0.15em;
  margin-right: 0.75rem
}

.external-login-button.ui-button+.ui-button--transparent {
  color: #007ace
}

.external-login-button.ui-button:focus {
  color: var(--p-text)
}

.external-login-divider {
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  width: 100%;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1rem;
  background: #8c9196;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) calc(50% - 1px), #8c9196 calc(50%), rgba(0, 0, 0, 0) calc(50% + 1px))
}

.external-login-divider .external-login-divider__text {
  background: #fff;
  color: #8c9196;
  padding: 0 1.5rem
}

.signup-external {
  text-align: center
}

.signup-external .alternate-login-button {
  min-width: 23rem
}

.signup form {
  width: 100%
}

.signup form .ui-form__group,
.signup form .ui-form__element {
  width: 100%
}

.signup .alternate-login-button {
  margin-top: 1rem;
  margin-right: 9rem
}

.signup .signup-or-text {
  text-align: center;
  height: 1px;
  background-color: #aaa;
  top: 51%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-width: 32rem
}

.signup .signup-or-text .or-text {
  position: relative;
  text-align: center;
  top: -1rem;
  background-color: white
}

.signup .use-external-button {
  text-align: center
}

.signup .ui-button--primary {
  margin-top: 0.375rem
}

.signup .ui-button__provider {
  border-color: #8C9196;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.signup .ui-button__provider:last-of-type {
  margin-bottom: 1.5rem
}

.signup .additional-details-submit {
  margin-top: 1.5rem
}

.from-external-login {
  width: 100%
}

.from-external-login .external-login-confirm-provider__text {
  font-size: 1rem;
  text-align: center
}

.from-external-login .external-login-confirm-provider__text .next-icon,
.from-external-login .external-login-confirm-provider__text .next-icon__text {
  padding-right: 0.5rem
}

.from-external-login .signup-footer__agreement {
  padding-left: 1rem;
  margin: 0;
  width: 100%;
  overflow-wrap: break-word
}

@media (max-width: 500px) and (max-height: 1024px) {
  .from-external-login .signup-footer__agreement {
    font-size: 0.875rem
  }
}

.login-card__content .next-input,
.login-card__content .next-input--stylized {
  color: #454F5B
}

.login-card__content .next-input,
.login-card__content .next-input--stylized,
.login-card__content .next-field__connected-wrapper {
  border: 0.0625rem solid #8C9196
}

.login-card__content .next-field__connected-wrapper.next-input--is-focused,
.login-card__content .next-input:focus,
.login-card__content .next-input--stylized:focus {
  -webkit-box-shadow: 0 0 0 0.0625rem #008060;
  box-shadow: 0 0 0 0.0625rem #008060;
  border-color: #008060
}

.login-card__content .next-input.next-input--invisible:focus,
.login-card__content .next-input.next-input--invisible,
.login-card__content .next-input--invisible.next-input--stylized {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none
}

.login-card__content .next-input-wrapper--is-error .next-input,
.login-card__content .next-input-wrapper--is-error .next-input--stylized {
  background-color: #FFF4F4;
  border-color: #D82C0D;
  -webkit-box-shadow: 0 0 0 0.0625rem #D82C0D;
  box-shadow: 0 0 0 0.0625rem #D82C0D
}

.login-card__content .lastpass-hidden {
  padding: 0
}

.login-card__content .restyle2020-form .next-label {
  position: absolute;
  top: 0;
  left: 0;
  padding: inherit;
  margin: 0;
  -webkit-transform: translateX(0.8125rem) translateY(0.25rem);
  transform: translateX(0.8125rem) translateY(0.25rem);
  z-index: 50;
  color: #637381;
  font-size: 0.75rem
}

.login-card__content .restyle2020-form .type-ahead-hint .next-label {
  display: none
}

.login-card__content .restyle2020-form .next-input,
.login-card__content .restyle2020-form .next-input--stylized {
  max-height: 3.5rem;
  font-size: 1rem;
  padding: 1.5rem 1rem 0.375rem 0.75rem
}

.login-card__content .next-input,
.login-card__content .next-input--stylized {
  margin: 0
}

.login-card__content .next-input-wrapper__loading-wrapper {
  position: relative
}

.login-card__content .next-input-wrapper__loading-bar {
  display: none;
  border-radius: 0.1875rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.login-card__content .next-input-wrapper--is-loading .next-input-wrapper__loading-bar {
  display: block
}

.login-card__content .next-input-wrapper__loading-bar::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #008060;
  -webkit-animation: loading-bar 1.5s infinite;
  animation: loading-bar 1.5s infinite;
  z-index: 50
}

@-webkit-keyframes loading-bar {
  0% {
    left: 0;
    right: calc(100%)
  }

  15% {
    left: 0
  }

  50% {
    left: calc(100%);
    right: 0
  }

  65% {
    right: 0
  }

  100% {
    left: 0;
    right: calc(100%)
  }
}

@keyframes loading-bar {
  0% {
    left: 0;
    right: calc(100%)
  }

  15% {
    left: 0
  }

  50% {
    left: calc(100%);
    right: 0
  }

  65% {
    right: 0
  }

  100% {
    left: 0;
    right: calc(100%)
  }
}

.login-card__content .next-input__help-text {
  margin: 0
}

.login-card__content .tfa-illust {
  width: 122px
}

.login-card__content .tfa-illust svg {
  width: 100%;
  height: 100%
}

[data-controller=employee_sessions] .page {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%
}

[data-controller=employee_sessions] .form {
  text-align: center
}

[data-controller=employee_sessions] .spinner {
  -webkit-animation: button-loading-spinner 0.7s linear infinite;
  animation: button-loading-spinner 0.7s linear infinite
}

[data-controller=employee_sessions] .manual-action {
  margin-left: 1em
}

[data-controller=employee_sessions] .spinner-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%
}

.enroll .ui-card:first-child {
  margin-top: 0.625rem
}

.enroll .ui-card:first-child .ui-callout-card__content h2 {
  margin-bottom: 0.3125rem
}

.enroll .description {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.password-strength-progress-bar.password-strength-strong .ui-progress-bar__indicator {
  background-color: #50b83c
}

.password-strength-strong {
  color: #173630;
  font-weight: bold
}

.password-strength-progress-bar.password-strength-medium .ui-progress-bar__indicator {
  background-color: #50b83c
}

.password-strength-medium {
  color: #173630;
  font-weight: bold
}

.password-strength-progress-bar.password-strength-weak .ui-progress-bar__indicator {
  background-color: #f49342
}

.password-strength-weak {
  color: #4a1504;
  font-weight: bold
}

.password-strength-progress-bar {
  margin-bottom: 0.5rem
}

.password-strength-progress-bar .ui-progress-bar__indicator {
  -webkit-transition: all 500ms cubic-bezier(0.64, 0, 0.35, 1);
  transition: all 500ms cubic-bezier(0.64, 0, 0.35, 1);
  background-color: #dfe3e8
}

.set-password-help-text {
  margin-top: 0
}

.set-password-feedback {
  padding-top: 0.5rem;
  display: none
}

.set-password-feedback.has-been-focused,
.next-input-wrapper--is-error~.set-password-feedback {
  display: block
}

.ui-form__section .next-input-wrapper~.set-password-feedback {
  padding: 0.5rem;
  padding-top: 0
}

.password-change-banner {
  display: inline
}

.use-staff-accounts-banner {
  padding-top: 0.5rem
}

.other-destinations {
  color: #637381
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0
}

h1 {
  font-size: 26px;
  line-height: 1
}

h2 {
  font-size: 18px
}

h3 {
  font-size: 14px
}

h4 {
  font-size: 14px
}

h5 {
  font-size: 14px
}

h6 {
  font-size: 14px
}

p {
  margin: 0
}

ul {
  list-style: disc inside none
}

ol {
  list-style: decimal inside none
}

ol[type='1'] {
  list-style: decimal inside none
}

ol[type='a'] {
  list-style: lower-alpha inside none
}

ol[type='A'] {
  list-style: upper-alpha inside none
}

ol[type='i'] {
  list-style: lower-roman inside none
}

ol[type='I'] {
  list-style: upper-roman inside none
}

li {
  margin: 0
}

dt {
  font-weight: bold
}

dd {
  margin-left: 10px
}

pre,
code {
  font-family: Monaco, Consolas, Lucida Console, monospace
}

pre {
  font-size: 90%
}

code {
  font-size: 85%;
  background: #ebeef0;
  padding: 2px;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word
}

input {
  font-size: 14px;
  line-height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif
}

del {
  text-decoration: line-through
}

address {
  font-style: normal
}

small {
  color: #798c9c;
  font-size: 12px
}

.next-type--note {
  font-style: italic;
  font-size: 12px;
  color: #95a7b7
}

.type--no-margin {
  margin: 0
}

.type--margin-bottom {
  margin-bottom: 5px
}

.type--margin-left {
  margin-left: 10px
}

.type--centered {
  text-align: center
}

.type--right {
  text-align: right
}

.type--left {
  text-align: left
}

.type--breakall {
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word
}

.type--light-weight {
  font-weight: 300
}

.type--semi-bold {
  font-weight: 500
}

.type--bold {
  font-weight: 700
}

.type--base {
  font-size: 14px
}

.type--danger {
  color: #ff5d5d
}

.type--warning {
  color: #ff9517
}

.type--success {
  color: #96bf48
}

.type--attention {
  color: #d4a002
}

.type--info {
  color: #006fbb
}

.type--white {
  color: #ffffff
}

.type--blue-lighter {
  color: #ebf5fa
}

.type--strikethrough {
  text-decoration: line-through
}

.type--line-height-equal-to-font {
  line-height: 1 !important
}

.type--tight-spacing {
  margin-bottom: -4px
}

.type--preserve-whitespace {
  white-space: pre-wrap
}

.type--no-wrap {
  white-space: nowrap
}

a.type--subdued,
button.type--subdued {
  color: inherit;
  text-decoration: underline
}

a.type--subdued:hover {
  color: #212b36
}

.type--truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block
}

.type--truncated--block {
  display: block
}

.type--margin-top {
  margin-top: 20px
}

.type--half-margin {
  margin-bottom: 10px
}

.type--number {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem
}

@media (min-width: 640px) {
  .type--number {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.type--number--x-large {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem
}

@media (min-width: 640px) {
  .type--number--x-large {
    font-size: 1.75rem;
    line-height: 2rem
  }
}

.type--number--large {
  font-size: 1.3125rem;
  font-weight: 400;
  line-height: 1.75rem
}

@media (min-width: 640px) {
  .type--number--large {
    font-size: 1.625rem;
    line-height: 2rem
  }
}

.type--number--small {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem
}

@media (min-width: 640px) {
  .type--number--small {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.type--number--tiny {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem
}

@media (min-width: 640px) {
  .type--number--tiny {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.user-avatar {
  position: relative;
  top: 0.0625rem;
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  background: transparent;
  text-align: center;
  overflow: hidden
}

.user-avatar>* {
  position: absolute;
  top: 0;
  left: 0
}

.user-avatar .user-avatar__initials {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: white;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 2px;
  margin-left: 2px;
  text-transform: uppercase
}

.user-avatar img {
  width: 100%;
  height: 100%;
  background-color: #fff
}

.user-avatar--style-1 {
  background: #29bc94
}

.user-avatar--style-2 {
  background: #ff5d5d
}

.user-avatar--style-3 {
  background: #ff9517
}

.user-avatar--style-4 {
  background: #96bf48
}

.user-avatar--style-5 {
  background: #006fbb
}

.user-avatar--style-6 {
  background: #b762ff
}

.nowrap {
  white-space: nowrap
}

.tool-tip {
  display: inline-block
}

.tool-tip__heading {
  border-bottom: 1px dashed #5c6ac4;
  color: #5c6ac4
}

.tool-tip .tool-tip__text {
  color: #212b36;
  margin-top: 5px;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: var(--p-border-radius-base);
  background: white;
  pointer-events: none;
  padding: 0.5rem 0.5rem;
  -webkit-box-shadow: 0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08);
  box-shadow: 0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08)
}

.tool-tip:hover .tool-tip__text {
  visibility: visible
}

.user-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.user-card .user-avatar {
  width: 2.5rem;
  height: 2.5rem;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.user-card__content {
  line-height: 1.2em;
  margin-left: 1rem;
  overflow: hidden
}

.user-card__name {
  color: #212b36;
  font-size: 1.0625rem;
  font-weight: 700
}

.user-card--integrated .user-card__name {
  font-size: 1em;
  font-weight: initial;
  margin-bottom: 0.25rem
}

.user-card__email {
  font-size: 0.8125rem;
  color: #637381;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden
}

.user-card--integrated .user-card__email {
  font-size: 1em
}

.user-card-condensed {
  border-radius: 0.25rem;
  background: #f4f6f8;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.6875rem 0.8125rem
}

.user-card-condensed--emphasized {
  background: white;
  -webkit-box-shadow: 0 0 0.0625rem rgba(33, 43, 54, 0.25), 0 0.0625rem 0.1875rem rgba(33, 43, 54, 0.15);
  box-shadow: 0 0 0.0625rem rgba(33, 43, 54, 0.25), 0 0.0625rem 0.1875rem rgba(33, 43, 54, 0.15)
}

.user-card-condensed__icon {
  margin-right: 0.5rem;
  fill: #919eab;
  top: 0;
  opacity: .6
}

.user-card-condensed--emphasized .user-card-condensed__icon {
  fill: #919eab;
  opacity: 1
}

.user-card-condensed__email {
  font-size: 0.8125rem;
  color: #454f5b
}

.user-card-condensed--emphasized .user-card-condensed__email {
  font-weight: 500
}

.content-stack>* {
  margin-bottom: 1rem
}

.content-stack>*:last-child {
  margin-bottom: 0
}

.content-stack--spacing-extra-tight>* {
  margin-bottom: 0.25rem
}

.content-stack--spacing-tight>* {
  margin-bottom: 0.5rem
}

.content-stack--spacing-loose>* {
  margin-bottom: 1.25rem
}

.content-stack--spacing-extra-loose>* {
  margin-bottom: 2rem
}

.tfa-enrollment.ui-modal {
  max-width: unset
}

.tfa-enrollment.ui-modal.overflow-visible {
  overflow: visible
}

.tfa-enrollment.overflow-visible .ui-modal__body {
  overflow-y: visible
}

.tfa-enrollment button.ui-action-list-action {
  font-family: unset
}

.tfa-enrollment form:not(#verify_password_form) .ui-modal__section {
  padding: 0
}

.tfa-enrollment form:not(#verify_password_form) .enrollment-step {
  padding: 1.25rem
}

.tfa-enrollment form:not(#verify_password_form) .enrollment-step:not(:last-child) {
  border-bottom: 1px solid var(--p-divider)
}

.tfa-enrollment.enroll-tfa-container {
  background-color: var(--p-surface, white);
  margin: auto;
  max-width: 640px;
  overflow: visible
}

.tfa-enrollment .recovery-codes-container .recovery {
  list-style: none;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.tfa-enrollment .recovery-codes-container .recovery li {
  margin: 2px 24px;
  display: inline-block;
  vertical-align: top
}

.tfa-enrollment .recovery-codes-container .recovery li.used {
  color: #95a7b7
}

.tfa-enrollment .recovery-codes-container .recovery code {
  font-size: 1.125rem;
  display: block;
  padding: 8px 0px
}

.tfa-enrollment #app_auth_form .qr-code__manual .ui-collapsible__panel p {
  margin-top: 1.5rem
}

.tfa-enrollment #push_auth_form #loading {
  color: var(--p-border-highlight)
}

.tfa-enrollment #push_auth_form #success {
  color: var(--p-icon-success)
}

.tfa-enrollment #push_auth_form #failed {
  color: var(--p-icon-critical)
}

.tfa-enrollment #push_auth_form #mainFailedFlashBanner {
  margin-bottom: 1.5rem
}

.tfa-enrollment .code-sent {
  color: var(--p-icon-success)
}

.tfa-enrollment .tfa-selector .tfa-method-illust {
  width: 80px
}

.tfa-enrollment .tfa-selector .ui-popover__container button {
  text-align: unset;
  font-weight: 400
}

.tfa-enrollment .tfa-selector .ui-popover {
  margin: 0
}

.tfa-enrollment .tfa-selector .ui-popover .ui-action-list__item {
  padding: 0.1rem
}

.tfa-enrollment .tfa-selector .ui-popover .ui-action-list__item:not(:last-child) {
  border-bottom: 1px solid var(--p-divider)
}

.tfa-enrollment .tfa-selector .ui-popover.ui-popover--is-positioned-above {
  top: 100%;
  bottom: auto
}

.tfa-enrollment .tfa-selector .tfa-description {
  margin-top: 0.6rem;
  color: #6D7175
}

.tfa-enrollment .tfa-selector .tfa-method-container:hover .tfa-method-illust .background-circle {
  fill: #D2D5D9
}

.login-card__content .tfa-enrollment .ui-button:not(.ui-button--link):not(.ui-banner__dismissible-button) {
  font: unset;
  text-align: unset;
  font-weight: 400;
  padding: 0.8rem 1.6rem
}

.login-card__content .tfa-enrollment .ui-modal__primary-actions .ui-button {
  width: unset
}

.login-card__content .tfa-enrollment .ui-stack {
  width: unset
}

.login-card__content .tfa-enrollment button[type='submit'] {
  font-size: 1em;
  font-weight: unset
}

.login-card__content .tfa-enrollment a:not(.ui-button) {
  color: var(--p-interactive);
  cursor: pointer
}

.login-card__content .tfa-enrollment a.ui-button {
  text-align: center
}

.login-card__content .tfa-enrollment .ui-button:not(.ui-button--link):not(.ui-banner__dismissible-button) {
  padding: 0.9rem 1.5rem
}

.login-card__content .tfa-enrollment .badge {
  font-size: 0.875rem
}

.login-card .tfa-enrollment .ui-button[type="submit"] {
  width: unset
}

.ui-type-container .qr-code__image {
  display: block;
  margin: 1.25rem 0;
  width: 150px
}

@media (min-width: 450px) {
  @supports (display: grid) {
    .ui-type-container .qr-code__configuration {
      display: grid;
      grid-gap: 1rem 1.25rem
    }

    .ui-type-container .qr-code__scan,
    .ui-type-container .qr-code__manual {
      grid-column: 1 / span 1
    }

    .ui-type-container .qr-code__image {
      grid-column: 2;
      grid-row: 1 / span 2;
      margin: 0
    }
  }
}

.ui-type-container .qr-code__code {
  font-family: inherit;
  font-weight: 700;
  margin-top: 0.5rem
}

.underlined {
  text-decoration: underline
}

.alternate-login-button {
  margin-top: 1.5625rem;
  padding: 1rem 1.25rem;
  color: #454f5b;
  font-size: 0.875rem;
  font-weight: 700;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid var(--p-border-subdued);
  border-radius: 0.3125rem;
  -webkit-box-shadow: 0.0625rem 0.0625rem 0 0.0625rem rgba(0, 0, 0, 0.05);
  box-shadow: 0.0625rem 0.0625rem 0 0.0625rem rgba(0, 0, 0, 0.05);
  text-decoration: none;
  white-space: nowrap;
  -webkit-transition-property: background-color, -webkit-box-shadow;
  transition-property: background-color, -webkit-box-shadow;
  transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow, -webkit-box-shadow;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out
}

.alternate-login-button:focus,
.alternate-login-button:hover {
  -webkit-box-shadow: 0.0625rem 0.25rem 0.3125rem 0.0625rem rgba(0, 0, 0, 0.1);
  box-shadow: 0.0625rem 0.25rem 0.3125rem 0.0625rem rgba(0, 0, 0, 0.1)
}

.alternate-login-button:active {
  background-color: #e5e5e5;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition-duration: 50ms;
  transition-duration: 50ms
}

.alternate-login-button:first-of-type {
  margin-top: 2.5rem
}

.alternate-login-button:last-of-type {
  margin-bottom: 0
}

.alternate-login-button__icon {
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.5rem
}

.account-selector {
  margin-top: 1.25rem
}

.account-selector .ui-heading {
  color: #212b36
}

.account-selector .ui-subheading {
  font-size: 14px;
  font-weight: 400;
  text-transform: none
}

.account-selector .ui-button {
  margin: 0;
  text-align: left
}

.account-selector .ui-button:hover {
  text-decoration: none
}

.account-selector .next-icon,
.account-selector .next-icon__text {
  margin-top: auto;
  margin-bottom: 0
}

.account-selector .ui-card {
  border-radius: unset;
  -webkit-box-shadow: none;
  box-shadow: none
}

.account-selector .ui-stack-item--account-selector {
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 1px solid #E1E3E5;
  margin-left: -8px;
  width: calc(100% + 16px);
  max-width: none
}

.account-selector__account {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.account-selector__content {
  overflow: hidden;
  padding-bottom: 0.0625rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.account-selector__email {
  margin: 0 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden
}

.account-selector__action {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.account-selector__byline {
  width: auto !important;
  margin-bottom: 0.5rem;
  line-height: 1.6rem
}

.account-selector__footer {
  text-align: center;
  margin-top: 2rem
}

.login-card__content .account-selector .ui-button.account-card {
  border-radius: unset;
  padding: 0.75rem 1rem;
  -webkit-transition: 200ms background-color ease;
  transition: 200ms background-color ease
}

.login-card__content .account-selector .ui-button.account-card:hover {
  background-color: #F6F6F7
}

.login-card__content .account-selector .ui-button.account-card:hover .account-card__icon {
  -webkit-transform: translateX(0.5rem);
  transform: translateX(0.5rem)
}

.login-card__content .account-selector .ui-button.account-card:active {
  background-color: #f4f6f8
}

.login-card__content .account-selector .ui-button.account-card::after {
  border-radius: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.account-card__destination {
  min-width: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  padding-right: 1rem
}

.account-card__destination>* {
  line-height: 2.4rem
}

.account-card__destination .ui-heading {
  font-size: 1rem;
  color: #202223
}

.account-card__destination p {
  font-size: 0.875rem;
  color: #6D7175;
  font-weight: normal
}

.account-card__destination--app {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.account-card__icon {
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease
}

.account-card__icon--before {
  padding-right: 1rem
}

.alternate-login__item {
  margin-bottom: 20px
}

.alternate-login__item:last-of-type {
  margin-bottom: 0
}

.google-button {
  height: 40px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  white-space: nowrap;
  -webkit-box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  -webkit-transition-property: background-color, -webkit-box-shadow;
  transition-property: background-color, -webkit-box-shadow;
  transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow, -webkit-box-shadow;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out
}

.google-button:focus,
.google-button:hover {
  -webkit-box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1)
}

.google-button:active {
  background-color: #e5e5e5;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition-duration: 50ms;
  transition-duration: 50ms
}

.google-button__icon {
  display: block;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px
}

.google-button__text {
  display: block;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
  color: #737373
}

.details-card--button {
  font-size: 1em;
  line-height: 1.4em;
  border: 0
}

.details-card--button:focus {
  outline: none
}

.details-card,
.fading-store-cards__card {
  text-align: left;
  border-radius: 0.3125rem;
  background-color: white;
  -webkit-box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.15);
  padding: 1rem;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1.5em
}

.details-card:last-child,
.fading-store-cards__card:last-child {
  margin-bottom: 0
}

.details-card .ui-banner,
.fading-store-cards__card .ui-banner {
  margin-bottom: 0
}

.details-card__section {
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--p-border-subdued)
}

.details-card--with-status,
.details-card--button {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.details-card--button {
  font: 14px "ShopifySans", -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  cursor: pointer
}

.details-card--button .details-card__icon {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #798c9c;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  padding-left: 0.25rem
}

.details-card--button:hover .details-card__icon {
  color: #31373d;
  -webkit-transform: translateX(0.25rem) rotate(90deg);
  transform: translateX(0.25rem) rotate(90deg)
}

.details-card--button.details-card--button-success {
  pointer-events: none
}

.details-card--button.details-card--button-success .details-card__icon {
  -webkit-transform: none;
  transform: none;
  color: transparent
}

.details-card__title {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0.25rem
}

.details-card__icon {
  -webkit-flex-basis: 1.3125rem;
  -ms-flex-preferred-size: 1.3125rem;
  flex-basis: 1.3125rem;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: auto
}

.details-card__collapsible-header {
  font-size: 1em;
  background: transparent;
  border: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 1rem;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.details-card__collapsible-header:active {
  color: #212b36
}

.details-card__collapsible-header:focus {
  outline: none
}

.details-card__collapsible-header[disabled] {
  color: #212b36;
  cursor: initial
}

.details-card__collapsible-icon-arrow .next-icon,
.details-card__collapsible-icon-arrow .next-icon__text {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease
}

[aria-expanded="true"] .details-card__collapsible-icon-arrow .next-icon,
[aria-expanded="true"] .details-card__collapsible-icon-arrow .next-icon__text {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg)
}

.details-card__collapsible-title {
  font-weight: 400;
  margin-right: auto;
  position: relative;
  top: -0.1875rem;
  word-break: break-all;
  text-align: left
}

[aria-expanded="false"] .details-card__collapsible-title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical
}

@supports not (-webkit-line-clamp: 3) {
  [aria-expanded="false"] .details-card__collapsible-title {
    display: block
  }
}

[aria-expanded="true"] .details-card__collapsible-title {
  font-weight: 700
}

.fading-store-cards {
  width: 100%;
  position: relative;
  padding-bottom: 1.5rem
}

.fading-store-cards::before,
.fading-store-cards::after {
  content: '';
  width: 95%;
  height: 0.75rem;
  border-radius: 0.3125rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: #f4f6f8;
  -webkit-box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  display: block;
  position: absolute;
  bottom: 0
}

.fading-store-cards::before {
  bottom: 0.75rem;
  left: 2.5%;
  z-index: 1
}

.fading-store-cards::after {
  width: 90%;
  background-color: #f9fafb;
  z-index: 0;
  left: 5%
}

.fading-store-cards--single {
  padding-bottom: 0.75rem
}

.fading-store-cards--single::before {
  bottom: 0
}

.fading-store-cards--single::after {
  display: none
}

.fading-store-cards__card {
  position: relative;
  z-index: 1;
  padding-left: 1.25rem
}

.user-details {
  padding: 0.5rem 0
}

.user-details__avatar-small {
  -webkit-transform: scale(0.75);
  transform: scale(0.75)
}

.recovery-codes {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto
}

.recovery-codes__column {
  width: 50%;
  float: left;
  text-align: center
}

.recovery-codes__code {
  display: block;
  margin-bottom: 0.5rem
}

.recovery-codes__code:last-child {
  margin-bottom: 0
}

.recovery-codes__code code {
  font-size: 1em
}

.recovery-codes__code--used {
  opacity: 0.7
}

.recovery-codes__help-text {
  color: #212b36
}

.web_authn_unsupported {
  color: #637381 !important
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0
}

h1 {
  font-size: 26px;
  line-height: 1
}

h2 {
  font-size: 18px
}

h3 {
  font-size: 14px
}

h4 {
  font-size: 14px
}

h5 {
  font-size: 14px
}

h6 {
  font-size: 14px
}

p {
  margin: 0
}

ul {
  list-style: disc inside none
}

ol {
  list-style: decimal inside none
}

ol[type='1'] {
  list-style: decimal inside none
}

ol[type='a'] {
  list-style: lower-alpha inside none
}

ol[type='A'] {
  list-style: upper-alpha inside none
}

ol[type='i'] {
  list-style: lower-roman inside none
}

ol[type='I'] {
  list-style: upper-roman inside none
}

li {
  margin: 0
}

dt {
  font-weight: bold
}

dd {
  margin-left: 10px
}

pre,
code {
  font-family: Monaco, Consolas, Lucida Console, monospace
}

pre {
  font-size: 90%
}

code {
  font-size: 85%;
  background: #ebeef0;
  padding: 2px;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word
}

input {
  font-size: 14px;
  line-height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif
}

del {
  text-decoration: line-through
}

address {
  font-style: normal
}

small {
  color: #798c9c;
  font-size: 12px
}

.next-type--note {
  font-style: italic;
  font-size: 12px;
  color: #95a7b7
}

.type--no-margin {
  margin: 0
}

.type--margin-bottom {
  margin-bottom: 5px
}

.type--margin-left {
  margin-left: 10px
}

.type--centered {
  text-align: center
}

.type--right {
  text-align: right
}

.type--left {
  text-align: left
}

.type--breakall {
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word
}

.type--light-weight {
  font-weight: 300
}

.type--semi-bold {
  font-weight: 500
}

.type--bold {
  font-weight: 700
}

.type--base {
  font-size: 14px
}

.type--danger {
  color: #ff5d5d
}

.type--warning {
  color: #ff9517
}

.type--success {
  color: #96bf48
}

.type--attention {
  color: #d4a002
}

.type--info {
  color: #006fbb
}

.type--white {
  color: #ffffff
}

.type--blue-lighter {
  color: #ebf5fa
}

.type--strikethrough {
  text-decoration: line-through
}

.type--line-height-equal-to-font {
  line-height: 1 !important
}

.type--tight-spacing {
  margin-bottom: -4px
}

.type--preserve-whitespace {
  white-space: pre-wrap
}

.type--no-wrap {
  white-space: nowrap
}

a.type--subdued,
button.type--subdued {
  color: inherit;
  text-decoration: underline
}

a.type--subdued:hover {
  color: #212b36
}

.type--truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block
}

.type--truncated--block {
  display: block
}

.type--margin-top {
  margin-top: 20px
}

.type--half-margin {
  margin-bottom: 10px
}

.type--number {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem
}

@media (min-width: 640px) {
  .type--number {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.type--number--x-large {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem
}

@media (min-width: 640px) {
  .type--number--x-large {
    font-size: 1.75rem;
    line-height: 2rem
  }
}

.type--number--large {
  font-size: 1.3125rem;
  font-weight: 400;
  line-height: 1.75rem
}

@media (min-width: 640px) {
  .type--number--large {
    font-size: 1.625rem;
    line-height: 2rem
  }
}

.type--number--small {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem
}

@media (min-width: 640px) {
  .type--number--small {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.type--number--tiny {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem
}

@media (min-width: 640px) {
  .type--number--tiny {
    font-size: 1.25rem;
    line-height: 1.75rem
  }
}

.user-avatar {
  position: relative;
  top: 0.0625rem;
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  background: transparent;
  text-align: center;
  overflow: hidden
}

.user-avatar>* {
  position: absolute;
  top: 0;
  left: 0
}

.user-avatar .user-avatar__initials {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: white;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 2px;
  margin-left: 2px;
  text-transform: uppercase
}

.user-avatar img {
  width: 100%;
  height: 100%;
  background-color: #fff
}

.user-avatar--style-1 {
  background: #29bc94
}

.user-avatar--style-2 {
  background: #ff5d5d
}

.user-avatar--style-3 {
  background: #ff9517
}

.user-avatar--style-4 {
  background: #96bf48
}

.user-avatar--style-5 {
  background: #006fbb
}

.user-avatar--style-6 {
  background: #b762ff
}

.password-strength-progress-bar.password-strength-strong .ui-progress-bar__indicator {
  background-color: #50b83c
}

.password-strength-strong {
  color: #173630;
  font-weight: bold
}

.password-strength-progress-bar.password-strength-medium .ui-progress-bar__indicator {
  background-color: #50b83c
}

.password-strength-medium {
  color: #173630;
  font-weight: bold
}

.password-strength-progress-bar.password-strength-weak .ui-progress-bar__indicator {
  background-color: #f49342
}

.password-strength-weak {
  color: #4a1504;
  font-weight: bold
}

.password-strength-progress-bar {
  margin-bottom: 0.5rem
}

.password-strength-progress-bar .ui-progress-bar__indicator {
  -webkit-transition: all 500ms cubic-bezier(0.64, 0, 0.35, 1);
  transition: all 500ms cubic-bezier(0.64, 0, 0.35, 1);
  background-color: #dfe3e8
}

.hidden {
  display: none
}

/**
 * Gradient
 */
.HomepageHeroGradient {
  --gradientColorZero: #00d58e;
  --gradientColorOne: #00b578;
  --gradientColorTwo: #009b67;
  --gradientColorThree: #00ffaa;
  --gradientColorZeroTransparent: rgba(169, 96, 238, 0);
  --gradientColorOneTransparent: rgba(255, 51, 61, 0);
  --gradientColorTwoTransparent: rgba(144, 224, 255, 0);
  --gradientColorThreeTransparent: rgba(255, 203, 87, 0);
  --gradientAngle: var(--angleStrong);
  --gradientHeight: 100%;
  --offsetX: var(--gutterWidth);
  --transformOriginX: -60px;
  --sectionAngleSin: var(--angleStrongSin);
  position: absolute;
  top: auto;
  left: 0;
  width: 100%;
  height: var(--gradientHeight);
  transform: skewY(var(--gradientAngle));
  overflow: hidden;
  border: none;
}

.Gradient canvas {
  width: 100%;
  height: 100%;
  --gradient-color-1: var(--gradientColorZero);
  --gradient-color-2: var(--gradientColorOne);
  --gradient-color-3: var(--gradientColorTwo);
  --gradient-color-4: var(--gradientColorThree);
}

.Gradient.isLoaded:after {
  transition: transform 1s 1s;
  transform: scaleY(.995);
}

@media (min-width: 600px) {
  .HomepageHeroGradient {
    --transformOriginX: calc(var(--gutterWidth)*0.8);
  }
}

.Gradient:after {
  content: "";
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(var(--gradientColorZero) 40%, var(--gradientColorTwoTransparent) 60%) -620px -180px no-repeat, radial-gradient(var(--gradientColorThree) 33%, var(--gradientColorThreeTransparent) 67%) -120px -24px no-repeat, radial-gradient(var(--gradientColorTwo) 40%, var(--gradientColorTwoTransparent) 70%) -470px 150px no-repeat, var(--gradientColorZero);
}

@media (min-width: 600px) {
  .Gradient:after {
    background: radial-gradient(var(--gradientColorZero) 40%, var(--gradientColorTwoTransparent) 60%) -420px -180px no-repeat, radial-gradient(var(--gradientColorThree) 23%, var(--gradientColorThreeTransparent) 70%) 240px -24px no-repeat, radial-gradient(var(--gradientColorTwo) 30%, var(--gradientColorTwoTransparent) 70%) -270px 220px no-repeat, var(--gradientColorZero);
  }
}

@media (min-width: 900px) {
  .Gradient:after {
    background: radial-gradient(var(--gradientColorThree) 23%, var(--gradientColorThreeTransparent) 67% 100%) 385px -24px, radial-gradient(var(--gradientColorOne) 0, var(--gradientColorOneTransparent) 60% 100%) -940px 290px, radial-gradient(var(--gradientColorTwo) 10%, var(--gradientColorTwoTransparent) 60% 100%) -120px 250px, radial-gradient(var(--gradientColorOne) 40%, var(--gradientColorOneTransparent) 57% 100%) 495px -44px, radial-gradient(var(--gradientColorZero) 30%, var(--gradientColorZeroTransparent) 67% 100%) 122px -120px, radial-gradient(var(--gradientColorZero) 10%, var(--gradientColorZeroTransparent) 60% 100%) -420px 120px, radial-gradient(var(--gradientColorTwo) 15%, var(--gradientColorTwoTransparent) 50% 100%) -620px 0, radial-gradient(var(--gradientColorTwo) 25%, var(--gradientColorTwoTransparent) 50% 100%) 520px -250px, var(--gradientColorZero);
    background-repeat: repeat-y;
  }
}