$nav-collapsed: 769px;
$top-bar-height: 56px;

.ContextControl {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: $top-bar-height;
}

.ShopName {
  margin-left: 8px;
  font-weight: 600;
  font-size: 14px;
  color: var(--p-text, black);

  @media only screen and (min-width: $nav-collapsed) {
    color: var(--p-text, white);
  }
}

.variantListContainer {
  background-color: var(--p-surface);
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-top: var(--p-border-base);
  border-color: var(--p-border-subdued);
  margin-top: var(--p-space-5);
}

.variantList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.variantItem {
  border-top: 0.0625rem solid var(--p-border-subdued);
  border-color: var(--p-border-subdued,#dfe3e8);
}

.variantItem:first-of-type {
  border-top: none;
}

.variantItemStack {
  color: var(--p-text);
  display: block;
  margin: var(--p-space-0);
  padding: var(--p-space-2) var(--p-space-5);
  text-decoration: none;
}

.variantItemStack.active {
  background: var(--p-surface-hovered);
  color: var(--p-interactive);
}

.variantItemStack:hover {
  text-decoration: none;
}

.variantItemStack:hover:not(.active) {
  background: var(--p-surface-hovered);
  color: var(--p-text);
}

.variantItemLink {
  outline: none;
}

.pricingTableButtons{
  width: 12rem;
  display: flex;
  justify-content: space-between;

}

// .YtSfD.LF6g1 {
//   background: var(--p-surface-selected);
//   color: var(--p-interactive);
// }
