.FullPage {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.LogoutSpinner {
  text-align: center;
}

.LogoutText {
  font-size: 18px;
  text-align: center;
}

.LogoutText p {
  margin-top: 10px;
}