$nav-collapsed: 769px;
$top-bar-height: 56px;

.ContextControl {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: $top-bar-height;
}

.ShopName {
  margin-left: 8px;
  font-weight: 600;
  font-size: 14px;
  color: var(--p-text, black);

  @media only screen and (min-width: $nav-collapsed) {
    color: var(--p-text, white);
  }
}

.dashboard {
  box-sizing: initial;
  max-width: 72.4rem;
  padding: 0;
  margin: 0 auto;
  position: relative;
  margin-top: 3.2rem;

  @media (min-width: 30.625em) {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }

  @media (max-width: 48.0625em) and (min-width: 30.625em),
  (min-width: 45.625em) {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}

.dashboardHeader {
  display: flex;
  align-items: center;
}

.dashboardContent {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-left: -1.6rem;
  padding-bottom: 1.6rem;

  @media screen and (min-width: 1200px) {
    flex-wrap: nowrap;
  }
}

.dashboardContentCol {
  min-width: 0px;
  max-width: 100%;
  flex: 1 0 100%;
  padding-left: 1.6rem;

  @media screen and (min-width: 1200px) {
    flex: 0 1 33.33%;
  }
}

.dashboardContentColPadding {
  padding-top: 1.6rem;
}

.cardTitle {
  cursor: pointer;
  border-bottom: 2px dotted #c4cdd5;
}

.cardTitle:hover {
  background-color: var(--p-surface-hovered);
}

.scadenzeContent {
  margin-top: 2rem;
  ;
}