@import url('https://fonts.cdnfonts.com/css/sofia-pro');

html,
body,
button {
  font-family: 'Sofia Pro', sans-serif;
  --p-action-primary: #00d58e;
  --p-action-primary-hovered: #00b578;
  --p-action-primary-pressed: #009b67;
  --p-text-primary: #00d58e;
  --p-text-primary-hovered: #00b578;
  --p-text-primary-pressed: #009b67;
}

[p-color-scheme="dark"] {
  --p-action-primary-disabled: rgba(188, 80, 19, 1);
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Sofia Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {}

.Polaris-Card {
  box-shadow: 0px 2px 5px rgb(23 24 24 / 5%), 0px 5px 5px rgb(92 96 98 / 15%);
}

.Polaris-Navigation__ListItem {
  margin-bottom: 0.08rem;
}