
.settingsList {
  list-style-type: none;
  margin: 0;
  padding: .8rem .4rem;
  row-gap: 1rem;

  @media screen and (min-width: 1025px) {
    padding: 1.6rem;
    display: grid;
    grid-column-gap: 1.6rem;
    grid-template-rows: repeat(var(--med-width-rows), auto);
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1201px) {
    grid-template-rows: repeat(var(--max-width-rows), auto);
  }
}

.settingsListItem {
  display: block;
  break-inside: avoid;
}

.settingsListItemIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 3px;
  background-color: #f4f6f8;
  fill: #919eab;
  color: transparent;
}

.settingsListItemSubtitle {
  color: var(--p-text-subdued);
  margin: 0;
  text-decoration: none;
}

.CompanySection {
  margin-bottom: 1.5rem;
}

.VariantButton {
  /* Additional styling if desired, e.g., spacing or text alignment */
  text-align: left;
}

.FileLink {
  /* Minor styling to differentiate file links, if needed */
  text-decoration: underline;
}
