.paymentsContainer {
  border: var(--p-border-width-1) solid var(--p-divider);
  border-radius: var(--p-border-radius-2);
}

.paymentsRow {
  min-width: 12.5rem;
  overflow: hidden;
  width: 100%;
}

.singlePayment {
  border: var(--p-border-base);
  border-radius: var(--p-border-radius-base);
  display: block;
  height: 1.8125rem;
  min-width: 2.75rem;
}