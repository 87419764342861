.dataContainer {
  display: flex;
}

.dataCol {
  z-index: 1;
  text-align: left;
  padding: .8rem 1.6rem;
  white-space: nowrap;
  flex: 1;
}

.dataCol:first-child {
  max-width: 35rem;
  overflow: hidden;
  text-overflow: ellipsis;
}